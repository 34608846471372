import { HttpClient } from '@angular/common/http';
import * as ɵngcc0 from '@angular/core';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileSaverOptions } from 'file-saver';

export declare class FileSaverService {
	readonly isFileSaverSupported: boolean;
	genType(fileName?: string): string;
	save(blob: Blob, fileName?: string, filtType?: string, option?: FileSaverOptions): void;
	saveText(txt: string, fileName?: string, option?: FileSaverOptions): void;
	static ɵfac: ɵngcc0.ɵɵFactoryDef<FileSaverService, never>;
	static ɵprov: ɵngcc0.ɵɵInjectableDef<FileSaverService>;
}

@Injectable({
	providedIn: 'root',
})
export class InvoicesService {
	constructor(private http: HttpClient) {}

	getFakturaList(data): Observable<any> {
		return this.http.get<any>(`api/faktura/getFakturaList?dataOd=${data.dataOd}&dataDo=${data.dataDo}`);
	}

	getInvoice(id): Observable<Blob> {
		//const options = { responseType: 'blob' }; there is no use of this
		let uri = `api/faktura/getFakturaAsPdf?fakturaId=` + id;
		// this.http refers to HttpClient. Note here that you cannot use the generic get<Blob> as it does not compile: instead you "choose" the appropriate API in this way.
		return this.http.get(uri, { responseType: 'blob' });
	}

	showInvoice(id) {
		let uri = `api/faktura/getFakturaAsPdf?fakturaId=` + id;
		return this.http.get(uri, {
			observe: 'response',
			responseType: 'arraybuffer',
		});
	}

	goGoFiscalReceipt(fakturaId) {
		let url = `api/faktura/urzadzenieFiskalizujDokument?fakturaId=` + fakturaId;
		return this.http.post(url, {});
	}

	goBaczek(fakturaId) {
		let url = `api/faktura/urzadzenieFiskalizujDokument?fakturaId=` + fakturaId;
		return this.http.post(url, {});
	}

	przyjmijZaliczkeDoRezerwacji(payload: { formaPlatnosci: number; rezerwacjaId: number }) {
		let url = `api/faktura/przyjmijZaliczkeDoRezerwacji`;
		return this.http.post(url, payload);
	}
}
