

<mat-tab-group [(selectedIndex)]="selectedIndex" matTabGroup (selectedTabChange)="onTabChange($event)">
  <mat-tab label="Dostępność/ceny"> 
	<table *ngIf="!isLoading" mat-table [dataSource]="dataSource" matSort
		class="mat-elevation-z8 main-table availability">
		<ng-container *ngFor="let disCol of displayedColumns; let colIndex = index" matColumnDef="{{ disCol }}">
			<th mat-header-cell *matHeaderCellDef>
				<strong> {{ disCol }} </strong>
			</th>
			<td mat-cell *matCellDef="let element; let i=index">
				<button mat-icon-button *ngIf="disCol == 'Typ pokoju'" class="btn-info" class="btn btn-link"
					(click)="pushToAccommodation(element, i)"
					[disabled]="element.ileDostepnychPokoi <= 0">
					<mat-icon>add</mat-icon>
				</button> 
				<span *ngIf="element.polozenieId && disCol == 'Typ pokoju'"> {{ element.polozenieNazwa }} -
				</span>
				<span *ngIf="disCol == 'Typ pokoju'"> {{ element.pokojtypNazwa | titlecase }} ({{
					element.ileLozek }}
					os.) </span>
				<span *ngIf="disCol != 'Typ pokoju'">{{ element.ileDostepnychPokoi }} / {{
					element.ceny[1].ceny[colIndex - 1].cenaBrutto }} zł</span>
			</td>
		</ng-container>
		<div *ngIf="roomTypes.length > 0">
			<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
		</div>
		<div *ngIf="roomTypes.length == 0">
			<tr class="mat-row" *matNoDataRow>
				<td class="mat-cell no-records" colspan="12">Brak rekordów spełniających kryteria</td>
			</tr>
		</div>
	</table>
  </mat-tab>
  <ng-template matTabContent>
	<span matBadge="4" matBadgeColor="accent">Tab 1 Content</span>
  </ng-template>
  <mat-tab>
		<ng-template mat-tab-label>
			<div class="tab-label-container">
			<span>Zakwaterowanie</span>
			<span *ngIf="this.accommodationArray.length > 0" matBadge="{{this.accommodationArray.length}}" matBadgeColor="#a489d4" class="badge"></span>
			</div>
		</ng-template>
		<div *ngFor="let roomsData of this.accommodationArray; let first = first; let index = index" class="accomodation-container">
			<div class="accomodation-type-room">
				<label *ngIf="first">Nazwa pokoju</label>
				<mat-form-field appearance="outline">
					<mat-select [placeholder]='roomsData.pokojtypNazwa' [disabled]="false" class="selectedTypeRoom" 
					>
						<mat-option *ngFor="let singleRoom of roomsData.pokoje;"
											[value]="singleRoom.pokojId" [ngClass]="{
						'room-status-dirty':  singleRoom.statusSprzatania == 'Brudny',
						'room-status-checked':  singleRoom.statusSprzatania == 'Sprawdzony',
						'room-status-clean':  singleRoom.statusSprzatania == 'Czysty'
		}" (click)="assinSingleRoom(singleRoom, index, roomsData)" [disabled]="occupiedRooms && occupiedRooms.includes(singleRoom.pokojId)">
							{{ singleRoom.pokojNazwa }}
						</mat-option> 
					</mat-select>
				</mat-form-field>
			</div>
			<form [formGroup]="accommodationForm">
				<div formArrayName="priceRooms">
				  <div *ngFor="let priceRoom of accommodationForm.get('priceRooms').controls; let i=index">
					<div [formGroupName]="i" *ngIf="i == index" class="main-accomodation-wrapper">
						<div class="accomodation-type-price">
							<label *ngIf="first">Cena pokoju</label>
							<mat-form-field appearance="outline">
								<input matInput type="number" min="0" step=".01" [disabled]="false"
								 formControlName="price" id="{{i}}"  (input)="handleChangePrice($event.target.id, $event.target.value)"  />
							</mat-form-field>
						</div>
						<div class="accomodation-type-price adult-wrapper">
							<label *ngIf="first">Dorosły</label>
							<div class="input-wrapper">
								<mat-form-field appearance="outline" class="adult-input">
									<input matInput type="number" min="0" [max]="roomsData.ileLozek" step=".01" class="adult-number"
									formControlName="adultNumber" id="{{i}}"
									oninput="if (parseFloat(this.value) > parseFloat(this.max)) { this.value = this.max; } else if (this.value == 0) { this.value = this.max } this.blur()"
									onfocus="this.value = ''"
									onblur="if (this.value === '' || parseFloat(this.value) === 0) { this.value = this.max; }"
									(input)="handleChangeAdult($event.target.id, $event.target.value)" />
								</mat-form-field>
								<span class="symbol">/</span>
								<mat-form-field appearance="outline" class="adult-input">
									<input matInput type="number" min="0" step=".01" [disabled]="true" class="adult-number"
										[value]="roomsData.ileLozek" />
								</mat-form-field>
							</div>
						</div>
					</div>
				  </div>
				</div>
			</form>			
			<div class="accomodation-actions">
				<label *ngIf="first">Akcje</label>
				<button mat-icon-button class="btn-info" class="btn btn-link" (click)="deleteRoom(roomsData, index)">
					<mat-icon>delete_forever</mat-icon>
				</button>
			</div>
		</div>		
 </mat-tab>
  <mat-tab>
	<ng-template mat-tab-label>
		<div class="tab-label-container">
		<span>Dodatki</span>
		<span *ngIf="totalServiceNumber > 0" matBadge="{{ totalServiceNumber }}" matBadgeColor="#a489d4" class="badge"></span>
		</div>
	</ng-template>
	<div *ngFor="let roomsData of this.accommodationArray; let first = first; let indexRoom = index" class="wrapper">
		<div *ngFor="let singleRoom of roomsData.przydzielonePokoje; let uniqIndex = index" class="accomodation-container">
				<mat-accordion>
					<mat-expansion-panel (opened)="panelOpenState = true"
										 (closed)="panelOpenState = false">
					  <mat-expansion-panel-header>
						<mat-panel-title>
						  {{singleRoom.pokojNazwa}} 
						  <span class="noAddons" *ngIf="singleRoom.uslugi.length == 0"> (Brak dodatków w wybranym terminie dla tego pokoju) </span>
						</mat-panel-title>
						<mat-panel-description>
						</mat-panel-description>
					  </mat-expansion-panel-header>
					  <div *ngFor="let addon of singleRoom.uslugi; let first = first; let indexAddon = index" class="accomodation-addons" [ngClass]="{'disabled-div': addon.nazwa === 'Opłata serwisowa'}">
						<!-- {{addon | json}} -->
						<div class="accomodation-type-room">
							<label *ngIf="first" class="label-text">Nazwa dodatku</label>
							<div>{{addon.nazwa}}</div>
						</div>
						<div class="accomodation-type-room">
							<label *ngIf="first" class="label-text">Cena dodatku</label>
							<div>{{addon.uslugaCenaJednBrutto}}</div>
						</div>
						<div class="accomodation-type-price">
							<label *ngIf="first" class="label-text">Jednostka miary</label>
							<div class="unit-addon">{{addon.jednMiaryNazwa}}</div>
						</div>
						<div >
							<label *ngIf="first" class="label-text">Akcje</label>
							<div class="actions-addons">
								<button mat-icon-button (click)="rmAddon(addon, singleRoom)" [disabled]="addon.nazwa === 'Opłata serwisowa'">
									<mat-icon>remove</mat-icon>
								</button>
								<strong>
									<div class="fontBigger  cursorPointer">
									{{addon.uslugaIlosc || 0}}
									</div>
								</strong>
								<button mat-icon-button (click)="addAddon(addon, singleRoom)" [disabled]="addon.nazwa === 'Opłata serwisowa'"
							>
									<mat-icon>add</mat-icon>
								</button>
							</div>
						</div>
					</div>	
					</mat-expansion-panel>
				  </mat-accordion>
		</div>
	</div>
  </mat-tab>
</mat-tab-group>
<!-- <mat-spinner class="spinner-addons" *ngIf="roomTypes.length == 0" [diameter]="40" matSuffix></mat-spinner> -->

