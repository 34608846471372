import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Stale } from '../interfaces/stale';
import { Slownik } from '../interfaces/slownik';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StaleService {

  constructor(private http: HttpClient) { }
  getZrodlaRezerwacji(): Observable<any> {
    return this.http.get<Stale[]>('api/stale/getZrodlaRezerwacji');
  }
  getRodzajeGosci(): Observable<any> {
    return this.http.get<Stale[]>('api/stale/getRodzajeGosci');
  }
  getUslugaList(): Observable<any> {
    return this.http.get<Stale[]>('api/usluga/getUslugaList');
  }
  getZrodloZgodyRodo(): Observable<any> {
    return this.http.get<Stale[]>('api/stale/findByStaleRodzajId?staleRodzajId=21');
  }
  getZwrotGrzecznosciowy(): Observable<any> {
    return this.http.get<any[]>('api/slownik/findBySlownikRodzajId?slownikRodzajId=12');
  }
  getFormyPlatnosci(): Observable<any> {
    return this.http.get<Slownik[]>('api/slownik/getFormyPlatnosci');
  }
  getPanstwa(): Observable<any> {
    return this.http.get<any[]>('api/panstwo/getPanstwoShortList');
  }
  getMiasto(): Observable<any> {
    return this.http.get<any[]>('/api/miasto/getMiastoShortList');
  }
  getMiastoById(miastoId): Observable<any> {
    return this.http.get<any[]>('/api/miasto/getMiastoShortList?search=' + miastoId);
  }
  getPersonEditInitData(): Observable<any> {
    const zrodloZgody$: Observable<any> = this.getZrodloZgodyRodo();
    const zwrotGrzecznosciowy$: Observable<any> = this.getZwrotGrzecznosciowy();
    const listaPanstw$: Observable<any> = this.getPanstwa();
    return forkJoin(
      zrodloZgody$,
      zwrotGrzecznosciowy$,
      listaPanstw$
    ).pipe(
      map(res => {
        let initData = {
          zgody: res[0],
          zwroty: res[1],
          listaPanstw: res[2],
        }
        return initData;
      })

    )
  }
  getCompanyEditInitData(): Observable<any> {
    const listaPanstw$: Observable<any> = this.getPanstwa();
    const listaMiast$: Observable<any> = this.getMiasto();
    return forkJoin(
      listaPanstw$,
      listaMiast$
    ).pipe(
      map(res => {
        let initData = {
          listaPanstw: res[0],
          listaMiast: res[1],
        }
        return initData;
      })

    )
  }
  getCityEditInitData(): Observable<any> {
    const listaPanstw$: Observable<any> = this.getPanstwa();
    return forkJoin(
      listaPanstw$,
    ).pipe(
      map(res => {
        let initData = {
          listaPanstw: res[0],
        }
        return initData;
      })

    )
  }
}
