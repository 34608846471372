import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ColorsPanelService {

  // Set default colors of app
  public sidebarColor: string = "gray"; // options: blue, red, primary, green , gray
  public dashboardWhite: boolean = true;
  constructor() {
    this.setLocalstorageColor();
  }

  changeSidebarColor(color: string) {
    var sidebar = document.getElementsByClassName("sidebar")[0];
    var mainPanel = document.getElementsByClassName("main-panel")[0];
    this.sidebarColor = color;
    this.getColorAndChange("mainColor", this.sidebarColor);

    if (sidebar != undefined) {
      sidebar.setAttribute("data", color);
    }
    if (mainPanel != undefined) {
      mainPanel.setAttribute("data", color);
    }
  }

  changeDashboardColor(color: string) {
    var body = document.getElementsByTagName("body")[0];
    this.getColorAndChange("backgroundColor", color.split("-")[0]);
    if (body && color === "white-content") {
      body.classList.add(color);
      this.dashboardWhite = true;
    } else if (body.classList.contains("white-content")) {
      body.classList.remove("white-content");
      this.dashboardWhite = false;
    }
  }

  /*
   * Localstorage Config Color methods
   */
  setLocalstorageColor() {
    if (localStorage.getItem("config") != null) {
      var config = JSON.parse(localStorage.getItem("config"));

      if (config.mainColor != undefined) {
        this.sidebarColor = config.mainColor;
      }
      if (
        config.backgroundColor != undefined &&
        config.backgroundColor == "black"
      ) {
        this.dashboardWhite = false;
      } else {
        this.dashboardWhite = true;
      }
    } else {
      localStorage.setItem(
        "config",
        JSON.stringify({ mainColor: "gray", backgroundColor: "white" })
      );
    }
  }

  getColorAndChange(colorName, newColor) {
    if (localStorage.getItem("config") != null) {
      var config = JSON.parse(localStorage.getItem("config"));
      config[colorName] = newColor;
      if (colorName == "mainColor") {
        document.documentElement.style.setProperty("--main-color", newColor);
      } else if (colorName == "backgroundColor") {
        document.documentElement.style.setProperty(
          "--background-color",
          newColor
        );
      }
      localStorage.setItem("config", JSON.stringify(config));
    } else {
      localStorage.setItem(
        "config",
        JSON.stringify({ mainColor: "gray", backgroundColor: "white" })
      );
    }
  }
}
