<perfect-scrollbar
[config]="{ wheelPropagation: false, suppressScrollX: true }"  class="ps-conent-wrapper">
<div style="display: flex;flex-direction: column; justify-content: space-between; height: 100%; gap: 6px">
  <div class="logo" style="display: flex;">
    <a class="simple-text logo-mini">
      <div class="logo-img">
        <img class="" src="./assets/img/logo.png" />
      </div>
    </a>
    <span class="simple-text logo-normal" style="flex:1">
      Ekoncept PMS
    </span>
    <button class="logo-btn" (click)="sidebarService.sidebarToggle()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="sidebar-wrapper" style="z-index: 1010;">
    <ul class="nav">
      <li #links *ngFor="let menuItem of menuItems; let i = index" class="{{ menuItem.class }} nav-item"
        [ngClass]="{'active':this.isTitleActive[i]}">
        <a class="nav-item__parent" [ngClass]="{'nav-item__parent--collapsed':!menuItem.isHide}"
          (click)="expand($event,i)">
          <div [routerLink]="menuItem.active ? [menuItem.path] : []" class="no-focus">
            <i class="tim-icons  {{ menuItem.icon }}"></i>
            <span *ngIf="menuItem.path.includes('/employee') && this.authService.user.value.hotelCode==9995">Użytkownicy systemu</span>
            <span *ngIf="!menuItem.path.includes('/raporty_online') && !menuItem.path.includes('/employee')" translate>Sidebar.{{ menuItem.title}}</span>
            <span *ngIf="menuItem.path.includes('/raporty_online')" translate>Raporty Online</span>
          </div>
          <i *ngIf="menuItem.subMenu.length" class="tim-icons nav-item__parent-dropdown icon-minimal-down"></i>
        </a>
        <div class="sub-active-wrapper" *ngIf="this.isTitleShown[i]">
      <li *ngFor="let subMenu of menuItem.subMenu" routerLinkActive="sub-active"
        [routerLink]="subMenu.path ? [menuItem.path, subMenu.path] : []" (click)="expand($event,i,true)">
        <a class="nav-item__children no-focus"
          *ngIf="(this.authService.user.value.hotelCode==0231 || this.authService.user.value.hotelCode==0201) && (subMenu.title=='Raport Managera')">
          <span translate>Sidebar.{{ subMenu.title }} </span>
        </a>
        <a class="nav-item__children no-focus"
          *ngIf="((this.authService.user.value.hotelCode==0231 || this.authService.user.value.hotelCode==0201)  && !(subMenu.title=='Raport Managera')) || (!(this.authService.user.value.hotelCode==0231 || this.authService.user.value.hotelCode==0201) && !(subMenu.title=='Raport Managera')) && ((this.authService.user.value.hotelCode!=0241 && this.authService.user.value.hotelCode!=281 && this.authService.user.value.hotelCode!=0291 && this.authService.user.value.hotelCode!=0271 && this.authService.user.value.hotelCode!=301) || ((this.authService.user.value.hotelCode==0241 || this.authService.user.value.hotelCode==0281 || this.authService.user.value.hotelCode==0291 || this.authService.user.value.hotelCode==0271 || this.authService.user.value.hotelCode==301) &&  (subMenu.title!='Foodcost')))">
          <span translate>{{ subMenu.title }} </span>
        </a>
      </li>
  </div>
  </li>
  </ul>
</div>
<div class="sidebar__footer">
  <div class="container-fluid">
    <form [formGroup]="selectHotelForm">
      <mat-form-field class="form-element" appearance="outline">
        <mat-label>Hotel</mat-label>
        <mat-select formControlName="selectedHotel" [disabled]="hotelList.length <= 1">
          <mat-option *ngFor="let hotel of hotelList" value="{{hotel.hotelName}}" (click)="changeHotelDb(hotel)">
            {{hotel.hotelName}}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>

    <div class="copyright">
      &copy; {{ versionDate | date: "yyyy" }} made by
      <a href="https://www.ekoncept.pl" target="_blank" style="color: #cf3c66;">
        Ekoncept </a>
      <span class="version-text"> v.{{ appVersion }}</span>
    </div>
  </div>
</div>
</div>
</perfect-scrollbar>