<h1 mat-dialog-title>
  {{ title }}
</h1>

<div mat-dialog-content>
  <p>{{ message }}</p>
</div>

<div class="mat-dialog-actions">
  <button mat-button (click)="onDismiss()">Nie</button>
  <button
    mat-raised-button
    class="btn-xs btn-ek btn-info-ek"
    (click)="onConfirm()"
  >
    Tak
  </button>
</div>
