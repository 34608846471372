import { Component, OnInit } from "@angular/core";

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-image-modal",
  templateUrl: "./image-modal.component.html",
  styleUrls: ["./image-modal.component.scss"],
})
export class ImageModalComponent implements OnInit {
  public image;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void { }

  closeModal() {
    this.activeModal.close();
  }
}
