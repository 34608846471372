import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DodatekWr } from './interface/addons-settings';

@Injectable({
	providedIn: 'root',
})
export class AddonsSettingsService {
	constructor(public http: HttpClient) {}

	getDodatekWrList(onlyActive = true) {
		return this.http.get<any>(`/api/dodatki/getDodatekWrList?tylkoAktywne=${onlyActive}`);
	}
	getDodatekWrListAll(naDzien: string, polozenieId: number, onlyActive: boolean, uslugodawcaId: number) {
		return this.http.get<any>(`/api/dodatki/getDodatekWrList?naDzien=${naDzien}&polozenieId=${polozenieId}&tylkoAktywne=${onlyActive}&uslugodawcaId=${uslugodawcaId}`);
	}
	getSlownikiDodatkow(withUslugodawcy = false, withDodatki = false) {
		let url = `/api/dodatki/getSlownikiDodatkow?uslugodawcy=${withUslugodawcy}&polozenia=${withDodatki}`;
		return this.http.get<any>(url);
	}

	edytujDodatekWr(dodatekWrData: DodatekWr) {
		return this.http.put<any>(`/api/dodatki/edytujDodatekWr`, dodatekWrData);
	}
	dodajDodatekWr(dodatekWrData: DodatekWr) {
		return this.http.post<any>(`/api/dodatki/dodajDodatekWr`, dodatekWrData);
	}
	usunDodatekWr(dodatekWrId: number) {
		return this.http.delete<any>(`/api/dodatki/usunDodatekWr?dodatekWrId=${dodatekWrId}`);
	}
	usunZdjecieDodatkuWr(dodatekWrId: number, fotoId: number) {
		return this.http.delete<any>(`/api/dodatki/usunZdjecieDodatkuWr?dodatekWrId=${dodatekWrId}&fotoId=${fotoId}`);
	}

	dodajZdjecieDodatkuWr(imageData: any) {
		return this.http.post<any>(`/api/dodatki/dodajZdjecieDodatkuWr`, imageData);
	}

	getZdjeciaDodatkuWr(dodatekWrId: number) {
		return this.http.get<any>(`/api/dodatki/getZdjeciaDodatkuWr?dodatekWrId=${dodatekWrId}`);
	}
}
