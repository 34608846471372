import { Component } from '@angular/core';

@Component({
    selector: 'app-admin-layout',
    templateUrl: './admin-layout.component.html',
    styleUrls: ['./admin-layout.component.scss'],
})
export class AdminLayoutComponent {
    constructor() {
        document.getElementById('blank-page-loading')
            ? (document.getElementById('blank-page-loading').style.display =
                  'none')
            : null;
    }
}
