import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HotelsRelations } from 'src/app/auth/models/hotels-list.model';

@Injectable({
    providedIn: 'root',
})
export class SetNewPasswordService {
    public newPasswordDatas = new BehaviorSubject<any[] | null>(null);
    constructor() {}

    getRelatedHotels(hotelCode: number): number[] {
        let relatedHotels = HotelsRelations[hotelCode];
        let hotelsToLogin = [...[hotelCode]];
        if (relatedHotels?.length >= 1) {
            hotelsToLogin = relatedHotels;
        }
        return hotelsToLogin;
    }
}
