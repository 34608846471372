<footer class=" footer">
  <div class=" container-fluid">
    <div class=" copyright">
      &copy; {{ test | date: "yyyy" }} made by
      <a href="https://www.ekoncept.pl" target="_blank"> Ekoncept </a>
      for a better web.
      <span class="version-text"> v.{{ appVersion }}</span>
    </div>
  </div>
</footer>
