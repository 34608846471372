import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RoomServiceService {
  constructor(private http: HttpClient) {}

  addPhotoToServiceRoom(imageData): Observable<any> {
    return this.http.post("api/zlecenie/dodajZdjecieZlecenia", imageData);
  }

  getPhotoToServiceRoom(zlecenieId): Observable<any> {
    return this.http.get(
      "api/zlecenie/getZdjeciaZlecenia?zlecenieId=" + zlecenieId
    );
  }

  removePhotoOfServiceRoom(serviceObject): Observable<any> {
    return this.http.delete(
      "api/zlecenie/usunZdjecieZlecenia?zlecenieId=" + serviceObject.zlecenieId + '&fotoId=' + serviceObject.fotoId 
    );
  }

  addServiceRoom(roomServiceData): Observable<any> {
    return this.http.post("api/zlecenie/dodajZlecenie", roomServiceData);
  }
  getUprawnieniList(): Observable<any> {
    return this.http.get("api/zlecenie/getUprawnieniList");
  }
  getServiceActions(): Observable<any> {
    return this.http.get("api/zlecenie/getRodzajeZlecenList");
  }
  editServiceRoom(roomServiceData): Observable<any> {
    return this.http.put("api/zlecenie/aktualizujZlecenie", roomServiceData);
  }
}
