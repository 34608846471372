<div data-component='datepicker' class="{{ customInputClass }}">
  <div class="row">
    <div class="col-12 col-sm-6 px-3 pr-sm-1">
      <label class="m-0 mb-sm-2" translate>Datepickerrange.datefrom</label>
      <div class="form-group form-group-hidden">
        <div class="input-group">
          <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker"
            [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="numberOfMonths"
            [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!">
          <ng-template #t let-date let-focused="focused">
            <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
              [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
              (mouseleave)="hoveredDate = null">
              {{ date.day }}
            </span>
          </ng-template>
        </div>
      </div>

      <div class="form-group">
        <div class="input-group">
          <input #dpFromDate class="form-control" placeholder="yyyy-mm-dd" name="dpFromDate"
            [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate)">
          <div class="input-group-append">
            <button class="input-group-button" (click)="datepickerToggle()" type="button">
              <span class="material-icons">
                date_range
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6 px-3 pl-sm-1">
      <label class="m-0 mb-sm-2" translate>Datepickerrange.dateto</label>
      <div class="form-group">
        <div class="input-group">
          <input #dpToDate class="form-control" placeholder="yyyy-mm-dd" name="dpToDate"
            [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate)">
          <div class="input-group-append">
            <button class="input-group-button" (click)="datepickerToggle()" type="button">
              <span class="material-icons">
                date_range
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
