<app-loading [isLoading]="isLoading"></app-loading>
<ng-container *ngIf="!isLoading">
  <div class="reason-container" mat-dialog-content>
    <h3>Dodaj zaliczkę dla rezerwacji {{data.rezerwacjaId}}</h3>
    <form [formGroup]="addAdvanceForm">
      <mat-form-field class="form-input" appearance="outline">
        <mat-label>ID rezerwacji</mat-label>
        <input matInput formControlName="rezerwacjaId" value="0" type="number" disabled>
      </mat-form-field>
      <mat-form-field class="form-input" appearance="outline">
        <mat-label>Forma płatności</mat-label>
        <mat-select formControlName="formaPlatnosci">
          <mat-option *ngFor="let formaPlatnosci of formyPlatnosci" [value]="formaPlatnosci.slownikId">
            {{ formaPlatnosci.slownikText }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions class="mat-dialog-actions">
    <button mat-stroked-button class="btn-xs btn-ek btn-transparent-ek" (click)="sendRes('cancel')">Anuluj</button>
    <button mat-button class="btn-xs btn-ek btn-info-ek" (click)="sendRes('confirm')">Dodaj zaliczkę</button>
  </div>
</ng-container>
