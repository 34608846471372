import {
    HttpHandler,
    HttpInterceptor,
    HttpParams,
    HttpRequest,
} from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, exhaustMap, retry, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
    private readonly SERVER_ERROR_MESSAGE =
        'Wystąpił błąd serwera. Spróbuj ponownie.';
    private readonly UNKNOWN_ERROR_MESSAGE = 'An unknown error occurred!';
    private readonly UNAUTHORIZED_ERROR_MESSAGE = 'Brak autoryzacji!';

    constructor(
        private authService: AuthService,
        private _snackBar: MatSnackBar,
        private router: Router
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (req.url.includes('i18n')) {
            return next.handle(req);
        }
        if (req.url.includes('raporty.ekoncept-pms.pl')) {
            if (req.headers.has('authorization')) {
                return next.handle(req);
            }
            return throwError(this.UNAUTHORIZED_ERROR_MESSAGE);
        }

        const apiUrl = environment.domain;

        if (req.headers.has('authorization')) {
            const modifiedReq = req.clone({ url: apiUrl + req.url });
            return next.handle(modifiedReq);
        }

        return this.authService.user.pipe(
            take(1),
            exhaustMap((user) => {
                const modifiedReq = this.modifyRequest(req, apiUrl, user);
                return next.handle(modifiedReq).pipe(
                    catchError((errorRes) => this.handleError(errorRes))
                );
            }),
            tap(() => this.authService.resetTokenTimer())
        );
    }

    private modifyRequest(
        req: HttpRequest<any>,
        apiUrl: string,
        user: any
    ): HttpRequest<any> {
        if (!user) {
            return req.clone({ url: apiUrl + req.url });
        }

        return req.clone({
            headers: req.headers.set(
                'Authorization',
                `Bearer ${user.uuid}`
            ),
            url: `${apiUrl}${user.hotelCode}/${req.url}`,
        });
    }

    private handleError(errorRes: any): Observable<never> {
        let errorMessage = this.UNKNOWN_ERROR_MESSAGE;

        // Handle specific errors
        if (
            errorRes.error?.message ===
            `Database operation error: Can't read or write data`
        ) {
            this.showSnackBar(this.SERVER_ERROR_MESSAGE, 'Błąd');
            return throwError(errorRes.error?.message);
        }

        if (errorRes.status === 401) {
            this.handleUnauthorizedError(errorRes);
            return throwError(errorRes?.message || this.UNAUTHORIZED_ERROR_MESSAGE);
        }

        if (errorRes.status === 404) {
            this.showSnackBar(
                'Metoda jest niedostępna, proszę zaktualizować oprogramowanie.',
                'Ok'
            );
            return throwError(errorRes?.message);
        }

        if (errorRes.error?.message) {
            errorMessage = errorRes.error?.message;
        } else if (errorRes.error?.error) {
            errorMessage = errorRes.error?.error;
        }

        this.showSnackBar(errorMessage);
        return throwError(errorMessage);
    }

    private handleUnauthorizedError(errorRes: any): void {
        if (this.router.url === '/dashboard') {
            this.authService.logout();
        } else {
            this.router.navigate(['/dashboard']);
        }

        if (errorRes.error?.message) {
            this.showSnackBar(errorRes.error?.message);
        }
    }

    private showSnackBar(message: string, action: string = '', duration: number = 2000): void {
        this._snackBar.open(message, action, { duration });
    }
}