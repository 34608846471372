<form class="" data-component='datepicker'>
  <div class="row">
    <div class="col-md-6">
      <label *ngIf="type == 'from'" translate>Datetimepicker.datefrom</label>
      <label *ngIf="type == 'to'" translate>Datetimepicker.dateto</label>
      <div class="form-group" *ngIf="setDisabled != 'true' ">
        <div class="input-group">
          <input class="form-control" id="{{ dateInputName }}" placeholder="yyyy-mm-dd" name="dp" ngbDatepicker
            #d="ngbDatepicker" (dateSelect)="onDateSelection($event)" value="{{ today }}">
          <div class=" input-group-append">
            <button class="input-group-button" (click)="d.toggle()" type="button">
              <span class="material-icons">
                date_range
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="setDisabled == 'true' ">
        <div class="input-group">
          <input class="form-control" id="{{ dateInputName }}" [formControlName]="date" value="{{ today }}" disabled>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <label *ngIf="type == 'from'" translate>Datetimepicker.hourfrom</label>
      <label *ngIf="type == 'to'" translate>Datetimepicker.hourto</label>
      <div class="form-group">
        <div class="input-group">
          <select class="form-control form-control__hour" id="timeHour{{ type }}" (change)="onHourSelection($event)"
            [attr.disabled]="setDisabled == 'true' ? '' : null">
            <option value=" {{ hour }}" *ngFor="let hour of hours" [selected]="hour == pickedHour ">{{ hour }}
            </option>
          </select>
          <span class="form-control__sep">:</span>
          <select class="form-control form-control__minutes" id="timeMinutes{{ type }}"
            (change)="onMinutesSelection($event)" [attr.disabled]="setDisabled == 'true' ? '' : null">
            <option value="{{ minut }}" *ngFor="let minut of minutes" [selected]="hour == pickedMinutes ">{{ minut }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</form>
