<div class="time-sch-wrapper" [style.height]="maxHeight">
	<div class="time-sch-header-wrapper">
		<div class="date-option-wrapper">
			<div class="time-sch-period-container">
				<button class="btn btn-sm-sch" *ngFor="let period of periods" [ngClass]="period.classes" (click)="changePeriod(period)" [title]="period.tooltip ? period.tooltip : ''">
					{{ period.name }}
				</button>
				<select class="time-sch__localization" *ngIf="isLocalizationOn && (roomSectionFlag | async)" (change)="onLocalizationChange($event)">
					<option *ngFor="let local of localizationArray" value="{{ local.polozenieId }}">
						{{ local.polozenieNazwa }}
					</option>
				</select>
			</div>
			<div class="time-sch-time-container">
				<button class="btn btn-sm-sch" *ngIf="showToday" (click)="gotoToday()">{{ text.TodayButton }}</button>
				<button class="btn btn-sm-sch" (click)="previousPeriod()">{{ text.PrevButton }}</button>
				<button class="btn btn-sm-sch" (click)="nextPeriod()">{{ text.NextButton }}</button>
			</div>
			<h3 class="text-center m-0">
				{{ start.format(headerFormat) + ' - ' + end.format(headerFormat) }}
			</h3>
		</div>
	</div>
	<div class="time-sch-table-wrapper" id="main-table">
		<table class="time-sch-table sticky">
			<tr *ngFor="let timeFrameHeader of header; let i = index; trackBy: trackByFn">
				<td class="time-sch-section text-center" *ngIf="i === 0" [rowSpan]="periods.length">
					<select (change)="onSectionChange($event)" class="typeRoom">
						<option *ngFor="let schType of schedulerTypes" value="{{ schType.id }}">{{ schType.name }}</option>
					</select>
				</td>
				<td
					*ngFor="let dateTime of timeFrameHeader.headerDetails; let in = index; trackBy: trackByFn"
					[id]="dateTime.name + dateTime.month.toString()"
					[colSpan]="dateTime.colspan"
					[title]="dateTime.tooltip ? dateTime.tooltip : ''"
					[ngClass]="{ 'text-saturday': dateTime.isSaturday, 'text-sunday': dateTime.isSunday, column: in === 0 }"
				>
					<div class="time-sch-table__day-wrapper">
						<span class="text-center text-three-dots" style="width: 100%">{{ dateTime.name }}</span>
						<span class="text-center text-three-dots" style="width: 100%">{{ dateTime.dayOfWeek }}</span>
					</div>
				</td>
			</tr>
		</table>
		<ng-container *ngIf="!isMobileView">
			<div class="time-sch-content-wrap" [ngClass]="{ noClick: isHousekeeping }" *ngIf="sectionItems.length > 0">
				<table class="time-sch-table">
					<tr *ngFor="let sectionItem of sectionItems; trackBy: trackByFn" [style.height]="(roomSectionFlag | async) ? sectionItem['roomHeight'] + 'px' : sectionItem.minRowHeight + 'px'">
						<td
							class="time-sch-section text-center"
							#sectionTd
							(click)="events.SectionClickEvent ? events.SectionClickEvent(sectionItem.section) : false"
							(contextmenu)="events.SectionContextMenuEvent ? events.SectionContextMenuEvent(sectionItem.section, $event) : false"
							[title]="sectionItem.section.tooltip ? sectionItem.section.tooltip : ''"
						>
							<div class="time-sch-section__room">
								<span
									class="time-sch-section__room-name"
									matTooltip="{{ sectionItem.section.object['pokojtypNazwa'] }}"
									*ngIf="this.authService.user.value.hotelCode == '0281' || this.authService.user.value.hotelCode == '0331'"
								>
									{{ sectionItem.section.name }} - {{ sectionItem.section.object['pokojtypNazwa'] }}
								</span>
								<span class="time-sch-section__room-name" *ngIf="this.authService.user.value.hotelCode != '0281' && this.authService.user.value.hotelCode != '0331'">
									{{ sectionItem.section.name }}
								</span>
								<span class="block-n-status">
									<button mat-icon-button class="block-icon" color="basic" matTooltip="Dodaj blokadę" matTooltipPosition="above" (click)="showRoomBlockerDialog(sectionItem.section)">
										<mat-icon>lock</mat-icon>
									</button>
									<span
										class="time-sch-section__status"
										[ngClass]="{
											'time-sch-section__status--dirty': sectionItem.section.object.pokojSprzatany == 1,
											'time-sch-section__status--clean': sectionItem.section.object.pokojSprzatany == 2,
											'time-sch-section__status--checked': sectionItem.section.object.pokojSprzatany == 0
										}"
									>
										{{ sectionItem.section.object.statusSprzatania }}
									</span>
								</span>
							</div>
						</td>
						<td class="graph" *ngFor="let td of header[header.length - 1].headerDetails; trackBy: trackByFn"></td>
					</tr>
				</table>
				<div class="time-sch-section-wrapper" [style.left]="SectionLeftMeasure" cdkDropListGroup>
					<div
						class="time-sch-section-container"
						cdkDropList
						cdkDropListSortingDisabled
						[cdkDropListData]="sectionItem.section"
						(cdkDropListDropped)="drop($event)"
						*ngFor="let sectionItem of sectionItems; trackBy: trackByFn"
						[style.height]="(roomSectionFlag | async) ? sectionItem['roomHeight'] + 'px' : sectionItem.minRowHeight + 'px'"
					>
						<div
							class="time-sch-item"
							cdkDrag
							cdkDragLockAxis="y"
							cdkDragBoundary=".time-sch-section-wrapper"
							[cdkDragData]="itemMeta.item"
							[cdkDragDisabled]="isMobileView"
							*ngFor="let itemMeta of sectionItem.itemMetas"
							[ngClass]="itemMeta.item.classes"
							(click)="events.ItemClicked ? events.ItemClicked(itemMeta.item) : false"
							(contextmenu)="events.ItemContextMenu ? events.ItemContextMenu(itemMeta.item, $event) : false"
							[style.height]="(roomSectionFlag | async) ? '30px' : minRowHeight + 'px'"
							[style.top]="itemMeta.cssTop + 'px'"
							[style.left]="itemMeta.cssLeft + '%'"
							[style.width]="itemMeta.cssWidth + '%'"
							[style.marginTop]="(roomSectionFlag | async) ? itemMeta.cssMarginTop + 'px' : 0"
						>
							<div
								class="item-drag-placeholder"
								*cdkDragPlaceholder
								[style.height]="(roomSectionFlag | async) ? 30 - 6 + 'px' : minRowHeight - 6 + 'px'"
								[style.left]="itemMeta.cssLeft + '%'"
								[style.width]="'calc(' + itemMeta.cssWidth + '% - 6px)'"
							></div>
							<div class="time-sch-item-content" [title]="itemMeta.item.tooltip ? itemMeta.item.tooltip : ''" [style.lineHeight]="minRowHeight + 'px'">
								{{ itemMeta.item.name }}
								<span style="display: flex; justify-content: center; align-items: center; font-size: 16px" *ngIf="itemMeta.item.object['uslugiBrutto'] > 0" class="material-symbols-outlined info-service"> info </span>
							</div>
						</div>
					</div>

					<div class="time-sch-current-time" *ngIf="showCurrentTime" [title]="currentTimeTitle" [style.visibility]="currentTimeVisibility" [style.left]="currentTimeIndicatorPosition"></div>
				</div>
			</div>
		</ng-container>

		<ng-container *ngIf="isMobileView">
			<div class="time-sch-content-wrap" [ngClass]="{ noClick: isHousekeeping }" *ngIf="sectionItems.length > 0">
				<perfect-scrollbar [config]="{ wheelPropagation: false, suppressScrollX: false }">
					<table class="time-sch-table">
						<tr *ngFor="let sectionItem of sectionItems; trackBy: trackByFn" [style.height]="(roomSectionFlag | async) ? sectionItem['roomHeight'] + 'px' : sectionItem.minRowHeight + 'px'">
							<td
								class="time-sch-section text-center"
								#sectionTd
								(click)="events.SectionClickEvent ? events.SectionClickEvent(sectionItem.section) : false"
								(contextmenu)="events.SectionContextMenuEvent ? events.SectionContextMenuEvent(sectionItem.section, $event) : false"
								[title]="sectionItem.section.tooltip ? sectionItem.section.tooltip : ''"
							>
								<div class="time-sch-section__room">
									<span
										class="time-sch-section__room-name"
										matTooltip="{{ sectionItem.section.object['pokojtypNazwa'] }}"
										*ngIf="this.authService.user.value.hotelCode == '0281' && this.authService.user.value.hotelCode == '0331'"
									>
										{{ sectionItem.section.name }} - {{ sectionItem.section.object['pokojtypNazwa'] }}
									</span>
									<span class="time-sch-section__room-name" *ngIf="this.authService.user.value.hotelCode != '0281' && this.authService.user.value.hotelCode != '0331'">
										{{ sectionItem.section.name }}
									</span>
									<span class="block-n-status">
										<button mat-icon-button class="block-icon" color="basic" matTooltip="Dodaj blokadę" matTooltipPosition="above" (click)="showRoomBlockerDialog(sectionItem.section)">
											<mat-icon>lock</mat-icon>
										</button>
										<span
											class="time-sch-section__status"
											[ngClass]="{
												'time-sch-section__status--dirty': sectionItem.section.object.pokojSprzatany == 1,
												'time-sch-section__status--clean': sectionItem.section.object.pokojSprzatany == 2,
												'time-sch-section__status--checked': sectionItem.section.object.pokojSprzatany == 0
											}"
										>
											{{ sectionItem.section.object.statusSprzatania }}
										</span>
									</span>
								</div>
							</td>
							<td class="graph" *ngFor="let td of header[header.length - 1].headerDetails; trackBy: trackByFn"></td>
						</tr>
					</table>
				</perfect-scrollbar>
				<div class="time-sch-section-wrapper" [style.left]="SectionLeftMeasure" cdkDropListGroup>
					<div
						class="time-sch-section-container"
						cdkDropList
						cdkDropListSortingDisabled
						[cdkDropListData]="sectionItem.section"
						(cdkDropListDropped)="drop($event)"
						*ngFor="let sectionItem of sectionItems; trackBy: trackByFn"
						[style.height]="(roomSectionFlag | async) ? sectionItem['roomHeight'] + 'px' : sectionItem.minRowHeight + 'px'"
					>
						<div
							class="time-sch-item"
							cdkDrag
							cdkDragLockAxis="y"
							cdkDragBoundary=".time-sch-section-wrapper"
							[cdkDragData]="itemMeta.item"
							[cdkDragDisabled]="isMobileView"
							*ngFor="let itemMeta of sectionItem.itemMetas"
							[ngClass]="itemMeta.item.classes"
							(click)="events.ItemClicked ? events.ItemClicked(itemMeta.item) : false"
							(contextmenu)="events.ItemContextMenu ? events.ItemContextMenu(itemMeta.item, $event) : false"
							[style.height]="(roomSectionFlag | async) ? '30px' : minRowHeight + 'px'"
							[style.top]="itemMeta.cssTop + 'px'"
							[style.left]="itemMeta.cssLeft + '%'"
							[style.width]="itemMeta.cssWidth + '%'"
							[style.marginTop]="(roomSectionFlag | async) ? itemMeta.cssMarginTop + 'px' : 0"
						>
							<div
								class="item-drag-placeholder"
								*cdkDragPlaceholder
								[style.height]="(roomSectionFlag | async) ? 30 - 6 + 'px' : minRowHeight - 6 + 'px'"
								[style.left]="itemMeta.cssLeft + '%'"
								[style.width]="'calc(' + itemMeta.cssWidth + '% - 6px)'"
							></div>
							<div class="time-sch-item-content" [title]="itemMeta.item.tooltip ? itemMeta.item.tooltip : ''" [style.height]="(roomSectionFlag | async) ? '30px' : minRowHeight + 'px'">
								{{ itemMeta.item.name }}
								<span style="display: flex; justify-content: center; align-items: center; font-size: 16px" *ngIf="itemMeta.item.object['uslugiBrutto'] > 0" class="material-symbols-outlined info-service"> info </span>
							</div>
						</div>
					</div>

					<div class="time-sch-current-time" *ngIf="showCurrentTime" [title]="currentTimeTitle" [style.visibility]="currentTimeVisibility" [style.left]="currentTimeIndicatorPosition"></div>
				</div>
			</div>
		</ng-container>

		<form class="form-container" [formGroup]="bookingResForm" *ngIf="showResForm && !isMobileView" cdkDrag>
			<mat-card class="booking-scheduler__form">
				<mat-card-subtitle class="card-header">
					<div>
						<button mat-button matTooltip="Ukryj" (click)="hideDetails()" type="button">
							<mat-icon>expand_more</mat-icon>
						</button>
						R: {{ choosenObject.rezerwacjaId }} | {{ choosenObject.osobaImieNazwisko }}
					</div>

					<div>
						<button mat-button matTooltip="Zapisz zmiany" type="submit" (click)="onSubmit('formSubmit')" [disabled]="buttonStatus == 'lock'">
							<mat-icon>save</mat-icon>
						</button>
						<button mat-button (click)="lockOrUnlock()" [matTooltip]="lockerTooltip" type="button">
							<mat-icon>{{ buttonStatus }}</mat-icon>
						</button>
					</div>
				</mat-card-subtitle>

				<div class="cards-container">
					<mat-card class="info-card">
						<div class="card__input-wrapper">
							<mat-form-field class="form-element flx-100" appearance="outline" [ngClass]="{ my_class: bookingResForm.controls.osobaTelefon.dirty }">
								<mat-label>Telefon</mat-label>
								<input matInput formControlName="osobaTelefon" placeholder="Telefon" />
							</mat-form-field>

							<mat-form-field class="form-element flx-100" appearance="outline" [ngClass]="{ my_class: bookingResForm.controls.osobaEmail.dirty }">
								<mat-label>Mail</mat-label>
								<input matInput formControlName="osobaEmail" placeholder="Mail" />
							</mat-form-field>

							<mat-form-field class="form-element flx-100" appearance="outline">
								<mat-label>Adres</mat-label>
								<input matInput formControlName="osobaAdres" placeholder="Adres" />
							</mat-form-field>

							<mat-form-field appearance="outline" [ngClass]="{ my_class: bookingResForm.controls.rezerwacjaDataOd.dirty || bookingResForm.controls.rezerwacjaDataDo.dirty }">
								<mat-label>Data pobytu</mat-label>
								<mat-date-range-input [rangePicker]="picker">
									<input matStartDate formControlName="rezerwacjaDataOd" placeholder="Data od" opened="true" />
									<input matEndDate formControlName="rezerwacjaDataDo" placeholder="Data do" />
								</mat-date-range-input>
								<mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
								<mat-date-range-picker #picker></mat-date-range-picker>
							</mat-form-field>

							<mat-form-field class="form-element flx-100" appearance="outline">
								<mat-label>Miejsce</mat-label>
								<input matInput formControlName="polozenieNazwa" placeholder="Miejsce" />
							</mat-form-field>

							<mat-form-field class="form-element flx-100" appearance="outline">
								<mat-label>Data zgłoszenia</mat-label>
								<input matInput formControlName="rezerwacjaDataZglosz" placeholder="Data zgłoszenia" />
							</mat-form-field>
						</div>

						<div class="card__input-wrapper">
							<mat-form-field class="form-element flx-100" appearance="outline">
								<mat-label>Pokój</mat-label>
								<input matInput formControlName="pokojNazwa" placeholder="Pokój" />
							</mat-form-field>

							<mat-form-field class="form-element flx-100" appearance="outline" [ngClass]="{ my_class: bookingResForm.controls.rezerwacjaIleDoroslych.dirty }">
								<mat-label>Dorosłych</mat-label>
								<input matInput formControlName="rezerwacjaIleDoroslych" placeholder="Dorosłych" />
							</mat-form-field>

							<mat-form-field class="form-element flx-100" appearance="outline" [ngClass]="{ my_class: bookingResForm.controls.rezerwacjaIleDzieci.dirty }">
								<mat-label>Dzieci</mat-label>
								<input matInput formControlName="rezerwacjaIleDzieci" placeholder="Dzieci" />
							</mat-form-field>

							<mat-form-field class="form-element flx-100" appearance="outline" [ngClass]="{ my_class: bookingResForm.controls.pobytBrutto.dirty }">
								<mat-label>Cena brutto (pobyt brutto + dodatki)</mat-label>
								<input matInput [value]="choosenObject['razemBrutto']" disabled placeholder="Cena" />
							</mat-form-field>

							<mat-form-field class="form-element flx-100" appearance="outline">
								<mat-label>Kod do zamka</mat-label>
								<input matInput formControlName="passCode" placeholder="Kod do zamka" />
							</mat-form-field>

							<mat-form-field class="form-element flx-100" appearance="outline">
								<mat-label>Źródło</mat-label>
								<input matInput formControlName="zrodloRezerwacji" placeholder="Źródło" />
							</mat-form-field>
						</div>
					</mat-card>

					<mat-card class="comments-card">
						<mat-form-field appearance="outline" [ngClass]="{ my_class: bookingResForm.controls.rezerwacjaUwagimemo.dirty }">
							<mat-label>Uwagi</mat-label>
							<textarea matInput cdkAutosizeMinRows="3" rows="4" cdkAutosizeMaxRows="5" formControlName="rezerwacjaUwagimemo" style="resize: none"> </textarea>
						</mat-form-field>

						<mat-form-field appearance="outline" [ngClass]="{ my_class: bookingResForm.controls.rezerwacjaUwagiPortal.dirty }">
							<mat-label>Uwagi od Gościa</mat-label>
							<textarea maxlength="150" matInput rows="4" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" formControlName="rezerwacjaUwagiPortal" #infoLength style="resize: none"></textarea>
						</mat-form-field>
					</mat-card>

					<mat-card class="functions-card">
						<button class="btn btn-xs btn-ek btn-info-ek" [disabled]="buttonStatus == 'lock'" (click)="openCheckinDialog(choosenObject)">Zamelduj</button>
						<button class="btn btn-xs btn-ek btn-info-ek" [disabled]="buttonStatus == 'lock'" (click)="sendMail(choosenObject)">Prześlij link</button>
						<button class="btn btn-xs btn-ek btn-info-ek" [disabled]="buttonStatus == 'lock'" (click)="addAdvancePayment(choosenObject)">Dodaj zaliczkę</button>
						<button class="btn btn-xs btn-ek btn-info-ek" [disabled]="buttonStatus == 'lock'" (click)="addClimatFee(choosenObject)" *ngIf="authService.user.value.hotelCode === '0332'">Dodaj opłatę miejscową</button>
						<button class="btn btn-xs btn-ek btn-info-ek" [disabled]="buttonStatus == 'lock'" *ngIf="isTTLockAvailable == 1" (click)="generateKeyRezerwacja(choosenObject)">Generuj klucz</button>
						<button class="btn btn-xs btn-ek btn-info-ek" [disabled]="buttonStatus == 'lock'" *ngIf="choosenObject.passCode" (click)="cancelPasscode(choosenObject.rezerwacjaId, choosenObject.pokojNazwa)">Wyłącz klucz</button>
						<button class="btn btn-xs btn-ek btn-info-ek" [disabled]="buttonStatus == 'lock'" (click)="onCancelRes(choosenObject)">Anuluj rezerwację</button>
					</mat-card>
				</div>
			</mat-card>
		</form>

		<form class="form-container" [formGroup]="bookingMelForm" *ngIf="showMelForm && !isMobileView" cdkDrag>
			<mat-card class="booking-scheduler__form">
				<mat-card-subtitle class="card-header">
					<div>
						<button mat-button matTooltip="Ukryj" (click)="hideDetails()">
							<mat-icon>expand_more</mat-icon>
						</button>
						M: {{ choosenObject.meldunekId }} | {{ choosenObject.osobaImieNazwisko }}
					</div>

					<div>
						<button mat-button matTooltip="Zapisz zmiany" [disabled]="buttonStatus == 'lock'" type="submit" (click)="onSubmit('formMelSubmit')">
							<mat-icon>save</mat-icon>
						</button>
						<button mat-button (click)="lockOrUnlock()">
							<mat-icon>{{ buttonStatus }}</mat-icon>
						</button>
					</div>
				</mat-card-subtitle>

				<div class="cards-container">
					<mat-card class="info-card">
						<div class="card__input-wrapper">
							<mat-form-field class="form-element flx-100" appearance="outline">
								<mat-label>Telefon</mat-label>
								<input matInput formControlName="osobaTelefon" placeholder="Telefon" />
							</mat-form-field>

							<mat-form-field class="form-element flx-100" appearance="outline">
								<mat-label>Mail</mat-label>
								<input matInput formControlName="osobaEmail" placeholder="Mail" />
							</mat-form-field>

							<mat-form-field class="form-element flx-100" appearance="outline">
								<mat-label>Adres</mat-label>
								<input matInput formControlName="osobaAdres" placeholder="Adres" />
							</mat-form-field>

							<mat-form-field appearance="outline">
								<mat-label>Data pobytu</mat-label>
								<mat-date-range-input [rangePicker]="picker">
									<input matStartDate formControlName="meldunekDataOd" placeholder="Data od" opened="true" />
									<input matEndDate formControlName="meldunekDataDo" placeholder="Data do" />
								</mat-date-range-input>
								<mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
								<mat-date-range-picker #picker></mat-date-range-picker>
							</mat-form-field>

							<mat-form-field class="form-element flx-100" appearance="outline">
								<mat-label>Miejsce</mat-label>
								<input matInput formControlName="polozenieNazwa" placeholder="Miejsce" />
							</mat-form-field>

							<mat-form-field class="form-element flx-100" appearance="outline">
								<mat-label>Data zgłoszenia</mat-label>
								<input matInput formControlName="rezerwacjaDataZglosz" placeholder="Data zgłoszenia" />
							</mat-form-field>
						</div>

						<div class="card__input-wrapper">
							<mat-form-field class="form-element flx-100" appearance="outline">
								<mat-label>Pokój</mat-label>
								<input matInput formControlName="pokojNazwa" placeholder="Pokój" />
							</mat-form-field>

							<mat-form-field class="form-element flx-100" appearance="outline">
								<mat-label>Dorosłych</mat-label>
								<input matInput formControlName="meldunekIleDoroslych" placeholder="Dorosłych" />
							</mat-form-field>

							<mat-form-field class="form-element flx-100" appearance="outline">
								<mat-label>Dzieci</mat-label>
								<input matInput formControlName="meldunekIleDzieci" placeholder="Dzieci" />
							</mat-form-field>

							<mat-form-field class="form-element flx-100" appearance="outline">
								<mat-label>Cena</mat-label>
								<input matInput formControlName="pobytBrutto" placeholder="Cena" />
							</mat-form-field>

							<mat-form-field class="form-element flx-100" appearance="outline">
								<mat-label>Kod do zamka</mat-label>
								<input matInput formControlName="passCode" placeholder="Kod do zamka" />
							</mat-form-field>

							<mat-form-field class="form-element flx-100" appearance="outline">
								<mat-label>Źródło</mat-label>
								<input matInput formControlName="zrodloRezerwacji" placeholder="Źródło" />
							</mat-form-field>
						</div>
					</mat-card>

					<mat-card class="comments-card">
						<mat-form-field appearance="outline" [ngClass]="{ my_class: bookingMelForm.controls.meldunekUwagimemo.dirty }">
							<mat-label>Uwagi</mat-label>
							<textarea matInput cdkAutosizeMinRows="3" rows="4" cdkAutosizeMaxRows="5" formControlName="meldunekUwagimemo" style="resize: none"></textarea>
						</mat-form-field>

						<mat-form-field appearance="outline" [ngClass]="{ my_class: bookingMelForm.controls.meldunekUwagiPortal.dirty }">
							<mat-label>Uwagi od Gościa</mat-label>
							<textarea maxlength="150" matInput rows="4" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" formControlName="meldunekUwagiPortal" #infoLength style="resize: none"></textarea>
						</mat-form-field>
					</mat-card>

					<mat-card class="functions-card">
						<button class="btn btn-xs btn-ek btn-info-ek" [disabled]="buttonStatus == 'lock'" (click)="sendMail(choosenObject)">Prześlij link</button>
						<button class="btn btn-xs btn-ek btn-info-ek" [disabled]="buttonStatus == 'lock'" *ngIf="isTTLockAvailable == 1" (click)="generateKeyRezerwacja(choosenObject)">Generuj klucz</button>
						<button class="btn btn-xs btn-ek btn-info-ek" [disabled]="buttonStatus == 'lock'" (click)="onCancelMel(choosenObject)">Anuluj meldunek</button>
					</mat-card>
				</div>
			</mat-card>
		</form>
	</div>
</div>
<app-loading [isLoading]="sectionItems.length === 0 && !this.stayinsService.emptyResponse.value"></app-loading>
<div class="info" *ngIf="sectionItems.length === 0 && this.stayinsService.emptyResponse.value"> Brak danych</div>