<nav class=" navbar  navbar-absolute navbar-transparent">
  <div class=" container-fluid px-0">
    <div class=" navbar-wrapper">
      <div class="navbar-toggle d-inline">
        <button class=" navbar-toggler" type="button" (click)="sidebarService.sidebarToggle()">
          <span class=" navbar-toggler-bar bar1"> </span>
          <span class=" navbar-toggler-bar bar2"> </span>
          <span class=" navbar-toggler-bar bar3"> </span>
        </button>
      </div>
      <h4 class="welcome-text">Witaj, <span>{{ userName }}</span> !</h4>
    </div>

    <div class="navbar-actions">
      <span *ngIf="isReservationNewVisible" class="material-icons" matTooltip="Nowa rezerwacja"
        [routerLink]="['/reception/reservations/new']">
        hail
      </span>
      <span *ngIf="isAssignmentVisible" class="material-icons" matTooltip="Przypisz pracownika do lokalizacji"
      (click)="openAssignmentDialog()">
      person_pin_circle
    </span>
      <span *ngIf="isHKNewVisible" class="material-icons" matTooltip="Zgłoszenia serwisowe"
        [routerLink]="['/housekeeping']">
        construction
      </span>

      <div class="photo" mat-button [matMenuTriggerFor]="menu">
        <img alt="Profile Photo" src="assets/img/anime3.png" />
      </div>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onLogout()">
          <span class="material-icons">logout</span> Wyloguj</button>
        <button mat-menu-item (click)="onRedirect('/settings')">
          <span class="material-icons">settings</span>Ustawienia</button>
      </mat-menu>

    </div>


    <div class=" navbar-collapse" [ngbCollapse]="isCollapsed" id="navigation">
      <ul class=" navbar-nav ml-auto">

        <li class=" nav-item" (click)="onLogout()">
          <a class=" nav-link" href="javascript:void(0)">
            <div class=" photo">
              <img alt="Profile Photo" src="assets/img/anime3.png" />
            </div>
            <span>Wyloguj</span>
          </a>

        </li>
        <li class=" separator d-lg-none"></li>
      </ul>
    </div>
  </div>
</nav>

<ng-template #content let-modal>
  <div class=" modal-header">
    <button aria-label="Close" class=" close" data-dismiss="modal" type="button" (click)="modal.dismiss('Cross click')">
      <i class=" tim-icons icon-simple-remove"> </i>
    </button>
  </div>

</ng-template>
