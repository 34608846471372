import { Component, OnInit, OnDestroy, ViewChildren, QueryList, ElementRef, Renderer2 } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { AuthService } from 'src/app/auth/auth.service';
import { ROUTES } from '../../shared/models/routes-links.model';
import { version } from 'package.json';
import { HotelModules, HOTELS, HotelsRelations } from '../../../app/auth/models/hotels-list.model';
import { SidebarService } from './sidebar.service';
import { AppServiceService } from 'src/app/pages/app-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReceptionService } from 'src/app/pages/reception/reception.service';

@Component({
	selector: 'app-sidebar',
	templateUrl: './sidebar.component.html',
	styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
	@ViewChildren('links') private links: QueryList<ElementRef>;
	menuItems: any[];
	credentialsSubscription: Subscription;
	$hotelName: Subscription;
	routeSubscription: Subscription;
	hkMode: boolean;
	public appVersion = version;
	public hotelName: string = '';
	public hotelList: any = [];
	public activeModules = [];
	versionDate: Date = new Date();
	public selectHotelForm: FormGroup = null;
	public ifSolMarina: boolean = false;
	public isTitleShown: boolean[] = [false];
	public isTitleActive: boolean[] = [true];

	constructor(private authService: AuthService, private renderer: Renderer2, private router: Router, public sidebarService: SidebarService, public appService: AppServiceService, private formBuilder: FormBuilder, private receptionService: ReceptionService,) {
		this.selectHotelForm = this.formBuilder.group({
			selectedHotel: ['', Validators.required],
		});
		this.authService.hotelList.subscribe((hotels) => {
			if (hotels) {
				this.hotelList = hotels;
			}
		});
	}

	ngOnInit(): void {
		// Set links on the navigation
		this.credentialsSubscription = this.authService.credentials.subscribe((res) => {
			this.$hotelName = this.authService.user.subscribe((user) => {
				if (user) {
					this.hotelName = user.hotelName;
					this.appService.getPmsModuleConfig().subscribe((res) => {
						if (res.length >= 1) {
							this.activeModules = [];
							res.map((module) => {
								if (module.active == true) {
									this.activeModules.push(module.name);
								}
							});

							this.menuItems = ROUTES.filter((menuItem) => {
								if (this.hkMode) {
									return menuItem.hkModeEnabled;
								}
								if (this.activeModules?.indexOf(menuItem.title.toLowerCase()) == -1 && this.activeModules?.indexOf('all') == -1) {
									return false;
								}
								const filteredMenu = res.filter(item => !item.active)
								menuItem.subMenu = menuItem.subMenu.filter(menuItem => 
									!filteredMenu.some(inactiveItem => 
										menuItem.path.includes(inactiveItem.name)
									)
								);
								return menuItem;
							});

							//Add property to control collapse behavior of nav items
							for (const menuItem of this.menuItems) {
								// Set active item open after reload
								if (menuItem.path.split('/').slice(1, 2)[0] === this.router.url.split('/').slice(1, 2)[0]) {
									menuItem.isHide = false;
						
								} else {
									menuItem.isHide = true;
								}
							}
						}
					});
				}
			});
			this.selectHotelForm.get(['selectedHotel']).setValue(this.hotelName);
			this.hkMode = res.hkMode;
		});

		// Control nav items collapse on route change
		this.routeSubscription = this.router.events.pipe(
			filter((event) => event instanceof NavigationEnd)
		  ).subscribe((event: NavigationEnd) => {
			if(event.url.includes("/raporty_online")){
				this.receptionService.getLinkByParamValue("RaportyOnline_AdresWWW").subscribe(res => {
					window.open(res, '_blank');
				})
			}
			setTimeout(() => {
			  for (const menuItem of this.menuItems) {
				if (menuItem.path.split('/').slice(1, 2)[0] === event.url.split('/').slice(1, 2)[0]) {
				  menuItem.isHide = false;
				} else {
				  menuItem.isHide = true;
				}
			  }
			});
		  });

		// for (let i = 0; i < this.menuItems.length; i++) {
		// 	this.isTitleShown[i] = false;
		// 	if (i != 0) this.isTitleActive[i] = false;
		// }
	}
	changeHotelDb(hotel) {
		this.authService.setActivatedHotelCode(hotel.hotelCode);
		this.authService.createUserAuthObject(hotel.hotelCode, this.authService.loggedUserInHotels[hotel.hotelCode]);
		// this.router.navigate(["/dashboard"]);
		window.location.reload();
	}

	expand($event, index: number, ifSubMenu: boolean = false) {

		if (!ifSubMenu) this.isTitleShown[index] = !this.isTitleShown[index];
		for (let i = 0; i < this.menuItems.length; i++) {
			if (i != index) this.isTitleActive[i] = false;
		}
		if (ifSubMenu) this.isTitleActive[index] = true;
		else this.isTitleActive[index] = this.isTitleShown[index];
	}

	isMobileMenu(): boolean {
		if (window.innerWidth > 991) {
			return false;
		}
		return true;
	}

	ngOnDestroy(): void {
		this.credentialsSubscription.unsubscribe();
		this.routeSubscription.unsubscribe();
		this.$hotelName.unsubscribe();
	}
}
