<h2 mat-dialog-title *ngIf="data.rezerwacjaId && !data.meldunekId">R: {{ data.rezerwacjaId }} | {{ data.osobaImieNazwisko }}</h2>
<h2 mat-dialog-title *ngIf="data.meldunekId">M: {{ data.rezerwacjaId }} | {{ data.osobaImieNazwisko }}</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="bookingResForm" *ngIf="data.rezerwacjaId && !data.meldunekId">
    <h3>Podstawowe informacje</h3>
    <mat-form-field class="form-element flx-100" appearance="outline" [ngClass]="{ my_class: bookingResForm.controls.osobaTelefon.dirty }">
      <mat-label>Telefon</mat-label>
      <input matInput formControlName="osobaTelefon" placeholder="Telefon" />
    </mat-form-field>

    <mat-form-field class="form-element flx-100" appearance="outline" [ngClass]="{ my_class: bookingResForm.controls.osobaEmail.dirty }">
      <mat-label>Mail</mat-label>
      <input matInput formControlName="osobaEmail" placeholder="Mail" />
    </mat-form-field>

    <mat-form-field class="form-element flx-100" appearance="outline">
      <mat-label>Adres</mat-label>
      <input matInput formControlName="osobaAdres" placeholder="Adres" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="form-element datepicker-width">
      <mat-label>Data pobytu</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate formControlName="rezerwacjaDataOd" placeholder="Data od" opened="true" />
        <input matEndDate formControlName="rezerwacjaDataDo" placeholder="Data do" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field class="form-element flx-100" appearance="outline">
      <mat-label>Miejsce</mat-label>
      <input matInput formControlName="polozenieNazwa" placeholder="Miejsce" />
    </mat-form-field>

    <mat-form-field class="form-element flx-100" appearance="outline">
      <mat-label>Data zgłoszenia</mat-label>
      <input matInput formControlName="rezerwacjaDataZglosz" placeholder="Data zgłoszenia" />
    </mat-form-field>

    <mat-form-field class="form-element flx-100" appearance="outline">
      <mat-label>Pokój</mat-label>
      <input matInput formControlName="pokojNazwa" placeholder="Pokój" />
    </mat-form-field>

    <mat-form-field class="form-element flx-100" appearance="outline" [ngClass]="{ my_class: bookingResForm.controls.rezerwacjaIleDoroslych.dirty }">
      <mat-label>Dorosłych</mat-label>
      <input matInput formControlName="rezerwacjaIleDoroslych" placeholder="Dorosłych" />
    </mat-form-field>

    <mat-form-field class="form-element flx-100" appearance="outline" [ngClass]="{ my_class: bookingResForm.controls.rezerwacjaIleDzieci.dirty }">
      <mat-label>Dzieci</mat-label>
      <input matInput formControlName="rezerwacjaIleDzieci" placeholder="Dzieci" />
    </mat-form-field>

    <mat-form-field class="form-element flx-100" appearance="outline">
      <mat-label>Cena brutto (pobyt brutto + dodatki)</mat-label>
      <input matInput formControlName="razemBrutto" disabled placeholder="Cena" />
    </mat-form-field>

    <mat-form-field class="form-element flx-100" appearance="outline">
      <mat-label>Kod do zamka</mat-label>
      <input matInput formControlName="passCode" placeholder="Kod do zamka" />
    </mat-form-field>

    <mat-form-field class="form-element flx-100" appearance="outline">
      <mat-label>Źródło</mat-label>
      <input matInput formControlName="zrodloRezerwacji" placeholder="Źródło" />
    </mat-form-field>
    <h3>Dodatkowe informacje</h3>
    <mat-form-field class="form-element flx-100" appearance="outline">
      <mat-label>Uwagi</mat-label>
      <textarea matInput formControlName="rezerwacjaUwagimemo" placeholder="Uwagi"></textarea>
    </mat-form-field>
    <mat-form-field class="form-element flx-100" appearance="outline">
      <mat-label>Uwagi od Gościa</mat-label>
      <textarea matInput formControlName="rezerwacjaUwagiPortal" placeholder="Uwagi od Gościa"></textarea>
    </mat-form-field>
  </form>
  <form [formGroup]="bookingMelForm" *ngIf="data.meldunekId">
    <h3>Podstawowe informacje</h3>
    <mat-form-field class="form-element flx-100" appearance="outline">
      <mat-label>Telefon</mat-label>
      <input matInput formControlName="osobaTelefon" placeholder="Telefon" />
    </mat-form-field>

    <mat-form-field class="form-element flx-100" appearance="outline">
      <mat-label>Mail</mat-label>
      <input matInput formControlName="osobaEmail" placeholder="Mail" />
    </mat-form-field>

    <mat-form-field class="form-element flx-100" appearance="outline">
      <mat-label>Adres</mat-label>
      <input matInput formControlName="osobaAdres" placeholder="Adres" />
    </mat-form-field>

    <mat-form-field appearance="outline" class="form-element datepicker-width">
      <mat-label>Data pobytu</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate formControlName="meldunekDataOd" placeholder="Data od" opened="true" />
        <input matEndDate formControlName="meldunekDataDo" placeholder="Data do" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <mat-form-field class="form-element flx-100" appearance="outline">
      <mat-label>Miejsce</mat-label>
      <input matInput formControlName="polozenieNazwa" placeholder="Miejsce" />
    </mat-form-field>

    <mat-form-field class="form-element flx-100" appearance="outline">
      <mat-label>Data zgłoszenia</mat-label>
      <input matInput formControlName="rezerwacjaDataZglosz" placeholder="Data zgłoszenia" />
    </mat-form-field>

    <mat-form-field class="form-element flx-100" appearance="outline">
      <mat-label>Pokój</mat-label>
      <input matInput formControlName="pokojNazwa" placeholder="Pokój" />
    </mat-form-field>

    <mat-form-field class="form-element flx-100" appearance="outline">
      <mat-label>Dorosłych</mat-label>
      <input matInput formControlName="meldunekIleDoroslych" placeholder="Dorosłych" />
    </mat-form-field>

    <mat-form-field class="form-element flx-100" appearance="outline">
      <mat-label>Dzieci</mat-label>
      <input matInput formControlName="meldunekIleDzieci" placeholder="Dzieci" />
    </mat-form-field>

    <mat-form-field class="form-element flx-100" appearance="outline">
      <mat-label>Cena</mat-label>
      <input matInput formControlName="pobytBrutto" placeholder="Cena" />
    </mat-form-field>

    <mat-form-field class="form-element flx-100" appearance="outline">
      <mat-label>Kod do zamka</mat-label>
      <input matInput formControlName="passCode" placeholder="Kod do zamka" />
    </mat-form-field>

    <mat-form-field class="form-element flx-100" appearance="outline">
      <mat-label>Źródło</mat-label>
      <input matInput formControlName="zrodloRezerwacji" placeholder="Źródło" />
    </mat-form-field>
    <h3>Dodatkowe informacje</h3>
    <mat-form-field appearance="outline" class="form-element">
      <mat-label>Uwagi</mat-label>
      <textarea matInput cdkAutosizeMinRows="3" rows="4" cdkAutosizeMaxRows="5" formControlName="meldunekUwagimemo" style="resize: none"></textarea>
    </mat-form-field>

    <mat-form-field appearance="outline" class="form-element">
      <mat-label>Uwagi od Gościa</mat-label>
      <textarea maxlength="150" matInput rows="4" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" formControlName="meldunekUwagiPortal" #infoLength style="resize: none"></textarea>
    </mat-form-field>
  </form>
  <h3>Akcje</h3>
  <div>
    <button class="btn btn-xs btn-ek btn-info-ek" [disabled]="!editForm" (click)="openCheckinDialog(data)" *ngIf="data.rezerwacjaId && !data.meldunekId">Zamelduj</button>
    <button class="btn btn-xs btn-ek btn-info-ek" [disabled]="!editForm" (click)="sendMail(data)">Prześlij link</button>
    <button class="btn btn-xs btn-ek btn-info-ek" [disabled]="!editForm" (click)="addAdvancePayment(data)" *ngIf="data.rezerwacjaId && !data.meldunekId">Dodaj zaliczkę</button>
    <button class="btn btn-xs btn-ek btn-info-ek" [disabled]="!editForm" *ngIf="isTTLockAvailable === true" (click)="generateKeyRezerwacja(data)">Generuj klucz</button>
    <button class="btn btn-xs btn-ek btn-info-ek" [disabled]="!editForm" *ngIf="data.passCode && data.rezerwacjaId && !data.meldunekId" (click)="cancelPasscode(data.rezerwacjaId, data.pokojNazwa)">Wyłącz klucz</button>
    <button class="btn btn-xs btn-ek btn-info-ek" [disabled]="!editForm" *ngIf="data.rezerwacjaId && !data.meldunekId" (click)="onCancelRes(data)">Anuluj rezerwację</button>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="saveEditForm('formSubmit')" *ngIf="!data.meldunekId" [disabled]="!editForm" tabindex="-1">
    <mat-icon>
      save
    </mat-icon>
  </button>
  <button mat-button (click)="saveEditForm('formMelSubmit')" *ngIf="data.meldunekId" [disabled]="!editForm" tabindex="-1">
    <mat-icon>
      save
    </mat-icon>
  </button>
  <button mat-button (click)="activeEditForm()" tabindex="-1">
    <mat-icon *ngIf="editForm">
      lock_open
    </mat-icon>
    <mat-icon *ngIf="!editForm">
      lock
    </mat-icon>
  </button>
  <button mat-button mat-dialog-close tabindex="-1">Zamknij</button>
</mat-dialog-actions>
