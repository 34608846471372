import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StaleService } from '../../../../../shared/services/stale.service';
import { StayinsNewService } from '../../../stayins/stayins-new/stayins-new.service';

@Component({
	selector: 'app-add-climat-fee',
	templateUrl: './add-climat-fee.component.html',
	styleUrls: ['./add-climat-fee.component.scss'],
})
export class AddClimatFeeComponent implements OnInit {
	@ViewChild('reason', { static: false }) reason;

	public addClimatFeeForm: FormGroup = this.formBuilder.group({
		rezerwacjaId: [{ value: null, disabled: true }, [Validators.required]],
		formaPlatnosci: [null, [Validators.required]],
	});

	public formyPlatnosci: any;
	public isLoading = true;

	constructor(private staleService: StaleService, private stayinsNewService: StayinsNewService, private formBuilder: FormBuilder, public dialogRef: MatDialogRef<AddClimatFeeComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

	ngOnInit(): void {
		this.staleService.getFormyPlatnosci().subscribe((res) => {
			this.formyPlatnosci = res;
			this.addClimatFeeForm.patchValue({ rezerwacjaId: this.data.rezerwacjaId });
			this.isLoading = false;
		});
	}

	sendRes(res): void {
		if (res === 'confirm') {
			if (this.addClimatFeeForm.invalid) {
				return;
			}
			this.stayinsNewService.utworzDokumentOplatyKlimatycznej(this.addClimatFeeForm.getRawValue()).subscribe(
				(oplata) => {
					console.log(oplata);
					this.dialogRef.close('success');
				},
				(err) => this.dialogRef.close('error')
			);
		} else {
			this.dialogRef.close(res);
		}
	}
}
