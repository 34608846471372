<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="title grabbing">
    <h5 translate> Blokowanie pokoju {{ fromParent.name }}</h5>
  </div>

  <mat-dialog-content class="dialog-content">
    <div class="edit-header" class="form-container">
      <form [formGroup]="roomServiceForm">
        <div class='inputs-container'>
  
          <mat-form-field >
            <mat-label>Zgłaszający</mat-label>
            <input value="{{ user.username }}" required disabled="" matInput type="text">
          </mat-form-field>
  
          <mat-form-field >
            <mat-label>Data od/do</mat-label>
            <mat-date-range-input
              [rangePicker]="datepickerRange" (click)="datepickerRange.open()">
              <input formControlName="zlecenieDataOd" matStartDate value="">
              <input formControlName="zlecenieDataDo" matEndDate value="">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="datepickerRange"></mat-datepicker-toggle>
            <mat-date-range-picker #datepickerRange></mat-date-range-picker>
          </mat-form-field>
  
        </div>
  
        <div class='inputs-container'>
          <mat-form-field >
            <mat-label>Powód zgłoszenia</mat-label>
            <mat-select formControlName="zlecenieRodzajId" required>
              <mat-option *ngFor="let fault of serviceBlockType" [value]="fault.id">
                {{ fault.nazwa }}
              </mat-option>
            </mat-select>        
          </mat-form-field>
          <mat-error class="form-error" *ngIf="roomServiceForm.controls.zlecenieRodzajId.touched && roomServiceForm.controls.zlecenieRodzajId.hasError('required')">
                      Powód zgłoszenia wymagany</mat-error>
          <mat-form-field>
            <mat-label>Przypisana osoba</mat-label>
            <mat-select formControlName="osobaId" [attr.disabled]="fromParent.modalType == 'edit' && !inProgress" required>
              <mat-option *ngFor="let worker of uprawnieniList" [value]="worker.id">
                {{ worker.nazwiskoImie }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-error class="form-error" *ngIf="roomServiceForm.controls.osobaId.touched && roomServiceForm.controls.osobaId.hasError('required')">
            Przypisana osoba wymagana</mat-error>
        </div>
  
        <div class='inputs-container'>
          <mat-form-field >
            <mat-label>Szczegóły</mat-label>
            <textarea formControlName="zlecenieOpis" matInput placeholder="Usterki..."></textarea>
          </mat-form-field>

          <mat-form-field>
            <mat-label>Podjęte działania</mat-label>
            <textarea formControlName="zlecenieOpis2" matInput placeholder="Działania..."></textarea>
          </mat-form-field>
        </div>
        <div class="settings-container">

          <mat-slide-toggle color="accent" [attr.disabled]="fromParent.modalType == 'edit' && !inProgress"
          (change)="changePriority($event)" matInput formControlName="zleceniePriorytet">
          <span>Priorytet {{ priorityStatus }}
          </span>
        </mat-slide-toggle>

        <mat-slide-toggle color="warn" [attr.disabled]="fromParent.modalType == 'edit' && !inProgress"
          (change)="changeBlockade($event)" matInput formControlName="zlecenieStatus" >
          <span>Blokada {{ blockadeStatus }}
          </span>
        </mat-slide-toggle>

          <mat-spinner *ngIf="spinnerActive" [diameter]="40"></mat-spinner>
          <app-lightbox class="lightbox" [images]="fileListImages" [serviceId]="serviceId" 
            [removeArray]="[fileListArray,fileListImages,fileListBase]"></app-lightbox>
          <button mat-raised-button color="accent"  (click)="fileInput.click()">
            <span>
              Dodaj zdjęcie
            </span>
            <input #fileInput type="file" id="photos" accept=".png,.jpg,.jpeg,"
              (change)="makeFilesList($event.target.files)" style="display:none;">
          </button>
  
  
        </div>
      </form>
    </div>
  
    <div class="footer-container">
  
      <button class="bottom-buttons" mat-raised-button data-dismiss="modal" mat-dialog-close translate>Anuluj</button>
  
      <button mat-raised-button class="bottom-buttons" (click)="onClickAddService()" translate>Zapisz</button>

    </div>
  </mat-dialog-content>