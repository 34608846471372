<div class="reason-container" mat-dialog-content>
  <p>Anuluj rezerwację {{data.reservationId}}</p>
  <h3>Podaj powód anulacji</h3>
  <textarea matInput #reason cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2"
    cdkAutosizeMaxRows="6" style="
    width: 100%;
    border: 1px solid #c4c4c4;"></textarea>
</div>
<div mat-dialog-actions class="mat-dialog-actions">
  <button mat-stroked-button class="btn-xs btn-ek btn-transparent-ek" (click)="sendRes('cancel')">Odrzuć</button>
  <button mat-button class="btn-xs btn-ek btn-info-ek" (click)="sendRes('confirm')">Potwierdź anulację</button>
</div>
