import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/auth/models/user.model';
import { EdytujZlecenie, Room } from 'src/app/pages/housekeeping/housekeeping.interface';
import { RoomServiceService } from 'src/app/pages/housekeeping/room-service/room-service.service';
import { ServiceOrderService } from 'src/app/pages/housekeeping/service-order/service-order.service';
import { ReceptionService } from 'src/app/pages/reception/reception.service';
import { ParamService } from '../services/param.service';

class ZdjecieZlecenia {
  constructor(public imageBase64: string, public zlecenieId) { }
}

@Component({
  selector: 'app-room-blocker-dialog',
  templateUrl: './room-blocker-dialog.component.html',
  styleUrls: ['./room-blocker-dialog.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ]
})
export class RoomBlockerDialogComponent implements OnInit {
  public user: User;
  public room: Room;
  public editData: EdytujZlecenie;
  public fileListArray: any[] = [];
  public fileList: any;
  public fileListBase: any[] = [];
  public fileListImages: any[] = [];
  public uprawnieniList: any[];
  public serviceBlockType: any[];
  public fromParent;
  public pokojNr: string;
  public roomServiceForm: FormGroup;
  public today = moment().format("YYYY-MM-DD");
  public tomorrow = moment().add(1, 'days').format("YYYY-MM-DD");
  public uF;
  public inProgress: boolean = true;
  public priorityStatus: string = 'normalny';
  public blockadeStatus: string = 'włączona'
  public spinnerActive: boolean;
  public serviceId: number;
  public defaultTimeOfBlockadeFrom: string ="14:00:00";
  public defaultTimeOfBlockadeTo: string ="12:00:00";

  constructor(
    private dialogRef: MatDialogRef<RoomBlockerDialogComponent>,
    private authService: AuthService,
    public formBuilder: FormBuilder,
    private roomServiceService: RoomServiceService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) data,
    private receptionService: ReceptionService,
    private serviceOrder: ServiceOrderService,
    private param: ParamService
  ) {
    this.authService.user.subscribe((user) => {
      this.user = user;
    });
    this.getServiceActions();
    this.getUprawnieniList();
    this.fromParent = data ;
    //this.param.getDomyslnaGodzinaPrzyjazdu().subscribe((res) => {
  //  this.defaultTimeOfBlockadeFrom=res.paramWartosc;
  //});
  //this.param.getDomyslnaGodzinaWyjazdu().subscribe((res) => {
  //  this.defaultTimeOfBlockadeTo=res.paramWartosc;
  //}); 
  }

  ngOnInit(): void {
    this.spinnerActive = false;
    this.roomServiceForm = this.formBuilder.group({
      osobaId: [null,Validators.required],
      zalozylId: this.user.userId,
      pokojId: this.fromParent.id,
      zlecenieDataOd: [this.today, Validators.required],
      zlecenieDataDo: [this.tomorrow, Validators.required],
      zlecenieCzasOd: null,
      zlecenieCzasDo: null,
      zlecenieRodzajId: [null, Validators.required],
      zlecenieOpis: "",
      zlecenieStatus: true,
      zlecenieOpis2: "",
      zleceniePriorytet: false,

      //zlecenieOpis2: serviceData.zlecenieOpis2,
      //zleceniePriorytet: serviceData.zleceniePriorytet

    });
    
  }

  changePriority(event) {
    if (event.checked === true) {
      this.priorityStatus = 'wysoki'
    } else {
      this.priorityStatus = 'normalny'
    }
  }

  getUprawnieniList(): void {
    this.roomServiceService.getUprawnieniList().subscribe((res) => {
      this.uprawnieniList = res;
    });
  }

  getServiceActions(): void {
    let rawResponse = [];
    this.roomServiceService.getServiceActions().subscribe((res) => {
      rawResponse = res;
      this.serviceBlockType = rawResponse;
    });
  }
  
  onClickAddService() { 
      this.roomServiceForm.markAsUntouched()
    if(this.roomServiceForm.valid) {
      let data = this.roomServiceForm.value;
      data.zleceniePriorytet !== true ? data.zleceniePriorytet = 0 : data.zleceniePriorytet = 1;
      data.zlecenieStatus !== true ? data.zlecenieStatus = 0 : data.zlecenieStatus = 1;
      data.zlecenieStan = data.zlecenieStan == true ? 1 : 0;
      data.zlecenieCzasOd = this.defaultTimeOfBlockadeFrom;
      data.zlecenieCzasDo = this.defaultTimeOfBlockadeTo;
      data.zlecenieDataOd = moment(this.roomServiceForm.controls.zlecenieDataOd.value).format('YYYY-MM-DD')
      data.zlecenieDataDo = moment(this.roomServiceForm.controls.zlecenieDataDo.value).format('YYYY-MM-DD')
      data.zalozylId = this.roomServiceForm.value.osobaId;   
      // data.osobaId = this.roomServiceForm.value.osobaId;
      // data.zlecenieRodzajId = this.roomServiceForm.value.zlecenieRodzajId;

      this.roomServiceService.addServiceRoom(data).subscribe((response) => {
        if (response && response.status == 200) {
          this._snackBar.open(response.entity, '', {duration: 2000})
          let tmpZlecenieId = response.metadata.zlecenieId[0];
          this.addImagesToServiceRoom(tmpZlecenieId);
          this.dialogRef.close('Edited');
        }
      });
    }else {
      this.roomServiceForm.markAllAsTouched();
    }
    console.log(this.roomServiceForm);
  }

  onClickEditService() {
    let zlecenieStatus = this.roomServiceForm.value.zlecenieStatus;
    if (this.roomServiceForm.value.zlecenieStatus === true) {
      zlecenieStatus = 1
    } else if (this.roomServiceForm.value.zlecenieStatus === false) {
      zlecenieStatus = 0
    }
    this.editData = {
      osobaId: this.roomServiceForm.value.osobaId,
      zalozylId : this.roomServiceForm.value.osobaId,
      zleceniePriorytet: this.roomServiceForm.value.zleceniePriorytet == true ? 1 : 0,
      zlecenieId: this.fromParent.serviceOrderObject.zlecenieId,
      zlecenieDataOd: moment(this.roomServiceForm.controls.zlecenieDataOd.value).format('YYYY-MM-DD'),
      zlecenieCzasDo: this.roomServiceForm.value.zlecenieCzasDo,
      zlecenieDataDo: moment(this.roomServiceForm.value.zlecenieDataDo).format('YYYY-MM-DD'),
      zlecenieOpis2: this.roomServiceForm.value.zlecenieOpis2,
      zlecenieStan: this.roomServiceForm.value.zlecenieStan == true ? 1 : 0,
      zlecenieStatus: zlecenieStatus,
      zlecenieOpis: this.roomServiceForm.value.zlecenieOpis,
      zlecenieRodzaj: this.roomServiceForm.value.zlecenieRodzaj
    };
    if(zlecenieStatus == 1){
      this.editData["zlecenieDataOd"] = moment(this.roomServiceForm.value.zlecenieDataOd).format('YYYY-MM-DD');
    }
    this.roomServiceService
      .editServiceRoom(this.editData)
      .subscribe((response) => {
        if (response.status == 200) {
          this.addImagesToServiceRoom(this.editData.zlecenieId);
          this._snackBar.open(
            'Zlecenie zostało edytowane', '', {
              duration: 2000,
            })
          this.dialogRef.close({status:'Edited',data : this.editData});
        }
      });
  }

  onClickResolveService(): void {
    let serviceData = this.fromParent.serviceOrderObject;
    let editData = {
      osobaId: this.roomServiceForm.value.osobaId,
      zalozylId: this.roomServiceForm.value.osobaId,
      zlecenieStatus: 0,
      zlecenieCzasDo: "00:00:00",
      zlecenieId: this.fromParent.serviceOrderObject.zlecenieId,
      zlecenieDataDo: moment(serviceData.zlecenieDataDo).format('YYYY-MM-DD'),
      zlecenieCzasOd: "00:00:00",
      zlecenieDataOd: moment(serviceData.zlecenieDataOd).format('YYYY-MM-DD'),
      zlecenieStan: 1,
      zlecenieOpis2: this.roomServiceForm.value.zlecenieOpis2,
      zlecenieOpis: this.roomServiceForm.value.zlecenieOpis,
      zleceniePriorytet: this.roomServiceForm.value.zleceniePriorytet == true ? 1 : 0,

    };
    this.roomServiceService
      .editServiceRoom(editData)
      .subscribe((response) => {
        if (response.status === 200) {
          this._snackBar.open(
            'Zlecenie zostało zamknięte', '', {
              duration: 2000,
            })
          this.dialogRef.close({status:'Resolved', data : editData});
        }
      });
  }

  addImagesToServiceRoom(zlecenieId): void {
    let successAddedCount = 0;
    this.fileListBase.forEach((zdjecieZleceniaOBJ, index) => {

      zdjecieZleceniaOBJ.zlecenieId = zlecenieId;
      this.roomServiceService
        .addPhotoToServiceRoom(zdjecieZleceniaOBJ)
        .subscribe((res) => {
          if (res.status == 200) {
            successAddedCount++;
          }
          if (index == this.fileListBase.length - 1 && res.status == 200) {
            if (res.status== 200){
              this._snackBar.open(
                `Dodano ${this.fileListBase.length}/${this.fileListBase.length} zdjęć`, '', {
                  duration: 3000,
                }
              );
            }else{
              this._snackBar.open(
                `Wystąpił błąd przy dodawaniu zdjęć`, '', {
                  duration: 3000,
                }
              );
            }
          }
        });
    });
  }

  removeFile(fileIndex) {
    this.fileListArray.splice(fileIndex, 1);
    this.fileListBase.splice(fileIndex, 1);
    this.fileListImages.splice(fileIndex, 1);
  }

  makeFilesList(files: FileList) {
    this.fileList = files;
    this.fileListArray = Array.from(this.fileList);
    this.fileListArray.forEach((element, index) => {
      var file = this.fileList[index];
      if (this.fileList && file) {
        this.resizeImage(50, file);
        this.processFile(file);
      }
    });
  }

  resizeImage(procent: number, image) {
    var img = new Image();
    img.src = image;
    return new Promise((resolve, reject) => {
      img.onload = function () {
        var canvas = document.createElement("canvas");
        img.width = (img.width * procent) / 100;
        img.height = (img.height * procent) / 100;
        var ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0, img.width, img.height);
        resolve(canvas.toDataURL("image/png"));
      };
    });
  }

  changeBlockade(event) {
    if (event.checked === true) {
      this.blockadeStatus = 'włączona'
    } else {
      this.blockadeStatus = 'wyłączona'
    }
  }

  getFiles(zlecenieId: number) {
    this.roomServiceService
      .getPhotoToServiceRoom(zlecenieId)
      .subscribe((response) => {
        response.forEach((imageObject) => {
          this.fileListImages.push({ src: imageObject.imageBase64, imageId: imageObject.imageId });
        });
        this.spinnerActive = false;
      });
  }

  processFile(imageInput: any) {
    const file: File = imageInput;
    const reader = new FileReader();
    reader.addEventListener("load", (event: any) => {
      this.uF = event.target.result;
      let tmpBase = event.target.result;
      let zdjecieZlecenia = new ZdjecieZlecenia(tmpBase, null);
      let fileImage = {
        src: tmpBase,
        type: file.type,
        alt: null,
        name: file.name,
      };
      this.fileListBase.push(zdjecieZlecenia);
      this.resizeImage(30, tmpBase).then((imageResized) => {
        fileImage.src = imageResized;
      });
      this.fileListImages.push(fileImage);
    });

    reader.readAsDataURL(file);
  }
}
