import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, BehaviorSubject } from "rxjs";
import { exhaustMap, take, tap } from "rxjs/operators";

import { Reservation } from "../reservations/models/reservation.model";
import { RESERVATIONS_LIST_PARAMS } from "./models/request-params.model";
import * as moment from "moment";
import { StayinsService } from "../stayins/stayins.service";

@Injectable({
  providedIn: "root",
})
export class ReservationsService {
  reservationsList = new BehaviorSubject<Reservation[]>(null);
  requestParams = new BehaviorSubject<RESERVATIONS_LIST_PARAMS>({
    dataOd: moment().format("YYYY-MM-DD"),
    dataDo: moment().add(7, "days").format("YYYY-MM-DD"),
    // dataDo: moment().add(90, "days").format('YYYY-MM-DD')
  });
  roomList = new BehaviorSubject<any[]>(null);

  constructor(private http: HttpClient, private stayService: StayinsService) { }

  /**
   * Check if reservationsList content exist and set right observable (content from reservationsList or request to API)
   * @returns Observable
   */
  setReservationsList(
    params: RESERVATIONS_LIST_PARAMS
  ): Observable<Reservation[]> {
    return this.reservationsList.pipe(
      take(1),
      exhaustMap((reservations) => {
        // Return if there are data stored in reservationsList
        if (reservations) {
          // Set listener to observe changes of reservationsList
          return this.reservationsList;

          // Return if reservationsList is empty - set request to API
        } else {
          let urlParams = "";

          // Get and set parameters form request
          if (params && params.dataDo && params.dataOd) {
            urlParams = `?dataDo=${params.dataDo}&dataOd=${params.dataOd}`;
            return (
              this.http
                .get<Reservation[]>(
                  "/api/grupa/getRezerwacjeGrupami" + urlParams
                )
                .pipe(
                  exhaustMap((res) => {
                    // Store data in this service
                    this.reservationsList.next(res);
                    this.requestParams.next({
                      dataOd: params.dataOd,
                      dataDo: params.dataDo,
                    });

                    // Set listener to observe changes of reservationsList
                    return this.reservationsList;
                  })
                )
            );

            // Get and set parameters form this service (requestParams)
          } else {
            return this.requestParams.pipe(
              exhaustMap((params) => {
                if (params && params.dataDo && params.dataOd) {
                  urlParams = `?dataDo=${params.dataDo}&dataOd=${params.dataOd}`;
                }
                return this.http
                  .get<Reservation[]>(
                    "api/grupa/getRezerwacjeGrupami" + urlParams
                  )
                  .pipe(
                    exhaustMap((res) => {
                      this.reservationsList.next(res);

                      // Set listener to observe changes of reservationsList
                      return this.reservationsList;
                    })
                  );
              })
            );
          }
        }
      })
    );
  }

  /**
   * Update reservationsList and requestParams data
   * @param  {RESERVATIONS_LIST_PARAMS} params
   * @returns Observable
   */
  changeReservationsList(
    params: RESERVATIONS_LIST_PARAMS
  ): Observable<Reservation[]> {
    let urlParams = "";
  
    if (params && params.dataDo && params.dataOd) {
      urlParams = `?dataDo=${params.dataDo}&dataOd=${params.dataOd}`;
      if (this.stayService.localizationId.value && this.stayService.localizationId.value != 0) {
        urlParams += `&polozenieId=${this.stayService.localizationId.value}`;
      }
      this.requestParams.next({
        dataOd: params.dataOd,
        dataDo: params.dataDo,
      });
    } else {
      this.requestParams.next(null);
    }
    return this.http
      .get<Reservation[]>("api/rezerwacja/getRezerwacjeList" + urlParams)
      .pipe(
        take(1),
        tap((res) => {
          this.reservationsList.next(res);
        })
      );
  }

  getPolozeniaList(): Observable<any[]> {
    return this.http.get<any[]>("/api/pokoj/getPolozeniaList")
  }

  getAllReservationsList(): Observable<Reservation[]> {
    return this.http
      .get<Reservation[]>("api/rezerwacja/getRezerwacjeList")
      .pipe(
        take(1),
        tap((res) => {
          this.reservationsList.next(res);
          this.requestParams.next(null);
        })
      );
  }

  sendInvoiceToEmail(body){
    return this.http.post('/api/mail/enqueueFakturaMail', body)
  }

  getReservationsViaGroupsList(params: RESERVATIONS_LIST_PARAMS): Observable<Reservation[]> {
    let urlParams = `?pokazAnulowane=${params.showCanceled}&dataDo=${params.dataDo}&dataOd=${params.dataOd}&pokazZameldowane=${params.showCheckedIn}`;
    return this.http
      .get<Reservation[]>("api/grupa/getRezerwacjeGrupami" + urlParams)
      .pipe(
        take(1),
        tap((res) => {
          this.reservationsList.next(res);
        })
      );
  }

  /**
   * Check if roomList content exist and set right observable (content from roomList or request to API)
   * @returns Observable
   */
  setRoomList(): Observable<any[]> {
          return this.http.get<any[]>("api/pokoj/getPokojList")
  }

  /**
   * Set oservable to any changing of content in the roomList
   * @returns Observable
   */
  getRoomList(): Observable<any[]> {
    return this.roomList;
  }

  /**
   * Set oservable to any changing of content in the reservationsList
   * @returns Observable
   */
  getReservationsList(): Observable<Reservation[]> {
    return this.reservationsList;
  }
  getReservationInfo(reservationId): Observable<Reservation> {
    return this.http.get<Reservation>(
      "api/rezerwacja/getRezerwacjaInfo?rezerwacjaId=" + reservationId
    );
  }

  settleMyLoads(data): Observable<any> {
    return this.http.post("api/portal/meldunek/rozliczMojeObciazenia", data);
  }

  reservationCancel(reason: any, type: string): Observable<any> {
    if (type === "single") {
      return this.http.post("api/rezerwacja/anulujRezerwacje", reason);
    } else {
      return this.http.post("api/grupa/anulujGrupe", reason);
    }
  }

  sendOffer(data): Observable<any> {
    return this.http.post<any>("api/rezerwacja/wyslijOferte", data);
  }
  sendOfferAgain(data): Observable<any> {
    return this.http.post<any>("api/rezerwacja/wyslijOfertePonownie", data);
  }
  edytujRezerwacje(data): Observable<any> {
    return this.http.put<any>("api/rezerwacja/edytujRezerwacje", data);
  }
  zmienPokojAktywnejRezerwacji(data): Observable<any> {
    return this.http.put<any>(`api/rezerwacja/zmienPokojAktywnejRezerwacji?rezerwacjaId=${data.rezerwacjaId}&pokojId=${data.pokojId}`,{});
  }
  edytujMeldunek(data): Observable<any> {
    return this.http.put<any>("api/meldunek/edytujMeldunek", data);
  }
  checkinReservation(data): Observable<any> {
    return this.http.post<any>("api/meldunek/zameldujPokoj", data);
  }
}
