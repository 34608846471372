import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-checkin-reservation",
  templateUrl: "./checkin-reservation.component.html",
  styleUrls: ["./checkin-reservation.component.scss"],
})
export class CheckinReservationComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CheckinReservationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}
  sendRes(res): void {
    this.dialogRef.close(res);
  }
}
