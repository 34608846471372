<div class="wrapper">
  <div class="main-panel">
    <div class=" content-no-sidebar">
      <div class="card login-form">
        <div class="card-header text-center d-flex justify-content-center">
          <div class="logo-img">
            <img src="./assets/img/logo.png">
          </div>
          <h5 class="title m-0">EKONCEPT PMS</h5>
        </div>
        <div class="card-body pb-0">
          <form #authForm="ngForm">
            <div class=" row justify-content-center">
              <div class=" col-md-8">
                <div class=" form-group">
                  <label for="exampleInputEmail1"> Login</label>
                  <input class=" form-control" name="user" placeholder="Login" type="text" ngModel required
                    (keydown.enter)="onSubmit(authForm)" />
                </div>
              </div>
            </div>

            <div class=" row justify-content-center">
              <div class=" col-md-8">
                <div class=" form-group">
                  <label> Hasło </label>
                  <input class=" form-control" name="password" placeholder="Hasło" type="password" ngModel required
                    (keydown.enter)="onSubmit(authForm)" />
                </div>
              </div>
            </div>
            <div class=" row justify-content-center">
              <div class=" col-md-8">
                <div class=" form-group">
                  <label> Kod hotelu </label>
                  <input class=" form-control" name="hotelCode" placeholder="Kod hotelu" type="text" ngModel required
                    pattern="^[0-9]*$" (keydown.enter)="onSubmit(authForm)" />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class=" card-footer text-center">
          <button class=" btn btn-info btn-info-ek" (click)="onSubmit(authForm)"
            [disabled]="!authForm.valid || loggingIn">
            <ng-container *ngIf="!loggingIn; else loader">
              Zaloguj
            </ng-container>
            <ng-template #loader>
              <mat-progress-spinner color="primary" mode="indeterminate" [diameter]="20" *ngIf="loggingIn">
              </mat-progress-spinner>
            </ng-template>
          </button>
          <!--<a href="/multiproperty/register-hotel">Zarejestruj</a>-->
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
<app-colors-panel></app-colors-panel>
