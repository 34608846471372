import { AfterViewInit, Component, EventEmitter, Injectable, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { AddonsSettingsService } from 'src/app/pages/settings/addons-settings/addons-settings.service';
import { accommodationColumns } from '../../../reception.interface';
import { ReceptionService } from '../../../reception.service';
import { SummaryAddonsService } from './summary-addons.service';
import _ from 'lodash';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { startWith } from 'rxjs/operators';

@Component({
	selector: 'app-reservation-table',
	templateUrl: './reservation-table.component.html',
	styleUrls: ['./reservation-table.component.scss'],
})
@Injectable()
export class ReservationTableComponent implements OnInit, AfterViewInit {
	@ViewChild(MatTabGroup) matTabGroup: MatTabGroup;
	@ViewChild(MatTable) table: MatTable<any>;
	@ViewChild(MatSort) sort: MatSort;
	@Input() displayedColumns;
	@Input() roomTypes;
	@Input() formData: FormGroup;
	@Input() filteredData = new BehaviorSubject([]);
	@Input() choosenDate: FormGroup;
	@Input() isLoading: boolean = true;
	@Input() people;
	@Input() events: Observable<void>;
	@Input('summaryCost') summaryCost: number;
	@Output() bookingObject = new EventEmitter<any>();
	@Output() $accomodationArrayWithGuestCount = new EventEmitter<any>();
	@Output() updateClimatFee = new EventEmitter<any>();
	selectedIndex = 0;
	private eventsSubscription: Subscription;
	public dataSource = new MatTableDataSource();
	public accommodationSource = new MatTableDataSource();
	public color: ThemePalette = 'primary';
	public mode: ProgressSpinnerMode = 'indeterminate';
	public accommodationColumns: accommodationColumns[] = [{ name: 'Pokój' }, { name: 'Wartość' }, { name: 'Akcje' }];
	public accommodationArray = [];
	public addonDateFrom: string = '';
	public addonDateTo: string = '';
	public listOfAddons = [];
	public occupiedRooms: string[];
	public panelOpenState = false;
	public accommodationForm = new FormGroup({
		priceRooms: new FormArray([]),
	});
	public numberOfNights = 0;
	public occupiedRoomsCount: number = 0;
	public clearDataFlag = false;
	public startDate: any = '';
	public endDate: any = '';
	public totalServiceNumber = 0;

	constructor(private receptionService: ReceptionService, public authService: AuthService, public addonsSettingsService: AddonsSettingsService, public summaryAddonsService: SummaryAddonsService, public _snackBar: MatSnackBar) {}

	ngOnInit(): void {
		this.addonsSettingsService.getDodatekWrList(true).subscribe((addonsHotel) => (this.listOfAddons = addonsHotel));
		// this.dataSource = new MatTableDataSource(this.roomTypes);
		this.filteredData.subscribe((data) => {
			if (data) {
				this.selectedIndex = 0;
				this.roomTypes = data;
				// this.dataSource = new MatTableDataSource(this.roomTypes);
				this.check();
			}
		});
		this.choosenDate.valueChanges.subscribe((selectedDate) => {
			this.startDate = moment(selectedDate.toDate);
			this.endDate = moment(selectedDate.fromDate);
			if (this.startDate && this.endDate) {
				this.numberOfNights = this.startDate.diff(this.endDate, 'days');
				this.selectedIndex = 0;
				this.dataSource = new MatTableDataSource([]);
				this.summaryAddonsService.accommodationArraySubject.next([]);
			}
		});
		this.summaryAddonsService.clearData.subscribe((data) => {
			if (data) {
				this.selectedIndex = 0;
			}
		});
		const formData$ = this.formData.get('rabatCenaPobytu').valueChanges.pipe(startWith(null));
		const accommodationArray$ = this.summaryAddonsService.getAccommodationArrayObservable();
		combineLatest([formData$, accommodationArray$]).subscribe(([rabatCenaPobytu, accommodationArray]) => {
			const accommodationsArrayForm = this.accommodationForm.get('priceRooms') as FormArray;
			if (accommodationArray.length == 0) {
				this.accommodationArray = [];
				const accommodationsArrayForm = this.accommodationForm.get('priceRooms') as FormArray;
				accommodationsArrayForm.clear();
				this.occupiedRooms = [];
			}
			const originalPriceSum = _.sumBy(accommodationsArrayForm.value, 'price');
			const sumByAddons = _(accommodationArray)
				.flatMap('przydzielonePokoje')
				.flatMap('uslugi')
				.filter((usluga) => _.has(usluga, 'uslugaIlosc'))
				.sumBy((usluga) => {
					if (usluga.uslugaIlosc) {
						switch (usluga.sposobNaliczania) {
							case 'NALICZ_BEZ_OSTATNIEGO_DNIA':
								usluga.asortNumberOfDays = this.numberOfNights;
								break;
							case 'NALICZ_BEZ_PIERWSZEGO_DNIA':
								usluga.asortNumberOfDays = this.numberOfNights;
								break;
							case 'NALICZ_BEZ_PIERWSZEGO_I_OSTATNIEGO_DNIA':
								usluga.asortNumberOfDays = this.numberOfNights - 1 < 0 ? 0 : this.numberOfNights - 1;
								break;
							case 'NALICZ_BRAK_NALICZANIA':
								usluga.asortNumberOfDays = 0;
								break;
							case 'NALICZ_RAZ_W_DNIU_PRZYJAZDU':
								usluga.asortNumberOfDays = 1;
								break;
							case 'NALICZ_RAZ_W_DNIU_WYJAZDU':
								usluga.asortNumberOfDays = 1;
								break;
							case 'NALICZ_WG_ILOSCI_DOB_HOTELOWYCH':
								usluga.asortNumberOfDays = this.numberOfNights;
								break;
							case 'NALICZ_ZAWSZE':
								usluga.asortNumberOfDays = this.numberOfNights + 1;
								break;
							default:
								usluga.asortNumberOfDays = 1;
								break;
						}
						return usluga.uslugaCenaJednBrutto * usluga.uslugaIlosc * usluga.asortNumberOfDays;
					}
				});
			const { originalPrice, discountedPrice } = this.calculatePrices(originalPriceSum, rabatCenaPobytu, sumByAddons);
			this.summaryAddonsService.totalPrice.next({ originalPrice, discountedPrice });
			this.sumServices(this.accommodationArray);
			// this.calculateOccupiedRoomsCount()
		});
	}
	ngAfterViewInit() {
		this.dataSource.sort = this.sort;
	}

	calculatePrices(originalPrice, discountRate, sumByAddons) {
		let discountedPrice;
		if (discountRate === 0 || discountRate === null || discountRate === undefined) {
			// console.log("Rabat jest równy 0, null lub nie został zdefiniowany. Cena nie zostanie obniżona.");
			discountedPrice = undefined;
		} else {
			discountedPrice = originalPrice - (originalPrice * discountRate) / 100;
			// console.log("Cena po rabacie", discountedPrice);
		}

		const totalOriginalPrice = (sumByAddons ? sumByAddons : 0) + originalPrice;
		const finalPrice = discountedPrice !== undefined ? discountedPrice + (sumByAddons ? sumByAddons : 0) : totalOriginalPrice;

		return {
			originalPrice: totalOriginalPrice,
			discountedPrice: finalPrice,
		};
	}

	sumServices(data) {
		this.totalServiceNumber = _(data)
			.flatMap('przydzielonePokoje')
			.flatMap('uslugi')
			.filter((usluga) => _.has(usluga, 'uslugaIlosc'))
			.sumBy((usluga) => usluga.uslugaIlosc);
	}

	calculateOccupiedRoomsCount() {
		this.occupiedRoomsCount = this.occupiedRooms.filter((room) => room !== undefined).length;
	}

	deleteRoom(roomType, roomIndex) {
		if (roomType.pokojId == undefined) {
			this.accommodationArray.splice(Number(roomIndex), 1);
			(this.accommodationForm.get('priceRooms') as FormArray).removeAt(Number(roomIndex));
			this.accommodationArray.map((item, idx) => {
				item.uniq = idx;

				return item;
			});
			return false;
		}
		this.occupiedRooms = _.without(this.occupiedRooms, roomType.pokojId);
		_.remove(this.accommodationArray, { pokojId: roomType.pokojId });
		this.accommodationArray.map((item, idx) => {
			item.uniq = idx;
			return item;
		});
		const priceRooms = this.accommodationForm.get('priceRooms') as FormArray;
		(this.accommodationForm.get('priceRooms') as FormArray).removeAt(Number(roomIndex));
		this.summaryAddonsService.updateAccommodationForm(this.accommodationForm);
		this.summaryAddonsService.accommodationArraySubject.next(this.accommodationArray);
	}

	rmAddon(addon, singleRoom) {
		const findRoom = _.find(this.accommodationArray, { pokojId: singleRoom.pokojId });
		findRoom.przydzielonePokoje.map((item) => {
			item.uslugi.map((addonData) => {
				if (addonData.id == addon.id && addonData.pokojId == singleRoom.pokojId) {
					addonData.uslugaIlosc = addonData.uslugaIlosc === undefined ? 0 : addonData.uslugaIlosc;
					if (addonData.uslugaIlosc > 0) {
						addonData.uslugaIlosc--;
					}
				}
			});
		});
		this.summaryAddonsService.accommodationArraySubject.next(this.accommodationArray);
	}

	addAddon(addon, singleRoom) {
		const findRoom = _.find(this.accommodationArray, { pokojId: singleRoom.pokojId });
		const selectedRoom = _.find(findRoom.przydzielonePokoje, { pokojId: singleRoom.pokojId });
		if (selectedRoom) {
			selectedRoom.uslugi.forEach((addonData) => {
				if (addon.id == addonData.id && addonData.pokojId == singleRoom.pokojId) {
					addonData.uslugaIlosc = addonData.uslugaIlosc === undefined ? 0 : addonData.uslugaIlosc;
					addonData.uslugaIlosc++;
				}
			});
		}
		this.summaryAddonsService.accommodationArraySubject.next(this.accommodationArray);
	}

	handleChangePrice(id: string, price: number) {
		this.accommodationArray[Number(id)]['updatePrice'] = this.accommodationForm.value.priceRooms[Number(id)];
		this.summaryAddonsService.accommodationArraySubject.next(this.accommodationArray);
	}

	handleChangeAdult(id: string, value: string) {
		if (value) {
			(this.accommodationForm.get('priceRooms') as FormArray).at(Number(id)).patchValue({
				price: this.accommodationArray[id].ceny[value].razemBrutto,
			});
		}
		this.accommodationArray[Number(id)]['updatePrice'] = this.accommodationForm.value.priceRooms[Number(id)];
		this.summaryAddonsService.updateAccommodationForm(this.accommodationForm);
		this.summaryAddonsService.accommodationArraySubject.next(this.accommodationArray);
	}

	assinSingleRoom(singleRoom, index, roomsData) {
		const isNumberFound = _.includes(this.occupiedRooms, singleRoom.pokojId);
		if (isNumberFound) {
			this._snackBar.open(`Wybrany pokój został już przydzielony. Wybierz inny.`, 'Błąd', {
				duration: 4000,
			});
			return;
		}
		singleRoom.uslugi = singleRoom.uslugi.map((usluga) => {
			return {
				...usluga,
				pokojId: singleRoom.pokojId,
			};
		});
		this.accommodationArray.map((item) => {
			if (item.uniq === index) {
				item.pokojId = singleRoom.pokojId;
				const guestCounts = [
					{
						count: 2,
						classId: 0,
					},
					{
						count: 0,
						classId: 1,
					},
				];
				singleRoom['ileDodLozek'] = roomsData['ileDodLozek'];
				singleRoom['ileDostepnychPokoi'] = roomsData['ileDostepnychPokoi'];
				singleRoom['ileLozek'] = roomsData['ileLozek'];
				singleRoom['dataOd'] = roomsData['dataOd'];
				singleRoom['dataDo'] = roomsData['dataOd'];
				singleRoom['zrodloRez'] = roomsData['zrodloRez'];
				singleRoom['biuroId'] = roomsData['biuroId'];
				singleRoom['kwotaZaPobyt'] = 0;
				singleRoom['guestCounts'] = guestCounts;
				item.przydzielonePokoje.push(singleRoom);
				if (item.przydzielonePokoje.length > 1) {
					item.przydzielonePokoje = item.przydzielonePokoje.filter((przydzielonyPokoj) => przydzielonyPokoj.pokojId === item.pokojId);
					item.przydzielonePokoje = _.uniqBy(item.przydzielonePokoje, 'pokojId');
				}
				const price = _.get(item, `ceny[${item.przydzielonePokoje[0].ileLozek}].razemBrutto`);
				(this.accommodationForm.get('priceRooms') as FormArray).at(index).patchValue({
					price,
					adultNumber: item.przydzielonePokoje[0].ileLozek,
					uniq: item.uniq,
					maxRooms: item.ileLozek,
					pokojId: item.pokojId,
				});
				this.accommodationArray[Number(index)]['updatePrice'] = this.accommodationForm.value.priceRooms[Number(index)];
				this.summaryAddonsService.updateAccommodationForm(this.accommodationForm);
			} else if (!_.has(item, 'pokojId')) {
				item.przydzielonePokoje = [];
			}
			return item;
		});
		this.occupiedRooms = this.accommodationArray.map((item) => item.pokojId);
		this.summaryAddonsService.accommodationArraySubject.next(this.accommodationArray);
	}

	check(): void {
		this.roomTypes.forEach((el, index) => {
			if (!el.ceny[1]) {
				this.roomTypes.splice(index, 1);
			}
		});
		for (let i = 0; i < this.roomTypes.length; i++) {
			if (this.roomTypes[i].ceny[1].ceny.length == 0) {
				this.roomTypes.splice(i, 1);
			}
		}
		this.roomTypes.forEach((element, index) => {
			element.pokoje.map((item) => {
				let addons = this.listOfAddons.filter((addon) => addon.polozenieNazwa === item.polozenieNazwa);
				if (addons.length === 0) {
					item['uslugi'] = this.listOfAddons;
				} else {
					item['uslugi'] = addons;
				}
			});
			return element;
		});
		this.dataSource = new MatTableDataSource(this.roomTypes);
	}
	sendUpdateClimatFee(): void {
		this.updateClimatFee.emit(true);
	}
	onTabChange(event: MatTabChangeEvent) {
		if (event.index === 2) {
			const updatedAccommodationArray = _.filter(this.accommodationArray, (obj) => {
				if (_.has(obj, 'pokojId')) {
					obj.przydzielonePokoje = obj.przydzielonePokoje.map((pokoj) => {
						pokoj.uslugi = pokoj.uslugi.filter((usluga) => {
							const uslugaStartDate = moment(usluga.dataOd, 'YYYY-MM-DD');
							const uslugaEndDate = moment(usluga.dataDo, 'YYYY-MM-DD');
							if(usluga.nazwa === 'Opłata serwisowa') {
								usluga.uslugaIlosc = 1
							}
							// Sprawdza, czy usługa mieści się w wybranym zakresie dat
							return this.startDate.isSameOrAfter(uslugaStartDate) && this.endDate.isSameOrBefore(uslugaEndDate) && usluga.polozenieId == pokoj.polozenieId
							;
						});
						return pokoj;
					});
					return obj;
				} else {
					obj.przydzielonePokoje = [];
					return obj;
				}
			});
			this.accommodationArray = updatedAccommodationArray;
			this.summaryAddonsService.accommodationArraySubject.next(this.accommodationArray)
		}
	}
	pushToAccommodation(choosenRoom): void {
		const newRoom = { ...choosenRoom, uniq: 0 };
		const maxUniq = Math.max(...this.accommodationArray.map((room) => room.uniq), -1);
		newRoom.uniq = maxUniq + 1;
		this.accommodationArray.push(newRoom);
		const priceRoom = new FormGroup({
			price: new FormControl(0),
			adultNumber: new FormControl(0),
			uniq: new FormControl(0),
			maxRooms: new FormControl(0),
			pokojId: new FormControl(0),
		});
		(this.accommodationForm.get('priceRooms') as FormArray).push(priceRoom);
	}
}
