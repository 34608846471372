declare interface SubMenu {
  path: string;
  title: string;
}
declare interface RouteInfo {
  path: string;
  active: boolean;
  title: string;
  icon: string;
  class: string;
  hkModeEnabled: boolean;
  subMenu: SubMenu[];
  isHide ? : boolean;
}
export const ROUTES: RouteInfo[] = [{
    path: '/dashboard',
    active: true,
    title: 'Dashboard',
    icon: 'icon-chart-pie-36',
    class: '',
    hkModeEnabled: true,
    subMenu: [],
  },
  {
    path: '/reception',
    active: false,
    title: 'Reception',
    icon: 'icon-book-bookmark',
    class: '',
    hkModeEnabled: false,
    subMenu: [{
        path: 'booking-schedule',
        title: 'Grafik rezerwacji',
      },
      {
        path: 'reservations',
        title: 'Reservations',
      },
      {
        path: 'arrivals',
        title: 'Arrivals',
      },
      {
        path: 'stayins',
        title: 'Pobyty',
      },
      {
        path: 'departures',
        title: 'Departures',
      },
      {
        path: 'price-schedule',
        title: 'Grafik cen',
      },
      {
        path: 'pricelist',
        title: 'Pricelist',
      },
      {
        path: 'work-schedule',
        title: 'Grafik pracy',
      },
      {
        path: 'rooms_dostepnosc_wr_be',
        title: 'Udostępnianie pokoi przez BE/VR',
      },
      {
        path: 'rooms_dostepnosc_ota',
        title: 'Udostępnianie pokoi przez OTA',
      },
    ],
  },
  {
    path: '/guest-book',
    active: false,
    title: 'Guest Book',
    icon: 'icon-money-coins',
    class: '',
    hkModeEnabled: false,
    subMenu: [{
        path: 'person',
        title: 'Osoby',
      },
      {
        path: 'company',
        title: 'Firmy',
      },
    ],
  },
  {
    path: '/housekeeping',
    active: true,
    title: 'Housekeeping',
    icon: 'icon-cart',
    class: '',
    hkModeEnabled: true,
    subMenu: [{
        path: 'service-order',
        title: 'Zgłoszenia serwisowe',
      },
      {
        path: 'booking-schedule',
        title: 'Grafik rezerwacji',
      },
    ],
  },
  {
    path: '/reports',
    active: true,
    title: 'Reports',
    icon: 'icon-coins',
    class: '',
    hkModeEnabled: false,
    subMenu: [{
        path: 'foodcost',
        title: 'Foodcost',
      },
      {
        path: 'housekeeping-report',
        title: 'Raport Housekeeping',
      },
      {
        path: 'raportmanagera',
        title: 'Raport Managera',
      },
    ],
  },
  {
    path: '/raporty_online',
    active: true,
    title: 'Raporty_Online',
    icon: 'icon-chart-pie-36',
    class: '',
    hkModeEnabled: false,
    subMenu: [],
  },
  {
    path: '/settings',
    active: true,
    title: 'Settings',
    icon: 'icon-settings-gear-63',
    class: '',
    hkModeEnabled: false,
    subMenu: [{
        path: 'addons',
        title: 'Usługi dodatkowe',
      },
      {
        path: 'service-providers',
        title: 'Usługodawcy',
      },
    ],
  },
  {
    path: '/analytics',
    active: true,
    title: 'Analytics',
    icon: 'icon-paper',
    class: '',
    hkModeEnabled: false,
    subMenu: [{
        path: 'reservations',
        title: 'Reservations',
      },
      {
        path: 'payments',
        title: 'Payments',
      },
      // {
      //   path: "activity",
      //   title: "Activity",
      // },
    ],
  },
  {
    path: '/marketing',
    active: false,
    title: 'Marketing',
    icon: 'icon-money-coins',
    class: '',
    hkModeEnabled: false,
    subMenu: [{
        path: 'discount-codes',
        title: 'Discount Codes',
      },
      {
        path: 'vouchers',
        title: 'Vouchery',
      },
	  {
        path: 'sms-messaging',
        title: 'Wysyłka SMS',
      },
    ],
  },
  {
    path: '/invoices',
    active: true,
    title: 'Invoices',
    icon: 'icon-single-copy-04',
    class: '',
    hkModeEnabled: false,
    subMenu: [],
  },
  {
    path: '/employee',
    active: true,
    title: 'Employee',
    icon: 'icon-user-run',
    class: '',
    hkModeEnabled: false,
    subMenu: [],
  },
  {
    path: '/documents',
    active: true,
    title: 'documents',
    icon: 'icon-single-copy-04',
    class: '',
    hkModeEnabled: false,
    subMenu: [{
      path: 'pos-print',
      title: 'POS print',
    }, ],
  },
];
