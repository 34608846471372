import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';

interface PriceInfo {
	originalPrice: number;
	discountedPrice?: number; // Opcjonalny
  }
  

@Injectable({
	providedIn: 'root',
})
export class SummaryAddonsService {
	public accommodationArraySubject = new Subject<any[]>();
	public totalPrice = new Subject<PriceInfo>();
	public clearData = new BehaviorSubject(false)
	public accommodationFormSource = new BehaviorSubject<FormGroup>(null);
	currentAccommodationForm = this.accommodationFormSource.asObservable();

	constructor() {}

	getAccommodationArrayObservable() {
	  return this.accommodationArraySubject.asObservable();
	}

	updateAccommodationForm(accommodationForm: FormGroup) {
		this.accommodationFormSource.next(accommodationForm);
	}
}
