<ngb-carousel *ngIf="images" id="imageCarousel">
  <ng-template ngbSlide *ngFor="let image of images; let ind = index">
    <div class="picsum-img-wrapper">
      <img [src]="image.src" alt="image.alt">
    </div>
    <div class="carousel-caption">
      <div style="display: inline">
        <button *ngIf="type == add" mat-icon-button class="btn-link btn-danger-ek" (click)="onClickDeleteImage(ind)">
          <mat-icon>delete_forever</mat-icon>
        </button>
        <button mat-icon-button class="btn-link btn-info-ek" (click)="openImageFullscreen(image)">
          <mat-icon>fullscreen</mat-icon>
        </button></div>
    </div>
    <!-- <p class="text-center">{{ image.name }}</p> -->
  </ng-template>
</ngb-carousel>
<div class="row">
  <div class="col-xs-6 col-md-3" *ngFor="let image2 of images;">
    <div class="thumbnail">
      <img [src]="image2.src" alt="image2.alt">
    </div>
  </div>
</div>
