import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule, PreloadAllModules, NoPreloading } from '@angular/router';

import { AuthGuard } from './auth/auth.guard';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthComponent } from './layouts/auth-layout/auth.component';
import { SetNewPasswordComponent } from './layouts/auth-layout/set-new-password/set-new-password.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'dashboard',
		pathMatch: 'full',
	},
	{
		path: '',
		component: AdminLayoutComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: '',
				loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then((m) => m.AdminLayoutModule),
			},
		],
	},
	{
		path: 'multiproperty',
		// component: MultipropertyComponent,
		children: [
			{
				path: '',
				loadChildren: () => import('./layouts/multiproperty/multiproperty.module').then((m) => m.MultipropertyModule),
			},
		],
	},
	{ path: 'auth', component: AuthComponent },
	{ path: 'new-password', component: SetNewPasswordComponent },
	{
		path: '**',
		redirectTo: 'dashboard',
	},
];

@NgModule({
	imports: [
		CommonModule,
		BrowserModule,
		RouterModule.forRoot(routes, {
			onSameUrlNavigation: 'reload',
			preloadingStrategy: PreloadAllModules,
			relativeLinkResolution: 'legacy',
		}),
	],
	exports: [RouterModule, BrowserModule],
})
export class AppRoutingModule {}
