import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
    BehaviorSubject,
    concat,
    forkJoin,
    Observable,
    of,
    throwError,
} from 'rxjs';
import {
    catchError,
    concatMap,
    finalize,
    map,
    take,
    tap,
} from 'rxjs/operators';
import { threadId } from 'worker_threads';
import { MpLoginService } from '../layouts/multiproperty/mp-login/mp-login.service';
import { AppServiceService } from '../pages/app-service.service';
import {
    AuthMPResponseData,
    AuthResponseData,
    ResetPasswordData,
    ResetPasswordResponse,
} from './models/auth-response-data.model';
import { Credentials } from './models/credentials.model';
import { HOTELS, HotelsRelations } from './models/hotels-list.model';
import { User } from './models/user.model';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    public loggedUserResponseList = new BehaviorSubject<any>(null);
    public hotelList = new BehaviorSubject<any[] | null>(null);
    public loggedUserInHotels = {};
    public user = new BehaviorSubject<User>(null);
    public credentials = new BehaviorSubject<Credentials>({ hkMode: true });
    public mpUser = new BehaviorSubject<any>(null);
    private tokenValidityTime = 28800; //8h //in seconds
    private tokenExpirationTimer: any;
    private hotelCode: any = '';

    constructor(
        private router: Router,
        private http: HttpClient,
        private _snackBar: MatSnackBar,
        private mpLoginService: MpLoginService
    ) {}

    /**
     * Login into app
     * @deprecated
     * @param  {string} email
     * @param  {string} password
     * @param  {string} hotelCode
     * @returns Observable
     */
    login(
        email: string,
        password: string,
        hotelCode: string
    ): Observable<AuthResponseData> {
        let config = JSON.parse(localStorage.getItem('config'));
        let loginData = {
            user: email,
            password: password,
            loginType: 'PMS',
            loginPlatform: 'WEB',
            langKod: config.lang ? config.lang : 'pl',
        };
        return this.http
            .post<AuthResponseData>(hotelCode + '/api/login/zaloguj', loginData)
            .pipe(
                tap((res) => {
                    this.createUserAuthObject(hotelCode, res);
                    this.setActivatedHotelCode(hotelCode);
                }),
                catchError((errorRes) => {
                    let errorMessage = 'An unknow error occurred!';
                    if (
                        !errorRes.error ||
                        (!errorRes.error.error && !errorRes?.message)
                    ) {
                        this._snackBar.open(errorMessage, '', {
                            duration: 2000,
                        });
                        return throwError(errorMessage);
                    }

                    if (errorRes.statusText === 'OK') {
                        this._snackBar.open(errorRes.error?.message, '', {
                            duration: 2000,
                        });
                        return throwError(errorRes.error?.message);
                    } else {
                        this._snackBar.open(errorRes?.message, '', {
                            duration: 2000,
                        });
                        return throwError(errorRes?.message);
                    }
                })
            );
    }

    /**
     * Login into app
     *
     * @param  {string} email
     * @param  {string} password
     * @param  {string} hotelCodes
     * @returns Observable
     */
    loginToMultiproperty(
        user: string,
        password: string,
        hotelCode: string
    ): Observable<AuthMPResponseData> {
        let config = JSON.parse(localStorage.getItem('config'));
        let loginMPData = {
            user: user,
            password: password,
            hotelCode: hotelCode,
            // lang: config.lang ? config.lang : 'pl',
            lang: config?.lang ? 'config.lang' : 'pl',
        };
        return this.mpLoginService.login(loginMPData).pipe(
            tap(
                (res) => {
                    this._snackBar.open(
                        'Zalogowano do funkcji Multiproperty',
                        '',
                        { duration: 2000 }
                    );
                    this.router.navigate(['/multiproperty/settings']);
                    const tokenExpirationDate = new Date(
                        new Date().getTime() + this.tokenValidityTime * 1000
                    );
                    const user = new User(
                        res.username,
                        res.userId,
                        hotelCode,
                        res.uuid,
                        tokenExpirationDate,
                        true
                    );
                    user.hotelId = res.hotelId;
                    user.authorities = [];
                    user.permissions = res.permissions;

                    this.hotelList.next(this.getRelatedHotels(user.hotelCode));

                    // Prevent logout when refresh broswer
                    localStorage.setItem('userData', JSON.stringify(user));
                    this.mpUser.next(user);
                    return res;
                },
                (err) => {
                    this._snackBar.open('Błąd logowania', '', {
                        duration: 5000,
                    });
                    return throwError(err);
                }
            )
        );
    }

    /**
     * Login into app
     *
     * @param  {string} email
     * @param  {string} password
     * @param  {string} hotelCodes
     * @returns Observable
     */
    loginToMultiple(
        email: string,
        password: string,
        hotelCodes: any
    ): Observable<any> {
        let config = JSON.parse(localStorage.getItem('config'));
        let loginData = {
            user: email,
            password: password,
            loginType: 'PMS',
            loginPlatform: 'WEB',
            langKod: config.language,
        };
        let requests = [];
        hotelCodes.map((hotelCode) => {
            requests.push(
                this.http
                    .post<AuthResponseData>(
                        hotelCode + '/api/login/zaloguj',
                        loginData
                    )
                    .pipe(catchError((error) => of(error)))
            );
        });
        return forkJoin(requests);
    }
    /**
     * Handle multi login depends of its success, creates only right auth objects
     * @param loginResponses
     * @param hotelCodes
     */
    handleLoginToMultipleHotels(loginResponses: any, hotelCodes: string[]) {
        loginResponses.forEach((loginResponse, index) => {
            if (loginResponse['uuid'] !== undefined) {
                this.loggedUserInHotels[hotelCodes[index]] =
                    loginResponses[index];
                this.createUserAuthObject(
                    hotelCodes[index],
                    loginResponses[index]
                );
            }
        });

        this.loggedUserResponseList.next(this.loggedUserInHotels);
        let hotelCode = hotelCodes[0];
        const allRelatedHotels = this.getRelatedHotels(hotelCode);
        const onlyLoggedHotels = allRelatedHotels.filter((hotel) => {
            if (this.loggedUserResponseList.value[hotel.hotelCode]) {
                return hotel;
            }
        });
        this.hotelList.next(onlyLoggedHotels);
        this.setActivatedHotelCode(hotelCode);
        this.createUserAuthObject(
            hotelCode,
            this.loggedUserResponseList.value[hotelCode]
        );
    }

    createUserAuthObject = (hotelCode, loginResponse) => {
        if (loginResponse == undefined) {
            return this.logout();
        }
        const tokenExpirationDate = new Date(
            new Date().getTime() + this.tokenValidityTime * 1000
        );
        let hotelObj: any = HOTELS.filter((res) => {
            return res.hotelCode == hotelCode;
        });
        const user = new User(
            loginResponse.username,
            loginResponse.userId,
            hotelCode,
            loginResponse.uuid,
            tokenExpirationDate,
            loginResponse.isAdmin,
            loginResponse.authorities[0].authority
        );
        user.employeeOsobaId = loginResponse.employeeOsobaId;
        if (hotelObj[0]) {
            user.hotelName = hotelObj[0].hotelName;
        }
        user.authorities = loginResponse.authorities[0].authority;
        const credentials = new Credentials(loginResponse.hkMode);

        // Set logged user data to share it in app
        this.user.next(user);
        this.credentials.next(credentials);

        // Set timer to logout when token expired
        this.autoLogout(this.tokenValidityTime * 1000);

        let userDataFromStorage = JSON.parse(localStorage.getItem('userData'));
        if (userDataFromStorage == null) {
            userDataFromStorage = {};
        }
        userDataFromStorage[hotelCode] = user;
        // Prevent logout when refresh broswer
        localStorage.setItem('userData', JSON.stringify(userDataFromStorage));
        // localStorage.setItem("userData", JSON.stringify(user));
    };

    /**
     * Login when user is stored in browswer
     *
     * @returns void
     */
    autologin(): void {
        const userData: User = JSON.parse(localStorage.getItem('userData'));
        if (!userData) {
            // this.logout();
            return;
        }

        //multiproperty redirect
        if (parseInt(userData.hotelCode) > 10000) {
            this.mpUser.next(userData);
            return;
        }

        let getLoggedUserReg = [];
        Object.keys(userData).map((key, index) => {
            let loadedUser = userData[key];

            if (loadedUser) {
                getLoggedUserReg.push(
                    this.http.get<AuthResponseData>(
                        loadedUser.hotelCode + '/api/login/getLoggedUser',
                        {
                            headers: {
                                Authorization: 'Bearer ' + loadedUser.uuid,
                            },
                        }
                    )
                );
            }
        });

        forkJoin(getLoggedUserReg)
            .pipe(
                map((res) => {
                    Object.keys(userData).map((hotelCode, index) => {
                        this.loggedUserInHotels[hotelCode] = res[index];
                    });
                    return res;
                }),
                finalize(() => {
                    //after getloged set 0 user as active, and hotel code
                    this.loggedUserResponseList.next(this.loggedUserInHotels);
                    let activatedHotelCode = localStorage.getItem('hotelCode');
                    if (activatedHotelCode !== null) {
                        this.setActivatedHotelCode(activatedHotelCode);
                    }
                    this.createUserAuthObject(
                        activatedHotelCode,
                        this.loggedUserResponseList.value[activatedHotelCode]
                    );
                    const allRelatedHotels =
                        this.getRelatedHotels(activatedHotelCode);
                    const onlyLoggedHotels = allRelatedHotels.filter(
                        (hotel) => {
                            if (
                                this.loggedUserResponseList.value[
                                    hotel.hotelCode
                                ]
                            ) {
                                return hotel;
                            }
                        }
                    );
                    this.hotelList.next(onlyLoggedHotels);
                })
            )
            .subscribe(
                (res) => {
                    // console.log(res);
                    // this._snackBar.open('Ponownie zalogowano.', 'OK', {
                    //     duration: 3000,
                    // });
                },
                (err) => {
                    this.logout();
                    this._snackBar.open(
                        'Jedna z sesji wygasła, zaloguj się ponownie',
                        '',
                        { duration: 5000 }
                    );
                }
            );
    }

    getUserInfo(): Observable<any> {
        // if (localStorage.getItem("userData")) {
        //   let hotelCode = JSON.parse(localStorage.getItem("userData")).hotelCode;
        //   return this.http.get<AuthResponseData>(
        //     hotelCode + "api/login/getLoggedUser"
        //   );
        // }
        return this.http.get<AuthResponseData>('api/login/getLoggedUser');
    }
    /**
     * Resets password in one hotel
     */
    resetPassword(
        reserPasswordData: ResetPasswordData,
        hotelCode
    ): Observable<any> {
        return this.http.post<any>(
            hotelCode + '/api/login/resetPassword',
            reserPasswordData
        );
    }

    getResetPasswordString(user, hotelCode): Observable<any> {
        return this.http.get<string>(
            `${hotelCode}/api/login/getResetPasswordString?employeeUsername=${user.username}`,
            {
                headers: {
                    Authorization: 'Bearer ' + user.uuid,
                    'Content-Type': 'text/html',
                    Accept: 'text/plain, */*',
                },
                responseType: 'text' as 'json',
            }
        );
    }

    /**
     * CanActivate logic of AuthLayoutsGuard
     * @returns Observable
     */
    checkAuthLayoutsGuard(): Observable<boolean> {
        return this.credentials.pipe(
            take(1),
            concatMap((credentials) => {
                // Check credentials to prevent load content for user with only HKmode access
                if (credentials && credentials.hkMode === false) {
                    return of(true);

                    // Check credentials in API to get access if autologin function not finished yet, but user should have access (hkMode = true is default)
                } else if (credentials && credentials.hkMode) {
                    return this.getUserInfo().pipe(
                        map((res) => {
                            if (res && res.hkMode === false) {
                                return true;
                            } else {
                                return false;
                            }
                        })
                    );
                }

                // Block access if there are not any credentials
                return of(false);
            })
        );
    }

    /**
     * Logout form app
     *
     * @returns void
     */
    logout(): void {
        // Logout from API
        if (this.user) {
            this.http.get<any>('api/login/logout').subscribe((res) => {
                this._snackBar.open(res.entity, '', { duration: 2000 });
            });
            // Clear user data
            this.user.next(null);
            this.credentials.next({ hkMode: true });
            localStorage.removeItem('userData');
            localStorage.removeItem('hotelCode');
        }
        if (this.mpUser.value) {
            this.mpUser.next(null);
            localStorage.removeItem('userData');
            localStorage.removeItem('hotelCode');
        }

        // Reset token timer
        if (this.tokenExpirationTimer) {
            clearTimeout(this.tokenExpirationTimer);
        }
        this.tokenExpirationTimer = null;

        // Redirect
        this.router.navigate(['/auth']);
    }

    /**
     * Logout when token expired
     *
     * @param  {number} expirationDuration
     * @returns void
     */
    autoLogout(expirationDuration: number): void {
        this.tokenExpirationTimer = setTimeout(() => {
            this.logout();
        }, expirationDuration);
    }

    /**
     * Reset counting of token timer
     *
     * @returns void
     */
    resetTokenTimer(): void {
        if (this.tokenExpirationTimer) {
            clearTimeout(this.tokenExpirationTimer);
            this.tokenExpirationTimer = setTimeout(() => {
                this.logout();
            }, this.tokenValidityTime * 1000);
        }
    }
    getRelatedHotels(hotelCode: string) {
        return HOTELS.filter((hotelObj) => {
            return (
                HotelsRelations[hotelCode]?.indexOf(hotelObj.hotelCode) != -1 ||
                hotelObj.hotelCode == hotelCode
            );
        });
    }
    getHotelInfo(hotelCode: string) {
        return HOTELS.filter((hotelObj) => {
            return hotelObj.hotelCode == hotelCode;
        });
    }

    getHotelCode(): string {
        return localStorage.getItem('hotelCode');
    }

    setActivatedHotelCode = (hotelCode: string): void => {
        localStorage.setItem('hotelCode', hotelCode);
    };
}
