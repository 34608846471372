import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { FormGroup } from "@angular/forms";
import * as moment from "moment";
import { NgbInputDatepicker, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ["../datepickers.scss", './datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {

  actualDate: NgbDate | null;
  setDisabled: boolean;

  @Input("title") title: string = "";
  @Input("type") type: string;
  @Input("date") date: string;
  @Input("valid") valid: boolean;
  @Input() parentForm: FormGroup;
  @Input("dateInputName") dateInputName: string;
  @Input("customInputClass") customInputClass: string;
  @ViewChild("datepicker") datepicker;
  constructor(
    public formatter: NgbDateParserFormatter) {

  }

  ngOnInit(): void {
    if (this.dateInputName != 'undefined') {
      if (this.parentForm.controls[this.dateInputName].value != null) {
        this.date = this.parentForm.controls[this.dateInputName].value;
        const fromDateParsed = this.formatter.parse(this.date);
        this.actualDate = NgbDate.from(fromDateParsed);
      }

    }
  }
  onChangeFun($event) {
    this.parentForm.controls[this.dateInputName].setValue($event.target.value);
    this.parentForm.controls[this.dateInputName].updateValueAndValidity();
  }
  onDateSelection($event) {
    let dataParsed = moment(
      $event.year + "-" + $event.month + "-" + $event.day
    ).format("YYYY-MM-DD");
    this.parentForm.controls[this.dateInputName].setValue(dataParsed);
    this.parentForm.controls[this.dateInputName].updateValueAndValidity();
  }
}
