<div class="wrapper">
  <div class="sidebar">
    <app-sidebar></app-sidebar>
  </div>
  <div class="main-panel">
    <app-navbar></app-navbar>
    <router-outlet></router-outlet>
    <!-- <app-footer></app-footer> -->
  </div>
</div>
<app-colors-panel></app-colors-panel>
