import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReservationsService } from '../../pages/reception/reservations/reservations.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { CheckinReservationComponent } from '../../pages/reception/shared/dialogs/checkin-reservation/checkin-reservation.component';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { ReceptionService } from '../../pages/reception/reception.service';
import { AcceptAdvancePaymentComponent } from '../../pages/reception/shared/dialogs/accept-advance-payment/accept-advance-payment.component';
import { CancelReservationComponent } from '../../pages/reception/shared/dialogs/cancel-reservation/cancel-reservation.component';
import { BasicConfirmationDialogComponent, ConfirmDialogModel } from '../basic-confirmation-dialog/basic-confirmation-dialog.component';
import { StayinsNewService } from '../../pages/reception/stayins/stayins-new/stayins-new.service';
import { OsobaService } from './osoba.service';

@Injectable({
	providedIn: 'root',
})
export class SchedulerService {
	public closeDialog = new BehaviorSubject(false);

	constructor(
		private http: HttpClient,
		private receptionService: ReceptionService,
		private reservationsService: ReservationsService,
		private snackBar: MatSnackBar,
		private dialog: MatDialog,
		private stayinsNewService: StayinsNewService,
		private personService: OsobaService
	) {}

	openCheckinDialog(data): void {
		console.log('dupa', data);
		const dialogRef = this.dialog.open(CheckinReservationComponent, {
			width: '100%',
			maxWidth: '60vw',
			minWidth: '250px',
			data,
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result === 'checkin') {
				const obj = {
					rezerwacjaId: data.rezerwacjaId,
				};
				this.reservationsService.checkinReservation(obj).subscribe((res) => {
					this.snackBar.open(res.entity, '', { duration: 2000 });
					if (res.status === 200) {
						this.closeDialog.next(true);
					}
				});
			}
		});
	}

	sendMail(scheduleObject): void {
		const preliminaryObject = {
			rezerwacjaId: scheduleObject.rezerwacjaId,
			mailRodzaj: 'REZERWACJA_WSTEPNA_INFO',
		};
		const confirmObject = {
			rezerwacjaId: scheduleObject.rezerwacjaId,
			mailRodzaj: 'REZERWACJA_LINK_DO_POTWIERDZENIA',
		};
		const send = forkJoin({
			Wstepna: this.receptionService.sendMailByStatus(preliminaryObject),
			Potwierdzenie: this.receptionService.sendMailByStatus(confirmObject),
		});
		send.subscribe(
			(res) => {
				this.snackBar.open(res.Wstepna.entity, '', { duration: 2000 });
				this.snackBar.open(res.Potwierdzenie.entity, '', {
					duration: 2000,
				});
			},
			(err) => {},
			() => {
				this.closeDialog.next(true);
			}
		);
	}

	addAdvancePayment(data): void {
		const dialogRef = this.dialog.open(AcceptAdvancePaymentComponent, {
			width: '100%',
			maxWidth: '60vw',
			minWidth: '250px',
			data,
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result === 'success') {
				this.snackBar.open('Zaliczka została dodana do rezerwacji', ':)', { duration: 2000 });
			}
		});
	}

	onCancelRes(item) {
		const data = {
			reason: '',
			item,
		};
		this.openCancelDialog(data, 'single');
	}

	openCancelDialog(data, type: string): void {
		const dialogRef = this.dialog.open(CancelReservationComponent, {
			width: '100%',
			maxWidth: '60vw',
			minWidth: '250px',
		});
		let obj = {};
		dialogRef.componentInstance.data = data;
		dialogRef.afterClosed().subscribe((result) => {
			if (result === 'confirm') {
				if (type === 'single') {
					obj = {
						rezerwacjaId: data.item.rezerwacjaId,
						powodAnulacji: data.reason,
					};
				} else {
					obj = {
						grupaId: data.item.grupaId,
						powodAnulacji: data.reason,
					};
				}
				this.reservationsService.reservationCancel(obj, type).subscribe((res) => {
					this.snackBar.open(res.entity, '', { duration: 2000 });
					if (res.status === 200) {
						this.closeDialog.next(true);
					}
				});
			}
		});
	}

	generateKeyRezerwacja(rezerwacja): void {
		const rezerwacjaId = rezerwacja.rezerwacjaId;
		const message = `Czy na pewno chcesz wygenerować nowy klucz dla rezerwacji ${rezerwacjaId}?`;
		const dialogData = new ConfirmDialogModel('Nowy klucz', message);
		const dialogRef = this.dialog.open(BasicConfirmationDialogComponent, {
			maxWidth: '400px',
			data: dialogData,
		});
		dialogRef.afterClosed().subscribe((dialogResult) => {
			if (dialogResult === true) {
				this.receptionService.generateKeyRezerwacja(rezerwacjaId).subscribe((res) => {
					if (res.status === 200) {
						this.snackBar.open(res.entity, '', {
							duration: 2000,
						});
						this.closeDialog.next(true);
					}
				});
			}
		});
	}

	cancelPasscode(rezerwacjaId, pokojNazwa): void {
		const resId = rezerwacjaId;
		const message = `Czy na pewno chcesz dezaktywować klucz dla pokoju` + ' ' + `${pokojNazwa}?`;
		const dialogData = new ConfirmDialogModel('Dezaktywuj klucz', message);
		const dialogRef = this.dialog.open(BasicConfirmationDialogComponent, {
			maxWidth: '400px',
			data: dialogData,
		});
		dialogRef.afterClosed().subscribe((dialogResult) => {
			if (dialogResult === true) {
				this.receptionService.cancelPassCodeRes(resId).subscribe((res) => {
					this.snackBar.open('Kod anulowano', '', {
						duration: 2000,
					});
					this.closeDialog.next(true);
				});
			}
		});
	}

	onSubmit(whatToSubmit: string, form: any) {
		this.confirmDialog('Czy jesteś pewny wprowadzonych zmian?', whatToSubmit, form);
	}

	confirmDialog(text, callTrigger, form: any): void {
		const dialogData = new ConfirmDialogModel('Potwierdź akcję', text);
		const dialogRef = this.dialog.open(BasicConfirmationDialogComponent, {
			maxWidth: '400px',
			data: dialogData,
		});

		dialogRef.afterClosed().subscribe((dialogResult) => {
			if (dialogResult === true && callTrigger === 'formSubmit') {
				console.log(form);
				this.stayinsNewService.editReservation({ rezerwacjaId: form.rezerwacjaId, rezerwacjaUwagimemo: form.rezerwacjaUwagimemo, rezerwacjaUwagiPortal: form.rezerwacjaUwagiPortal }).subscribe((res) => {
					this.snackBar.open(res.entity, '', { duration: 2000 });
					this.personService.editPerson({ osobaId: form.osobaId, osobaTelefon1: form.osobaTelefon, osobaEmail: form.osobaEmail }).subscribe((res2) => {
						if (res.status === 200 && res2.status === 200) {
							this.closeDialog.next(true);
						}
					});
				});
			} else if (dialogResult === true && callTrigger === 'formMelSubmit') {
				this.stayinsNewService.editStayin({ meldunekId: form.meldunekId, meldunekUwagimemo: form.meldunekUwagimemo, meldunekUwagiPortal: form.meldunekUwagiPortal }).subscribe((res) => {
					this.snackBar.open(res.entity, '', { duration: 2000 });

					this.personService.editPerson({ osobaId: form.osobaId, osobaTelefon1: form.osobaTelefon, osobaEmail: form.osobaEmail }).subscribe((res2) => {
						if (res.status === 200 && res2.status === 200) {
							this.closeDialog.next(true);
						}
					});
				});
			}
		});
	}
}
