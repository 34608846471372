<!--The content below is only a placeholder and can be replaced.-->

<div id="blank-page-loading" class="logo" style="display: flex;
width: 100%;
height: 100%;
justify-content: center;
align-items: center;
flex-direction: row;">
  <div style="display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row; gap: 16px;">
    <div class="logo-img" style="position: relative;top: 0;height: 40px; width: 40px; border-radius: 20px;">
      <img style="
      width: 40px;
      padding: 5px;
      height: 40px;" src="./assets/img/logo.png" />
    </div>
    <span class="simple-text logo-normal">
      Ekoncept PMS
    </span>
  </div>
</div>
<router-outlet></router-outlet>
