<form data-component='datepicker' class="{{ customInputClass }}">
  <label *ngIf="title != ''" translate>{{ title }}</label>
  <div class="form-group" *ngIf="setDisabled != true ">
    <div class="input-group">
      <input class="form-control" ngbDatepicker #datepicker="ngbDatepicker" (dateSelect)="onDateSelection($event)"
        [startDate]="actualDate" [autoClose]="'outside'" placeholder="yyyy-mm-dd" value="{{ date }}"
        [class.ng-invalid]="valid == 'false'" (change)="onChangeFun($event)">
      <div class="input-group-append">
        <button class="input-group-button" (click)="datepicker.toggle()" type="button">
          <span class="material-icons">
            date_range
          </span>
        </button>
      </div>
    </div>
  </div>
  <div class="form-group" *ngIf="setDisabled == true ">
    <div class="input-group">
      <input class="form-control" [formControlName]="date" [startDate]="actualDate" disabled>
    </div>
  </div>
</form>
