import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ParamService {

  constructor(private http: HttpClient) { }
  getDomyslnaGodzinaPrzyjazdu(): Observable<any> {
    return this.http.get<any>('api/param/getDomyslnaGodzinaPrzyjazdu');
  }
  getDomyslnaGodzinaWyjazdu(): Observable<any> {
    return this.http.get<any>('api/param/getDomyslnaGodzinaWyjazdu');
  }
  getMaksymalnaIloscDzieci(): Observable<any> {
    return this.http.get<any>('api/param/portal/getMaksymalnaIloscDzieci');
  }
}
