import { Component, OnInit, Input } from "@angular/core";
import { NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import { FormGroup } from "@angular/forms";
import * as moment from "moment";

@Component({
  selector: "app-datetimepicker",
  templateUrl: "./datetimepicker.component.html",
  styleUrls: ["./datetimepicker.component.scss"],
})
export class DatetimepickerComponent implements OnInit {
  model: NgbDateStruct;
  hours: Array<string>;
  minutes: Array<string>;
  pickedHour: string = "00";
  pickedMinutes: string = "00";
  time: string;
  @Input("type") type: string;
  @Input() parentForm: FormGroup;
  @Input("timeInputName") timeInputName: string;
  @Input("dateInputName") dateInputName: string;
  @Input("setDateValue") setDateValue: string;
  @Input("setTimeValue") setTimeValue: string;
  @Input("setDisabled") setDisabled: string;
  @Input("offsetD") offsetD: number;

  timeH: string = moment().format("HH");
  today: string = moment().format("YYYY-MM-DD");

  constructor() {
    this.hours = [
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
    ];
    this.minutes = ["00", "15", "30", "45"];
  }
  ngOnInit(): void {
    this.today =
      this.offsetD != 0
        ? moment().add(this.offsetD, "days").format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
    this.setDateValue
      ? (this.today = moment(this.setDateValue, "YYYY-MM-DD").format(
          "YYYY-MM-DD"
        ))
      : "";
    this.setTimeValue
      ? (this.pickedHour = moment(this.setTimeValue, "HH:mm:ss").format("HH"))
      : "";
    this.setTimeValue
      ? (this.pickedMinutes = moment(this.pickedMinutes, "HH:mm:ss").format(
          "mm"
        ))
      : "";
    this.parseTime();
  }
  onDateSelection($event) {
    let dataParsed = moment(
      $event.year + "-" + $event.month + "-" + $event.day
    ).format("YYYY-MM-DD");
    this.parentForm.controls[this.dateInputName].setValue(dataParsed);
  }
  onHourSelection($event) {
    let hour = $event.target.value;
    this.pickedHour = hour;
    this.parseTime();
  }
  onMinutesSelection($event) {
    let minutes = $event.target.value;
    this.pickedMinutes = minutes;
    this.parseTime();
  }
  parseTime() {
    let timeParsed = (
      this.pickedHour +
      ":" +
      this.pickedMinutes +
      ":00"
    ).trim();
    this.time = timeParsed;
    this.parentForm.controls[this.timeInputName].setValue(timeParsed);
  }
}
