declare interface HotelInfo {
	hotelName: string;
	hotelCode: string;
}
export const HOTELS: HotelInfo[] = [
	{
		hotelName: 'Ekoncept Klient',
		hotelCode: '9999',
	},
	{
		hotelName: 'Ekoncept PreProd',
		hotelCode: '9998',
	},
	{
		hotelName: 'Ekoncept Demo',
		hotelCode: '9997',
	},
	{
		hotelName: 'Ekoncept Test',
		hotelCode: '9996',
	},
	{
		hotelName: 'Almond',
		hotelCode: '0011',
	},
	{
		hotelName: 'Belmare',
		hotelCode: '0021',
	},
	{
		hotelName: 'Balt-tur',
		hotelCode: '0031',
	},
	{
		hotelName: 'Grano',
		hotelCode: '0041',
	},
	{
		hotelName: 'NowaMotlawa',
		hotelCode: '0051',
	},
	{
		hotelName: 'Zatoka',
		hotelCode: '0061',
	},
	{
		hotelName: 'FlatsForRent',
		hotelCode: '0071',
	},
	{
		hotelName: 'NumberOne',
		hotelCode: '0081',
	},
	{
		hotelName: 'GranoApartments',
		hotelCode: '0091',
	},
	{
		hotelName: 'Arche',
		hotelCode: '0101',
	},
	{
		hotelName: 'HBH',
		hotelCode: '0111',
	},
	{
		hotelName: 'Zamek Łeba',
		hotelCode: '0121',
	},
	{
		hotelName: 'Osrodek Wiatraki',
		hotelCode: '0131',
	},
	{
		hotelName: 'Morze Ustka',
		hotelCode: '0141',
	},
	{
		hotelName: 'Hotel Artus',
		hotelCode: '0151',
	},
	{
		hotelName: 'Palazzo Rosso',
		hotelCode: '0161',
	},
	{
		hotelName: 'Hotel Hel',
		hotelCode: '0171',
	},
	{
		hotelName: 'Willa Tatrzańska',
		hotelCode: '0181',
	},
	{
		hotelName: 'Panoramic Oscar',
		hotelCode: '0191',
	},
	{
		hotelName: 'Hotel Francuski',
		hotelCode: '0201',
	},
	{
		hotelName: 'SolMarina',
		hotelCode: '0211',
	},
	{
		hotelName: 'SolMarinaApartamenty',
		hotelCode: '0221',
	},
	{
		hotelName: 'Altus Palace',
		hotelCode: '0231',
	},
	{
		hotelName: 'Montownia',
		hotelCode: '0241',
	},
	{
		hotelName: 'Ren',
		hotelCode: '0251',
	},
	{
		hotelName: 'Blick',
		hotelCode: '0261',
	},
	{
		hotelName: 'N.33 PREMIUM APARTMENTS',
		hotelCode: '0271',
	},
	{
		hotelName: 'Dream Aparts',
		hotelCode: '0281',
	},
	{
		hotelName: 'Adela',
		hotelCode: '0291',
	},
	{
		hotelName: 'Apartamenty Wydma&Las',
		hotelCode: '0301',
	},
];

export const HotelModules = {
	'9999': ['all'],
	'9998': ['all'],
	'9996': ['all'],
	'9997': ['all'],
	'0011': ['dashboard', 'reports', 'housekeeping', 'reception'],
	'0021': ['dashboard', 'reception', 'housekeeping', 'invoices', 'marketing'],
	'0031': ['dashboard', 'housekeeping'],
	'0041': ['all'],
	'0051': ['dashboard', 'reports', 'housekeeping', 'reception'],
	'0061': ['all'],
	'0071': ['dashboard', 'housekeeping', 'reception'],
	'0081': ['dashboard', 'reports', 'housekeeping', 'reception'],
	'0091': ['dashboard', 'housekeeping', 'reception'],
	'0101': ['dashboard', 'reception', 'housekeeping', 'invoices', 'marketing'],
	'0111': ['dashboard', 'reception'],
	'0121': ['dashboard', 'reception'],
	'0131': ['dashboard', 'reception', 'housekeeping'],
	'0141': ['dashboard', 'reception'],
	'0151': ['dashboard', 'housekeeping', 'reports'],
	'0161': ['dashboard', 'housekeeping', 'reception'],
	'0171': ['dashboard', 'housekeeping', 'reception'],
	'0181': ['dashboard', 'housekeeping', 'reception'],
	'0191': ['dashboard', 'housekeeping', 'reception'],
	'0201': ['all'],
	'0211': ['dashboard', 'reception', 'housekeeping', 'reports'],
	'0221': ['dashboard', 'reception', 'housekeeping', 'reports'],
	'0231': ['all'],
	'0241': ['all'],
	'0251': ['all'],
	'0261': ['all'],
	'0271': ['all'],
	'0281': ['all'],
	'0291': ['all'],
	'0301': ['all'],
};

export const HotelsRelations = {
	'9999': ['9999'],
	'9998': ['9998'],
	'9997': ['9997'],
	'9996': ['9996'],
	'0011': ['0011'],
	'0021': ['0021'],
	'0031': ['0031'],
	'0041': ['0041', '0091'],
	'0051': ['0051', '0081'],
	'0061': ['0061'],
	'0071': ['0071'],
	'0081': ['0081', '0051'],
	'0091': ['0091', '0041'],
	'0101': ['0101'],
	'0111': ['0111'],
	'0121': ['0121'],
	'0131': ['0131'],
	'0141': ['0141'],
	'0151': ['0151'],
	'0161': ['0161'],
	'0171': ['0171'],
	'0181': ['0181'],
	'0191': ['0191'],
	'0201': ['0201', '0231', '0271'],
	'0211': ['0211', '0221'],
	'0221': ['0221', '0211'],
	'0231': ['0231', '0201', '0271'],
	'0241': ['0241', '0301'],
	'0251': ['0251'],
	'0261': ['0261'],
	'0271': ['0271', '0201', '0231'],
	'0281': ['0281'],
	'0291': ['0291'],
	'0301': ['0301', '0241'],
};
