import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StaleService } from '../../../../../shared/services/stale.service';
import { InvoicesService } from '../../../../invoices/invoices.service';

@Component({
	selector: 'app-accept-advance-payment',
	templateUrl: './accept-advance-payment.component.html',
	styleUrls: ['./accept-advance-payment.component.scss'],
})
export class AcceptAdvancePaymentComponent implements OnInit {
	@ViewChild('reason', { static: false }) reason;

	public addAdvanceForm: FormGroup = this.formBuilder.group({
		rezerwacjaId: [{ value: null, disabled: true }, [Validators.required]],
		formaPlatnosci: [null, [Validators.required]],
	});

	public formyPlatnosci: any;
	public isLoading = true;

	constructor(private staleService: StaleService, private invoicesService: InvoicesService, private formBuilder: FormBuilder, public dialogRef: MatDialogRef<AcceptAdvancePaymentComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

	ngOnInit(): void {
		this.staleService.getFormyPlatnosci().subscribe((res) => {
			this.formyPlatnosci = res;
			this.addAdvanceForm.patchValue({ rezerwacjaId: this.data.rezerwacjaId });
			this.isLoading = false;
		});
	}

	sendRes(res): void {
		if (res === 'confirm') {
			if (this.addAdvanceForm.invalid) {
				return;
			}
			this.invoicesService.przyjmijZaliczkeDoRezerwacji(this.addAdvanceForm.getRawValue()).subscribe(
				(zaliczka) => {
					console.log(zaliczka);
					this.dialogRef.close('success');
				},
				(err) => this.dialogRef.close('error')
			);
		} else {
			this.dialogRef.close(res);
		}
	}
}
