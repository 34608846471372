import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { NgbCarouselConfig } from "@ng-bootstrap/ng-bootstrap";
import { NgbModalConfig, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgForOfContext } from "@angular/common";
import { ImageModalComponent } from "src/app/shared/image-modal/image-modal.component";

@Component({
  selector: "app-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.scss"],
})
export class CarouselComponent implements OnInit {
  @Input() images;
  @Input() removeArray;
  @Input() type;
  @ViewChild("#fullscreen") fullScreenModal;
  constructor(config: NgbCarouselConfig, private modalService: NgbModal) {
    config.interval = 10000;
    config.keyboard = false;
    config.pauseOnHover = false;
  }

  ngOnInit(): void {}

  onClickDeleteImage(index) {
    this.removeArray.forEach((imagesList) => {
      imagesList.splice(index, 1);
    });
  }

  openImageFullscreen(content) {
    let modalFullScreen = this.modalService.open(ImageModalComponent, {
      windowClass: "modal-dialog-image",
      backdrop: false,
    });
    modalFullScreen.componentInstance.image = content;
  }
}
