<div class="wrapper">

  <div class="main-panel">
    <div class=" content-no-sidebar">
      <div class="card login-form">
        <div class="card-header text-center d-flex justify-content-center">
          <div class="logo-img">
            <img src="./assets/img/logo.png">
          </div>
          <h5 class="title m-0">EKONCEPT PMS</h5>
        </div>
        <div class="card-body pb-0">
          <p style="text-align: center;">Prosimy o zmianę hasła</p>
          <form [formGroup]="setNewPasswordForm">
            <div class="row  justify-content-center">
              <div class=" col-md-8">
                <div class=" form-group">
                  <label>Login </label>
                  <input class=" form-control " disabled required type="text" placeholder=""
                    formControlName="employeeUsername" />
                </div>
              </div>
            </div>
            <div class="row  justify-content-center">
              <div class=" col-md-8">
                <div class=" form-group">
                  <label>Kod hotelu </label>
                  <input class=" form-control " disabled required type="text" placeholder="" formControlName="hotelCode"
                    pattern="^[0-9]*$" />

                </div>
              </div>
            </div>
            <div class="row  justify-content-center">
              <div class=" col-md-8">
                <div class=" form-group">
                  <label> Hasło </label>
                  <input class=" form-control " required type="password" placeholder="" formControlName="password"
                    (keydown.enter)="onSubmitChangePassword()" />
                </div>
              </div>
            </div>
            <div class="row  justify-content-center">
              <div class=" col-md-8">
                <div class=" form-group">
                  <label>Powtórz hasło </label>
                  <input class=" form-control " required type="password" placeholder=""
                    formControlName="repeatePassword" (keydown.enter)="onSubmitChangePassword()" />
                  <mat-error *ngIf="setNewPasswordForm.controls.repeatePassword.hasError('diffrent')"
                    style="font-size: 10px; ">
                    {{setNewPasswordForm.controls.repeatePassword.errors.diffrent}}</mat-error>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class=" card-footer text-center">
          <button class=" btn btn-info btn-info-ek" (click)="onSubmitChangePassword()">Zmień hasło</button>
          <a href="/login">Powrót do logowania</a>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</div>
<app-colors-panel></app-colors-panel>
