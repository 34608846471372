import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { forkJoin, of, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ResetPasswordData } from 'src/app/auth/models/auth-response-data.model';
import { HotelsRelations } from 'src/app/auth/models/hotels-list.model';
import { AuthService } from '../../auth/auth.service';
import { MpLoginService } from '../multiproperty/mp-login/mp-login.service';
import { SetNewPasswordService } from './set-new-password/set-new-password.service';

@Component({
	selector: 'app-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
	public user$ = new Subscription();
	public ifOnboarding: boolean = true;
	public resetpassword: boolean = false;
	public resetPasswordData: ResetPasswordData | null = null;
	public loggingIn = false;

	constructor(private authService: AuthService, private router: Router, public dialog: MatDialog, private mpLoginService: MpLoginService, private _snackBar: MatSnackBar, private setNewPasswordService: SetNewPasswordService) {
		document.getElementById('blank-page-loading') ? (document.getElementById('blank-page-loading').style.display = 'none') : null;
		this.user$ = this.authService.user.subscribe((user) => {
			this.router.navigate(['/dashboard']);
		});
	}

	ngOnInit(): void {
		if (this.authService.user.value) {
			this.router.navigate(['/dashboard']);
		}
		const html = document.getElementsByTagName('html')[0];
		if (html) {
			html.classList.remove('nav-open');
		}
	}

	/**
	 * Handle bad login requests to all hotels then return true if loginErrors.count == hotelstologin.length
	 * @param loginResponses
	 * @returns
	 */
	handleLoginFailedException(loginResponses: any[], hotelsToLogin: any[]): boolean {
		let exceptionText = 'ekoncept.exception.LoginFailedException';
		let loginFailedExceptionCount = loginResponses.filter((el) => {
			return el.error?.exception === exceptionText;
		});
		if (loginFailedExceptionCount.length == hotelsToLogin.length) {
			// bad login to all hotels, just prompt info wrong password
			this._snackBar.open(loginResponses[0].error.message, 'OK', {
				duration: 5000,
			});
			return true;
		}
	}

	/**
	 * If all users are user not found jus reset form and throw wrong password, if one of logins login ok, then filter hotels and login one
	 * @param loginResponses
	 * @param hotelsToLogin
	 * @returns
	 */
	handleLoginPmsActiveUserNotFoundException(loginResponses: any[], hotelsToLogin: any[]): boolean | string {
		let exceptionText = 'ekoncept.exception.LoginPmsActiveUserNotFoundException';
		let LoginPmsActiveUserNotFoundExceptionCount = loginResponses.filter((el) => {
			return el.error?.exception == exceptionText;
		});

		if (LoginPmsActiveUserNotFoundExceptionCount.length == hotelsToLogin.length) {
			// User not exists in all hotels
			this._snackBar.open('Błędny login lub hasło.', 'OK', {
				duration: 5000,
			});
			return true;
		} else {
			// one of user are not from this hotel, just try login only to one
			let loggedHotelCodeList = [];
			let allLoggedSessions = loginResponses.filter((el, index) => {
				if (el.uuid !== undefined && el.uuid !== null) {
					let loggedHotelCode = hotelsToLogin[index];
					loggedHotelCodeList.push(loggedHotelCode);
					this.authService.loggedUserInHotels[loggedHotelCode] = el;
					return el;
				}
			});
			//number of user not active and logged session
			let numberOfRequests = LoginPmsActiveUserNotFoundExceptionCount.length + allLoggedSessions.length;
			// should be number of all requests
			if (allLoggedSessions.length > 0 && numberOfRequests == hotelsToLogin.length) {
				// atleast one user logged in just login
				this.authService.loggedUserResponseList.next(this.authService.loggedUserInHotels);
				this.authService.hotelList.next(
					loggedHotelCodeList.map((hotelCode) => {
						return this.authService.getHotelInfo(hotelCode);
					})
				);

				if (loggedHotelCodeList[0]) {
					this.authService.setActivatedHotelCode(loggedHotelCodeList[0]);
					this.authService.createUserAuthObject(loggedHotelCodeList[0], this.authService.loggedUserResponseList.value[loggedHotelCodeList[0]]);
				}
				return 'SOME_LOGGED_ID';
			}
		}
	}
	/**
	 *
	 * @param loginResponses
	 * @param hotelsToLogin
	 */
	async handleLoginPasswordChangeRequiredException(loginResponses: any[], hotelsToLogin: any[], employeeUsername: string) {
		let exceptionText = 'ekoncept.exception.LoginPasswordChangeRequiredException';
		let LoginPasswordChangeRequiredExceptionCount = loginResponses.filter((el) => {
			return el.error?.exception == exceptionText;
		});
		if (LoginPasswordChangeRequiredExceptionCount.length == hotelsToLogin.length) {
			// change password is required in all ahotels, just redirect and save responsedata to service
			let parsedResponseToNewPasswordArray = loginResponses.map((res, index) => {
				return {
					employeeUsername: employeeUsername,
					secret: res.error.description,
					hotelCode: hotelsToLogin[index],
				};
			});

			this.setNewPasswordService.newPasswordDatas.next(parsedResponseToNewPasswordArray);
			return true;
		} else if (LoginPasswordChangeRequiredExceptionCount.length !== hotelsToLogin.length) {
			//if one need be changed then change all, but get resetpassword secret first
			//find all good request and get reset string, create objects and redirect to newpassword

			let getResetPasswordStringRequests = [];
			loginResponses.forEach((loggedUser, index) => {
				if (loggedUser.uuid !== undefined && loggedUser.uuid !== null) {
					getResetPasswordStringRequests.push(this.authService.getResetPasswordString(loggedUser, hotelsToLogin[index]).pipe(catchError((error) => of(error))));
				} else {
					getResetPasswordStringRequests.push(of(null).pipe(catchError((error) => of(error))));
				}
			});
			let getResetPasswordStringStatus = await new Promise((resolve) => {
				forkJoin(getResetPasswordStringRequests).subscribe((resetPasswordStringResponse) => {
					let allNewResetPasswordStrings: any = resetPasswordStringResponse.map((getResetPasswordStringResponse, getResetPasswordStringResponseIndex) => {
						if (getResetPasswordStringResponse == null) {
							let errorWithDesc = loginResponses[getResetPasswordStringResponseIndex];
							return {
								employeeUsername: employeeUsername,
								secret: errorWithDesc.error.description,
								hotelCode: hotelsToLogin[getResetPasswordStringResponseIndex],
							};
						} else {
							return {
								employeeUsername: employeeUsername,
								secret: getResetPasswordStringResponse,
								hotelCode: hotelsToLogin[getResetPasswordStringResponseIndex],
							};
						}
					});

					this.setNewPasswordService.newPasswordDatas.next(allNewResetPasswordStrings);
					resolve(true);
				});
			});

			return getResetPasswordStringStatus;
		}
	}
	/**
	 * Submit form to login
	 *
	 * @param  {NgForm} form
	 */
	onSubmit(form: NgForm) {
		if (!form.valid) {
			return;
		}
		if (this.authService.user.value) {
			this.router.navigate(['/dashboard']);
		}
		this.loggingIn = true;
		const username = form.value.user;
		const password = form.value.password;
		const hotelCode = form.value.hotelCode;

		// MULTIPROPERTY LOGIN
		if (hotelCode >= 10000) {
			this.authService.loginToMultiproperty(username, password, hotelCode).subscribe(() => {
				if (!this.ifOnboarding) this.router.navigate(['/multiproperty/settings']);
				else if (this.ifOnboarding) this.router.navigate(['/multiproperty/onboarding']);
			});
			form.reset();
			return;
		}
		// LOGIN TO MULTIPLE HOTELS
		let relatedHotels = HotelsRelations[hotelCode];
		let hotelsToLogin = [...[hotelCode]];
		if (relatedHotels?.length >= 1) {
			hotelsToLogin = relatedHotels;
		}

		if (hotelsToLogin?.length >= 1) {
			this.authService.loginToMultiple(username, password, hotelsToLogin).subscribe(async (loginResponses) => {
				let responseErrorsList = loginResponses.filter((el) => {
					this.loggingIn = false;
					return el.status == 400 || el.status == 403;
				});
				if (responseErrorsList.length > 0) {
					if (this.handleLoginFailedException(loginResponses, hotelsToLogin)) {
						form.reset();
						this.loggingIn = false;
						return;
					}
					let handleLoginPmsActiveUserNotFoundException = this.handleLoginPmsActiveUserNotFoundException(loginResponses, hotelsToLogin);
					if (handleLoginPmsActiveUserNotFoundException === true) {
						form.reset();
						this.loggingIn = false;
						return;
					} else if (handleLoginPmsActiveUserNotFoundException === 'SOME_LOGGED_ID') {
						this.router.navigate(['/dashbaord']);
						this.loggingIn = false;
						return;
					}

					if (await this.handleLoginPasswordChangeRequiredException(loginResponses, hotelsToLogin, username)) {
						this.router.navigate(['/new-password']);
						this.loggingIn = false;
						return;
					}
					this.loggingIn = false;
					return;
				} else {
					//at least one is success , some could be "ekoncept.exception.LoginPmsActiveUserNotFoundException"
					this.authService.handleLoginToMultipleHotels(loginResponses, hotelsToLogin);
				}
			});
		}
	}
	onDestroy(): void {
		this.user$ ? this.user$.unsubscribe() : null;
	}
}
