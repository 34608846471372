import { Component, OnInit, Input, ViewChild } from "@angular/core";
import {
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter,
  NgbDatepicker,
  NgbInputDatepicker,
} from "@ng-bootstrap/ng-bootstrap";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "app-datepicker-range",
  templateUrl: "./datepicker-range.component.html",
  styleUrls: ["./datepicker-range.component.scss"],
})
export class DatepickerRangeComponent implements OnInit {
  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  @Input() parentForm: FormGroup;
  @Input("customInputClass") customInputClass: string;
  @Input("dateInputToName") dateInputToName: string;
  @Input("dateInputFromName") dateInputFromName: string;

  @ViewChild("datepicker", { static: false }) datepicker: NgbInputDatepicker;
  public numberOfMonths = 2;

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) {}

  ngOnInit(): void {
    // Set data values from parent component, to manage calendar inputs.
    if (this.parentForm.value.fromDate) {
      const fromDateParsed = this.formatter.parse(
        this.parentForm.value.fromDate
      );
      this.fromDate = NgbDate.from(fromDateParsed);
    }
    if (this.parentForm.value.toDate) {
      const toDateParsed = this.formatter.parse(this.parentForm.value.toDate);
      this.toDate = NgbDate.from(toDateParsed);
    }
  }

  /**
   * Set data in form on select dates
   * @param  {NgbDate} date
   * @returns void
   */
  onDateSelection(date: NgbDate): void {
    const year = date.year;
    const month = date.month < 10 ? `0${date.month}` : date.month;
    const day = date.day < 10 ? `0${date.day}` : date.day;

    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      this.parentForm.controls.fromDate.setValue(`${year}-${month}-${day}`);
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
      this.parentForm.controls.toDate.setValue(`${year}-${month}-${day}`);
    } else {
      this.toDate = null;
      this.parentForm.value.toDate = null;
      this.fromDate = date;
      this.parentForm.controls.fromDate.setValue(`${year}-${month}-${day}`);
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, dp: any): NgbDate | null {
    const parsed = this.formatter.parse(dp.value);
    if (parsed && this.calendar.isValid(NgbDate.from(parsed))) {
      if (dp.name === "dpFromDate") {
        this.parentForm.controls.fromDate.setValue(dp.value);
      } else if (dp.name === "dpToDate") {
        this.parentForm.controls.toDate.setValue(dp.value);
      }
      return NgbDate.from(parsed);
    } else {
      return currentValue;
    }
  }

  reset() {
    this.fromDate = null;
    this.toDate = null;
    this.parentForm.controls.fromDate.setValue(null);
    this.parentForm.controls.toDate.setValue(null);
  }

  datepickerToggle() {
    window.innerWidth < 576
      ? (this.numberOfMonths = 1)
      : (this.numberOfMonths = 2);
    setTimeout(() => {
      this.datepicker.toggle();
    });
  }
}
