<div class="image-row image-row--list">
  <div class="image-column" *ngFor="let image of images; let i = index">
    <img src="{{image.src}}" (click)="openModal();currentSlide(i+1)" class="hover-shadow cursor">
  </div>
</div>
<div id="imgModal" class="slide-modal">
  <span class="close cursor" (click)="closeModal()">&times;</span>
  <div class="slide-modal-content">
    <div class="img-slides" *ngFor="let image of images; let i = index">
      <div class="image-slides-container">
        <img src="{{image.src}}" />

        <div class="img-slides-actions">
          <button mat-icon-button class="btn-link btn-danger-ek" (click)="onClickDeleteImage(i, image.imageId)">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <a class="prev" (click)="plusSlides(-1)">&#10094;</a>
    <a class="next" (click)="plusSlides(1)">&#10095;</a>
    <div class="image-row ">
      <div class="image-column" *ngFor="let image of images; let i = index">
        <div class="images" style="background-image: url({{image.src}});" (click)="currentSlide(i)">
        </div>
      </div>
    </div>
  </div>
</div>
