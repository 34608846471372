import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-cancel-reservation",
  templateUrl: "./cancel-reservation.component.html",
  styleUrls: ["./cancel-reservation.component.scss"],
})
export class CancelReservationComponent implements OnInit {
  @ViewChild("reason", { static: false }) reason;
  constructor(
    public dialogRef: MatDialogRef<CancelReservationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  sendRes(res): void {
    this.data.reason = this.reason.nativeElement.value;
    this.dialogRef.close(res);
  }
}
