import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
    Osoba,
    OsobaDetails,
} from 'src/app/pages/reception/reception.interface';
import { HttpClient } from '@angular/common/http';
import { Response } from 'src/app/shared/interfaces/response';
import { tap } from 'rxjs/operators';
@Injectable({
    providedIn: 'root',
})
export class OsobaService {
    constructor(private http: HttpClient) {}

    getOsobaList(): Observable<any> {
        return this.http.get<Osoba[]>('api/osoba/getOsobaList');
    }

    getPersonDetails(osobaId): Observable<OsobaDetails> {
        return this.http.get<OsobaDetails>(
            'api/osoba/getOsobaInfo?osobaId=' + osobaId
        );
    }

    editPerson(personData: Osoba): Observable<Response> {
        return this.http.put<Response>('api/osoba/edytujOsobe', personData);
    }

    searchPerson(searchString): Observable<any> {
        return this.http.get<Osoba[]>(
            'api/osoba/getOsobaList?search=' + searchString
        );
    }

    addPerson(personData): Observable<any> {
        return this.http.post<Osoba>('api/osoba/dodajOsobe', personData);
    }

    getOsobaShortList(search: string = ''): Observable<any> {
        let opts = [];
        return opts.length
            ? of(opts)
            : this.http
                  .get<any>(`api/osoba/getOsobaShortList?search=${search}`)
                  .pipe(tap((data) => (opts = data)));
    }

    getFirmaShortList(search: string = ''): Observable<any> {
        let opts = [];
        return opts.length
            ? of(opts)
            : this.http
                  .get<any>(`api/firma/getFirmaShortList?search=${search}`)
                  .pipe(tap((data) => (opts = data)));
    }

    searchCity(search: string = ''): Observable<any> {
        let opts = [];
        return opts.length
            ? of(opts)
            : this.http
                  .get<any>(`api/miasto/getMiastoShortList?search=${search}`)
                  .pipe(tap((data) => (opts = data)));
    }

    getCitiesShortList(): Observable<any> {
        return this.http.get<any>(`api/miasto/getMiastoShortList`);
    }

    getCountryShortList(): Observable<any> {
        let opts = [];
        return opts.length
            ? of(opts)
            : this.http
                  .get<any>('api/panstwo/getPanstwoShortList')
                  .pipe(tap((data) => (opts = data)));
    }
    searchCountry(search: string = ''): Observable<any> {
        let opts = [];
        return opts.length
            ? of(opts)
            : this.http
                  .get<any>(`api/panstwo/getPanstwoShortList?search=${search}`)
                  .pipe(tap((data) => (opts = data)));
    }
}
