import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReceptionService } from '../../pages/reception/reception.service';
import { SchedulerService } from '../services/scheduler.service';

@Component({
	selector: 'app-booking-scheduler-mobile-dialog',
	templateUrl: 'booking-scheduler-mobile-dialog.component.html',
	styleUrls: ['./booking-scheduler-mobile-dialog.component.scss'],
})
export class BookingSchedulerMobileDialogComponent implements OnInit {
	public bookingResForm = new FormGroup({
		rezerwacjaDataOd: new FormControl(),
		rezerwacjaDataDo: new FormControl(),
		osobaTelefon: new FormControl(),
		osobaEmail: new FormControl(),
		osobaAdres: new FormControl(),
		polozenieNazwa: new FormControl(),
		pokojNazwa: new FormControl(),
		rezerwacjaIleDoroslych: new FormControl(),
		rezerwacjaIleDzieci: new FormControl(),
		rezerwacjaCena: new FormControl(),
		passCode: new FormControl(),
		rezerwacjaUwagimemo: new FormControl(),
		rezerwacjaUwagiPortal: new FormControl(),
		zrodloRezerwacji: new FormControl(),
		rezerwacjaDataZglosz: new FormControl(),
		razemBrutto: new FormControl(),
	});
	public bookingMelForm = new FormGroup({
		meldunekDataOd: new FormControl(),
		meldunekDataDo: new FormControl(),
		osobaTelefon: new FormControl(),
		osobaEmail: new FormControl(),
		osobaAdres: new FormControl(),
		polozenieNazwa: new FormControl(),
		pokojNazwa: new FormControl(),
		meldunekIleDoroslych: new FormControl(),
		meldunekIleDzieci: new FormControl(),
		pobytBrutto: new FormControl(),
		razemBrutto: new FormControl(),
		passCode: new FormControl(),
		meldunekUwagimemo: new FormControl(),
		meldunekUwagiPortal: new FormControl(),
		zrodloRezerwacji: new FormControl(),
		rezerwacjaDataZglosz: new FormControl(),
	});

	public editForm = false;
	public isTTLockAvailable = false;

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog, private formBuilder: FormBuilder, private receptionService: ReceptionService, private schedulerService: SchedulerService) {
		console.log(data);
	}

	public ngOnInit(): void {
		this.schedulerService.closeDialog.next(false);
		this.checkIsTTLockAvailable();
		this.bookingResForm = this.formBuilder.group({
			rezerwacjaDataOd: [{ value: this.data.rezerwacjaDataOd, disabled: true }, Validators.required],
			rezerwacjaDataDo: [{ value: this.data.rezerwacjaDataDo, disabled: true }, Validators.required],
			osobaTelefon: [{ value: this.data.osobaTelefon, disabled: !this.editForm }, Validators.required],
			osobaEmail: [{ value: this.data.osobaEmail, disabled: !this.editForm }, Validators.required],
			osobaAdres: [{ value: '', disabled: true }],
			polozenieNazwa: [{ value: this.data.polozenieNazwa, disabled: true }, Validators.required],
			pokojNazwa: [{ value: this.data.pokojNazwa, disabled: true }, Validators.required],
			rezerwacjaIleDoroslych: [{ value: this.data.rezerwacjaIleDoroslych, disabled: true }, Validators.required],
			rezerwacjaIleDzieci: [{ value: this.data.rezerwacjaIleDzieci, disabled: true }, Validators.required],
			pobytBrutto: [{ value: parseFloat(this.data.pobytBrutto).toFixed(2), disabled: true }, Validators.required],
			razemBrutto: [{ value: parseFloat(this.data.razemBrutto).toFixed(2), disabled: true }, Validators.required],
			passCode: [{ value: this.data.passCode ? this.data.passCode : null, disabled: true }],
			rezerwacjaUwagimemo: [{ value: this.data.rezerwacjaUwagimemo, disabled: !this.editForm }],
			rezerwacjaUwagiPortal: [{ value: this.data.rezerwacjaUwagiPortal, disabled: !this.editForm }],
			zrodloRezerwacji: [{ value: this.data.zrodloRezerwacji, disabled: true }, Validators.required],
			rezerwacjaDataZglosz: [{ value: this.data.rezerwacjaDataZglosz, disabled: true }],
		});
		this.bookingMelForm = this.formBuilder.group({
			meldunekDataOd: [{ value: this.data.meldunekDataOd, disabled: !this.editForm }, Validators.required],
			meldunekDataDo: [{ value: this.data.meldunekDataDo, disabled: !this.editForm }, Validators.required],
			osobaTelefon: [{ value: this.data.osobaTelefon, disabled: !this.editForm }],
			osobaEmail: [{ value: this.data.osobaEmail, disabled: !this.editForm }, Validators.required],
			osobaAdres: [{ value: '', disabled: !this.editForm }],
			polozenieNazwa: [{ value: this.data.polozenieNazwa, disabled: !this.editForm }, Validators.required],
			pokojNazwa: [{ value: this.data.pokojNazwa, disabled: !this.editForm }, Validators.required],
			meldunekIleDoroslych: [{ value: this.data.meldunekIleDoroslych, disabled: !this.editForm }, Validators.required],
			meldunekIleDzieci: [{ value: this.data.meldunekIleDzieci, disabled: !this.editForm }, Validators.required],
			pobytBrutto: [{ value: parseFloat(this.data.pobytBrutto).toFixed(2), disabled: !this.editForm }, Validators.required],
			passCode: [{ value: this.data.passCode ? this.data.passCode : null, disabled: !this.editForm }],
			meldunekUwagimemo: [{ value: this.data.meldunekUwagimemo, disabled: !this.editForm }],
			meldunekUwagiPortal: [{ value: this.data.meldunekUwagiPortal, disabled: !this.editForm }],
			zrodloRezerwacji: [{ value: this.data.zrodloRezerwacji, disabled: true }, Validators.required],
			rezerwacjaDataZglosz: [{ value: this.data.rezerwacjaDataZglosz, disabled: true }],
		});
		this.schedulerService.closeDialog.subscribe((res) => {
			if (res) {
				this.dialog.closeAll();
			}
		});
	}

	public activeEditForm(): void {
		this.editForm = !this.editForm;
		if (this.editForm) {
			this.bookingResForm.get('osobaTelefon').enable();
			this.bookingResForm.get('osobaEmail').enable();
			this.bookingResForm.get('rezerwacjaUwagimemo').enable();
			this.bookingResForm.get('rezerwacjaUwagiPortal').enable();
			this.bookingMelForm.get('osobaTelefon').enable();
			this.bookingMelForm.get('osobaEmail').enable();
			this.bookingMelForm.get('meldunekUwagimemo').enable();
			this.bookingMelForm.get('meldunekUwagiPortal').enable();
		} else {
			this.bookingResForm.get('osobaTelefon').disable();
			this.bookingResForm.get('osobaEmail').disable();
			this.bookingResForm.get('rezerwacjaUwagimemo').disable();
			this.bookingResForm.get('rezerwacjaUwagiPortal').disable();
			this.bookingMelForm.get('osobaTelefon').disable();
			this.bookingMelForm.get('osobaEmail').disable();
			this.bookingMelForm.get('meldunekUwagimemo').disable();
			this.bookingMelForm.get('meldunekUwagiPortal').disable();
		}
	}

	public saveEditForm(text: string): void {
		if (text === 'formSubmit') {
			this.schedulerService.onSubmit(text, { ...this.bookingResForm.value, rezerwacjaId: this.data.rezerwacjaId, osobaId: this.data.osobaId });
		} else {
			this.schedulerService.onSubmit(text, { ...this.bookingMelForm.value, meldunekId: this.data.meldunekId, osobaId: this.data.osobaId });
		}
	}

	public checkIsTTLockAvailable(): void {
		const param = 'TTLockObslugaWlaczona';
		this.receptionService.getParamValueByName(param).subscribe((res) => {
			this.isTTLockAvailable = res === 1;
		});
	}

	public openCheckinDialog(data): void {
		this.schedulerService.openCheckinDialog(data);
	}

	public sendMail(data): void {
		this.schedulerService.sendMail(data);
	}

	public addAdvancePayment(data): void {
		this.schedulerService.addAdvancePayment(data);
	}

	public onCancelRes(data): void {
		this.schedulerService.onCancelRes(data);
	}

	public generateKeyRezerwacja(data): void {
		this.schedulerService.generateKeyRezerwacja(data);
	}

	public cancelPasscode(rezerwacjaId, pokojNazwa): void {
		this.schedulerService.cancelPasscode(rezerwacjaId, pokojNazwa);
	}
}
