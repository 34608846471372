<perfect-scrollbar [config]="{wheelPropagation: false, suppressScrollX: true}">
<div class="module-container">
  <form [formGroup]="assignmentForm">
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Pracownik</mat-label>
        <mat-select formControlName="osobaId">
          <mat-option *ngFor="let worker of uprawnieniList" [value]="worker.id" (click)="getSingleWorkerRooms(worker.id)">
            {{ worker.nazwiskoImie }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>

  <form *ngIf="assignmentForm.value.osobaId !== null">

    <mat-card class="local-group" *ngFor="let local of roomGroup">
      <mat-card-subtitle class="local-name">
        <mat-checkbox color="primary" (change)="changeWholeLocalStatus($event, local)">
          {{local.polozenie}}
        </mat-checkbox>
      </mat-card-subtitle>

      <ng-container *ngFor="let room of local.pokoje">
        <mat-checkbox class="single-checkbox" color="primary" (change)="room.checked = $event.checked" [checked]="room.checked">
          {{room.pokojNazwa}}
        </mat-checkbox>
      </ng-container>
    </mat-card>

  </form>
  <div class="mat-dialog-actions">
    <button mat-stroked-button class="btn-s btn-ek btn-transparent-ek" (click)="updateEmployeeAssignment('cancel')">Anuluj</button>
    <button mat-button class="btn-xs btn-ek btn-info-ek" type="submit" (click)="updateEmployeeAssignment('save')">Zapisz</button>
  </div>
</div>
</perfect-scrollbar>