import { Injectable } from "@angular/core";
@Injectable({
  providedIn: "root",
})
export class SidebarService {
  public mobile_menu_visible: any = 0;
  public toggleButton: any;
  public sidebarVisible: boolean = false;
  constructor() {}

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );
    const html = document.getElementsByTagName("html")[0];

    if (window.innerWidth < 991) {
      mainPanel.style.position = "fixed";
    }
    toggleButton?.classList.add("toggled");

    html?.classList.add("nav-open");

    this.sidebarVisible = true;
  }
  sidebarClose() {
    const html = document.getElementsByTagName("html")[0];
    this.toggleButton?.classList.remove("toggled");
    const mainPanel = <HTMLElement>(
      document.getElementsByClassName("main-panel")[0]
    );

    if (window.innerWidth < 991) {
      setTimeout(function () {
        mainPanel.style.position = "";
      }, 500);
    }
    this.sidebarVisible = false;
    html?.classList.remove("nav-open");
  }
  sidebarToggle() {
    var $toggle = document.getElementsByClassName("navbar-toggler")[0];
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  isMobileMenu(): boolean {
    if (window.innerWidth > 991) {
      return false;
    }
    return true;
  }
}
