import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";

import { ColorsPanelComponent } from "./colors-panel/colors-panel.component";
import { NavbarMapComponent } from "./navbar-map/navbar-map.component";
import { TranslateModule } from "@ngx-translate/core";
import { LoadingComponent } from "./loading/loading.component";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { ContextMenuComponent } from "src/app/pages/reception/shared/context-menu/context-menu.component";

import { MatMenuModule } from "@angular/material/menu";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatSelectModule } from "@angular/material/select";
import {
  PerfectScrollbarModule,
} from "ngx-perfect-scrollbar";

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    TranslateModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatMenuModule,
    MatIconModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    PerfectScrollbarModule
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ColorsPanelComponent,
    NavbarMapComponent,
    LoadingComponent,
    ContextMenuComponent,
    // TablePaginationComponent,
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ColorsPanelComponent,
    NavbarMapComponent,
    LoadingComponent,
    ContextMenuComponent,
    MatProgressSpinnerModule,
    MatMenuModule,
  ],
})
export class ComponentsModule {}
