import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { AppServiceService } from 'src/app/pages/app-service.service';
import { ReceptionService } from 'src/app/pages/reception/reception.service';
import { ReservationsService } from 'src/app/pages/reception/reservations/reservations.service';
import { CancelReservationComponent } from 'src/app/pages/reception/shared/dialogs/cancel-reservation/cancel-reservation.component';
import { CheckinReservationComponent } from 'src/app/pages/reception/shared/dialogs/checkin-reservation/checkin-reservation.component';
import { StayinsNewService } from 'src/app/pages/reception/stayins/stayins-new/stayins-new.service';
import { BasicConfirmationDialogComponent, ConfirmDialogModel } from 'src/app/shared/basic-confirmation-dialog/basic-confirmation-dialog.component';
import { RoomBlockerDialogComponent } from 'src/app/shared/room-blocker-dialog/room-blocker-dialog.component';
import { Events, Header, HeaderDetails, Item, ItemMeta, Period, SchedulerType, Section, SectionItem, Text } from './ngx-time-scheduler.model';
import { NgxTimeSchedulerService } from './ngx-time-scheduler.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/auth.service';
import { OsobaService } from 'src/app/shared/services/osoba.service';
import { StayinsService } from 'src/app/pages/reception/stayins/stayins.service';
import { MOMENT_LOCALE } from 'src/app/shared/constants';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { SidebarService } from 'src/app/components/sidebar/sidebar.service';
import { AcceptAdvancePaymentComponent } from '../../../../pages/reception/shared/dialogs/accept-advance-payment/accept-advance-payment.component';
import { BookingSchedulerMobileDialogComponent } from '../../../booking-scheduler-mobile-dialog/booking-scheduler-mobile-dialog.component';
import { AddClimatFeeComponent } from '../../../../pages/reception/shared/dialogs/add-climat-fee/add-climat-fee.component';
import { takeUntil } from 'rxjs/operators';

const ELEMENT_DATA: any[] = [];

@Component({
	selector: 'ngx-ts[items][periods][sections]',
	templateUrl: './ngx-time-scheduler.component.html',
	styleUrls: ['./ngx-time-scheduler.component.scss'],
})
export class NgxTimeSchedulerComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('sectionTd') set SectionTd(elementRef: ElementRef) {
		if (elementRef) {
			this.SectionLeftMeasure = elementRef.nativeElement.clientWidth + 'px';
		}
		this.changeDetector.detectChanges();
	}
	originalSchedulerTypes: SchedulerType[] = [];
	displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
	dataSource = ELEMENT_DATA;
	public localizationArray = [];
	@Input() currentTimeFormat = 'DD-MMM-YYYY HH:mm';
	@Input() showCurrentTime = true;
	@Input() showGoto = true;
	@Input() showToday = true;
	@Input() allowDragging = false;
	@Input() showBusinessDayOnly = false;
	@Input() headerFormat = 'Do MMM YYYY';
	@Input() minRowHeight = 30;
	@Input() maxHeight: string = null;
	@Input() text = new Text();
	@Input() public items: Item[];
	@Input() sections: Section[];
	@Input() periods: Period[];
	@Input() schedulerTypes: SchedulerType[];
	@Input() events: Events = new Events();
	@Input() start;
	@Input() areDetailsVisible: boolean;
	@Input() choosenObject;
	public end = moment().endOf('day');
	public showGotoModal = false;
	public currentTimeIndicatorPosition: string;
	public schedulerTypeId: number = 0;
	public currentTimeVisibility = 'visible';
	public currentTimeTitle: string;
	public ShowCurrentTimeHandle = null;
	public SectionLeftMeasure = '0';
	public currentPeriod: Period;
	public currentPeriodMinuteDiff = 0;
	public header: Header[];
	public sectionItems: SectionItem[];
	public isLocalizationOn: boolean;
	public choosenLocal: string;
	public locked: boolean = true;
	public buttonStatus: string = 'lock';
	public selected: Date | null;
	public currentDay;
	public bookingResForm = new FormGroup({
		rezerwacjaDataOd: new FormControl(),
		rezerwacjaDataDo: new FormControl(),
		osobaTelefon: new FormControl(),
		osobaEmail: new FormControl(),
		osobaAdres: new FormControl(),
		polozenieNazwa: new FormControl(),
		pokojNazwa: new FormControl(),
		rezerwacjaIleDoroslych: new FormControl(),
		rezerwacjaIleDzieci: new FormControl(),
		rezerwacjaCena: new FormControl(),
		passCode: new FormControl(),
		rezerwacjaUwagimemo: new FormControl(),
		rezerwacjaUwagiPortal: new FormControl(),
		zrodloRezerwacji: new FormControl(),
		rezerwacjaDataZglosz: new FormControl(),
	});
	public bookingMelForm = new FormGroup({
		meldunekDataOd: new FormControl(),
		meldunekDataDo: new FormControl(),
		osobaTelefon: new FormControl(),
		osobaEmail: new FormControl(),
		osobaAdres: new FormControl(),
		polozenieNazwa: new FormControl(),
		pokojNazwa: new FormControl(),
		meldunekIleDoroslych: new FormControl(),
		meldunekIleDzieci: new FormControl(),
		pobytBrutto: new FormControl(),
		passCode: new FormControl(),
		meldunekUwagimemo: new FormControl(),
		meldunekUwagiPortal: new FormControl(),
		zrodloRezerwacji: new FormControl(),
		rezerwacjaDataZglosz: new FormControl(),
	});
	public lockerTooltip: string = 'Odblokuj zmiany';
	public isTTLockAvailable;
	public showResForm: boolean = false;
	public showMelForm: boolean = false;
	public initialResForm;
	public isHousekeeping: boolean;
	public data2: boolean = true;
	private environment: any;
	public currentSection: any = {
		id: 0,
		value: 'room',
		name: 'Pokój',
	};
	public isRezAfterFirst: boolean = false;
	public isMelAfterFirst: boolean = false;
	public isMobileView: boolean = false;
	public roomSectionFlag = new BehaviorSubject(true);
	private destroy$ = new Subject<void>();

	constructor(
		private changeDetector: ChangeDetectorRef,
		private service: NgxTimeSchedulerService,
		private appService: AppServiceService,
		private _formBuilder: FormBuilder,
		public dialog: MatDialog,
		private reservationsService: ReservationsService,
		private _snackBar: MatSnackBar,
		private stayinsNewService: StayinsNewService,
		private receptionService: ReceptionService,
		private router: Router,
		public authService: AuthService,
		public personService: OsobaService,
		public stayinsService: StayinsService,
		private breakpointObserver: BreakpointObserver,
		public sidebarService: SidebarService
	) {
		moment.locale(MOMENT_LOCALE);
		this.environment = environment;
		this.breakpointObserver.observe([Breakpoints.HandsetPortrait, Breakpoints.HandsetLandscape]).subscribe((result) => {
			if (result.matches) {
				this.isMobileView = true;
				sidebarService.sidebarClose();
			} else {
				this.isMobileView = false;
			}
		});
	}

	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
		this.hideDetails();
	}

	ngOnInit(): void {
		this.getLocalizationList();
		this.checkHousekeepingModule();
		this.checkIsTTLockAvailable();
		this.service
			.fetchMessage()
			.pipe(takeUntil(this.destroy$))
			.subscribe((choosenLocal) => (this.choosenLocal = choosenLocal));
		this.setSectionsInSectionItems();
		this.changePeriod(this.periods[0], false);
		this.refresh();
		setTimeout(() => {
			this.isLocalizationOn = this.appService.isLocalizationOn.getValue();
		}, 1000);

		if (this.router.url == '/housekeeping/booking-schedule') {
			this.isHousekeeping = true;
		} else {
			this.isHousekeeping = false;
		}
		this.checkLocalizationStatus();
	}

	checkLocalizationStatus (){
		this.originalSchedulerTypes = [...this.schedulerTypes];
		this.stayinsService.localizationId.subscribe(idLocalization => {
			if (idLocalization != 0) {
			  this.schedulerTypes = this.originalSchedulerTypes.filter(item => item.id <= 0);
			} else {
			  this.schedulerTypes = [...this.originalSchedulerTypes];
			}
		  });
	}

	ngAfterViewInit() {}

	getLocalizationList() {
		this.reservationsService.getPolozeniaList().subscribe((res) => {
			const wszystkieLokalizacje = {
				polozenieId: 0,
				polozenieNazwa: 'Wszystkie lokalizacje',
			};
			this.localizationArray = [wszystkieLokalizacje, ...res];
		});
	}

	private checkHousekeepingModule() {
		if (this.router.url == '/housekeeping/booking-schedule') {
			this.isHousekeeping = true;
		} else {
			this.isHousekeeping = false;
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (this.choosenObject && this.choosenObject.rezerwacjaId && !this.choosenObject.meldunekId) {
			this.showMelForm = false;
			if (!this.isMobileView) {
				this.showResForm = true;
			} else {
				const dialogRef = this.dialog.open(BookingSchedulerMobileDialogComponent, {
					data: this.choosenObject,
				});
			}

			this.lockOrUnlockNextOpening(true);

			this.service.fetchMessage().subscribe((choosenLocal) => (this.choosenLocal = choosenLocal));
		} else if (this.choosenObject && this.choosenObject.meldunekId && !this.choosenObject.zlecenieId) {
			this.showResForm = false;
			if (!this.isMobileView) {
				this.showMelForm = true;
			} else {
				const dialogRef = this.dialog.open(BookingSchedulerMobileDialogComponent, {
					data: this.choosenObject,
				});
			}

			this.lockOrUnlockNextOpening(true);
		} else {
			this.showResForm = false;
			this.showMelForm = false;
		}
		if (this.choosenObject && !this.choosenObject.meldunekId) {
			if (!this.isRezAfterFirst) {
				this.bookingResForm = this._formBuilder.group({
					rezerwacjaDataOd: [this.choosenObject.rezerwacjaDataOd, Validators.required],
					rezerwacjaDataDo: [this.choosenObject.rezerwacjaDataDo, Validators.required],
					osobaTelefon: [this.choosenObject.osobaTelefon, Validators.required],
					//osobaTelefon: [{ value: " ", disabled: true }],
					osobaEmail: [this.choosenObject.osobaEmail, Validators.required],
					osobaAdres: [
						{
							value: ' ',
							disabled: true,
						},
					],
					polozenieNazwa: [
						{
							value: this.choosenObject.polozenieNazwa,
							disabled: true,
						},
						Validators.required,
					],
					pokojNazwa: [
						{
							value: this.choosenObject.pokojNazwa,
							disabled: true,
						},
						Validators.required,
					],
					rezerwacjaIleDoroslych: [this.choosenObject.rezerwacjaIleDoroslych, Validators.required],
					rezerwacjaIleDzieci: [this.choosenObject.rezerwacjaIleDzieci, Validators.required],
					pobytBrutto: [parseFloat(this.choosenObject.pobytBrutto).toFixed(2), Validators.required],
					passCode: [this.choosenObject.passCode ? this.choosenObject.passCode : null],
					rezerwacjaUwagimemo: [this.choosenObject.rezerwacjaUwagimemo],
					rezerwacjaUwagiPortal: [this.choosenObject.rezerwacjaUwagiPortal],
					zrodloRezerwacji: [
						{
							value: this.choosenObject.zrodloRezerwacji,
							disabled: true,
						},
						Validators.required,
					],
					rezerwacjaDataZglosz: [this.choosenObject.rezerwacjaDataZglosz],
				});
			} else {
				this.bookingResForm.controls.rezerwacjaDataOd.setValue(this.choosenObject.rezerwacjaDataOd);
				this.bookingResForm.controls.rezerwacjaDataDo.setValue(this.choosenObject.rezerwacjaDataDo);
				this.bookingResForm.controls.osobaTelefon.setValue(this.choosenObject.osobaTelefon);
				this.bookingResForm.controls.osobaEmail.setValue(this.choosenObject.osobaEmail);
				this.bookingResForm.controls.osobaAdres.setValue('');
				this.bookingResForm.controls.polozenieNazwa.setValue(this.choosenObject.polozenieNazwa);
				this.bookingResForm.controls.pokojNazwa.setValue(this.choosenObject.pokojNazwa);
				this.bookingResForm.controls.rezerwacjaIleDoroslych.setValue(this.choosenObject.rezerwacjaIleDoroslych);
				this.bookingResForm.controls.rezerwacjaIleDzieci.setValue(this.choosenObject.rezerwacjaIleDzieci);
				this.bookingResForm.controls.pobytBrutto.setValue(parseFloat(this.choosenObject.pobytBrutto).toFixed(2));
				this.bookingResForm.controls.passCode.setValue(this.choosenObject.passCode);
				this.bookingResForm.controls.rezerwacjaUwagimemo.setValue(this.choosenObject.rezerwacjaUwagimemo);
				this.bookingResForm.controls.rezerwacjaUwagiPortal.setValue(this.choosenObject.rezerwacjaUwagiPortal);
				this.bookingResForm.controls.zrodloRezerwacji.setValue(this.choosenObject.zrodloRezerwacji);
				this.bookingResForm.controls.rezerwacjaDataZglosz.setValue(this.choosenObject.rezerwacjaDataZglosz);
			}
			if (this.choosenObject.osobaId) {
				this.reservationsService.getReservationInfo(Number(this.choosenObject.rezerwacjaId)).subscribe((res) => {
					this.bookingResForm.controls.rezerwacjaUwagimemo.setValue(res.rezerwacjaUwagimemo);
					this.bookingResForm.controls.rezerwacjaUwagiPortal.setValue(res.rezerwacjaUwagiPortal);
				});
			}
			this.bookingResForm.disable();
			this.isRezAfterFirst = true;
		} else if (this.choosenObject && this.choosenObject.meldunekId) {
			if (!this.isMelAfterFirst) {
				this.bookingMelForm = this._formBuilder.group({
					meldunekDataOd: [this.choosenObject.meldunekDataOd, Validators.required],
					meldunekDataDo: [this.choosenObject.meldunekDataDo, Validators.required],
					osobaTelefon: [
						{
							value: this.choosenObject.osobaTelefon,
							disabled: true,
						},
					],
					osobaEmail: [this.choosenObject.osobaEmail, Validators.required],
					osobaAdres: [
						{
							value: ' ',
							disabled: true,
						},
					],
					polozenieNazwa: [
						{
							value: this.choosenObject.polozenieNazwa,
							disabled: true,
						},
						Validators.required,
					],
					pokojNazwa: [
						{
							value: this.choosenObject.pokojNazwa,
							disabled: true,
						},
						Validators.required,
					],
					meldunekIleDoroslych: [this.choosenObject.meldunekIleDoroslych, Validators.required],
					meldunekIleDzieci: [this.choosenObject.meldunekIleDzieci, Validators.required],
					pobytBrutto: [parseFloat(this.choosenObject.pobytBrutto).toFixed(2), Validators.required],
					passCode: [this.choosenObject.passCode ? this.choosenObject.passCode : null],
					meldunekUwagimemo: [this.choosenObject.meldunekUwagimemo],
					meldunekUwagiPortal: [this.choosenObject.meldunekUwagiPortal],
					zrodloRezerwacji: [
						{
							value: this.choosenObject.zrodloRezerwacji,
							disabled: true,
						},
						Validators.required,
					],
					rezerwacjaDataZglosz: [this.choosenObject.rezerwacjaDataZglosz],
				});
			} else {
				this.bookingMelForm.controls.meldunekDataOd.setValue(this.choosenObject.meldunekDataOd);
				this.bookingMelForm.controls.meldunekDataDo.setValue(this.choosenObject.meldunekDataDo);
				this.bookingMelForm.controls.osobaTelefon.setValue(this.choosenObject.osobaTelefon);
				this.bookingMelForm.controls.osobaEmail.setValue(this.choosenObject.osobaEmail);
				this.bookingMelForm.controls.osobaAdres.setValue('');
				this.bookingMelForm.controls.polozenieNazwa.setValue(this.choosenObject.polozenieNazwa);
				this.bookingMelForm.controls.pokojNazwa.setValue(this.choosenObject.pokojNazwa);
				this.bookingMelForm.controls.meldunekIleDoroslych.setValue(this.choosenObject.meldunekIleDoroslych);
				this.bookingMelForm.controls.meldunekIleDzieci.setValue(this.choosenObject.meldunekIleDzieci);
				this.bookingMelForm.controls.pobytBrutto.setValue(parseFloat(this.choosenObject.pobytBrutto).toFixed(2));
				this.bookingMelForm.controls.passCode.setValue(this.choosenObject.passCode);
				this.bookingMelForm.controls.meldunekUwagimemo.setValue(this.choosenObject.meldunekUwagimemo);
				this.bookingMelForm.controls.meldunekUwagiPortal.setValue(this.choosenObject.meldunekUwagiPortal);
				this.bookingMelForm.controls.zrodloRezerwacji.setValue(this.choosenObject.zrodloRezerwacji);
				this.bookingMelForm.controls.rezerwacjaDataZglosz.setValue(this.choosenObject.rezerwacjaDataZglosz);
			}

			if (this.choosenObject.osobaId) {
				this.personService.getOsobaList().subscribe((res) => {
					for (let i = 0; i < res.length; i++) {
						if (res[i].osobaId == this.choosenObject.osobaId) {
							this.bookingMelForm.controls.osobaTelefon.setValue(res[i].osobaTelefon);
							this.bookingMelForm.controls.osobaEmail.setValue(res[i].osobaEmail);
							break;
						}
					}
				});
				this.stayinsService.getStayinInfo(Number(this.choosenObject.meldunekId)).subscribe((res) => {
					this.bookingMelForm.controls.meldunekUwagimemo.setValue(res.meldunekUwagimemo);
					this.bookingMelForm.controls.meldunekUwagiPortal.setValue(res.meldunekUwagiPortal);
				});
			}
			this.bookingMelForm.disable();
			this.isMelAfterFirst = true;
		}
		this.isLocalizationOn = this.appService.isLocalizationOn.getValue();
	}

	refreshView() {
		this.setSectionsInSectionItems();
		this.changePeriod(this.currentPeriod, false);
	}

	hideDetails(): void {
		this.showMelForm = false;
		this.showResForm = false;
	}

	sendMail(scheduleObject): void {
		let preliminaryObject = {
			rezerwacjaId: scheduleObject.rezerwacjaId,
			mailRodzaj: 'REZERWACJA_WSTEPNA_INFO',
		};
		let confirmObject = {
			rezerwacjaId: scheduleObject.rezerwacjaId,
			mailRodzaj: 'REZERWACJA_LINK_DO_POTWIERDZENIA',
		};
		const send = forkJoin({
			Wstepna: this.receptionService.sendMailByStatus(preliminaryObject),
			Potwierdzenie: this.receptionService.sendMailByStatus(confirmObject),
		});
		send.subscribe(
			(res) => {
				this._snackBar.open(res.Wstepna.entity, '', {
					duration: 2000,
				});
				this._snackBar.open(res.Potwierdzenie.entity, '', {
					duration: 2000,
				});
			},
			(err) => {},
			() => {
				this.router.navigate(['/reception/booking-schedule']);
				this.showMelForm = false;
				this.showResForm = false;
			}
		);
	}

	addAdvancePayment(scheduleObject: any): void {
		// przyjmij zaliczkę
		const dialogRef = this.dialog.open(AcceptAdvancePaymentComponent, {
			width: '100%',
			maxWidth: '60vw',
			minWidth: '250px',
			data: scheduleObject,
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result === 'success') {
				this._snackBar.open('Zaliczka została dodana do rezerwacji', ':)', {
					duration: 2000,
				});
			}
		});
	}

	addClimatFee(scheduleObject: any): void {
		const dialogRef = this.dialog.open(AddClimatFeeComponent, {
			width: '100%',
			maxWidth: '60vw',
			minWidth: '250px',
			data: scheduleObject,
		});

		dialogRef.afterClosed().subscribe((result) => {
			if (result === 'success') {
				this._snackBar.open('Opłata miejscowa została dodana do rezerwacji', ':)', {
					duration: 2000,
				});
			}
		});
	}

	onCheckinRes(item) {
		let data = item.object;
		data.dataOd = item.object.rezerwacjaDataOd;
		data.dataDo = item.object.rezerwacjaDataDo;
		this.openCheckinDialog(data);
	}

	openCheckinDialog(data): void {
		const dialogRef = this.dialog.open(CheckinReservationComponent, {
			width: '100%',
			maxWidth: '60vw',
			minWidth: '250px',
		});
		dialogRef.componentInstance.data = data;
		dialogRef.afterClosed().subscribe((result) => {
			if (result == 'checkin') {
				let obj = {
					rezerwacjaId: data.rezerwacjaId,
				};
				this.reservationsService.checkinReservation(obj).subscribe((res) => {
					this._snackBar.open(res.entity, '', {
						duration: 2000,
					});
					if (res.status == 200) {
						//todo zamienic to na odswiezanie, a nie przeladowanie
						//window.location.reload();
						this.events.onEditBlockStayReservation();
					}
				});
			}
		});
	}

	trackByFn(index, item) {
		return index;
	}

	checkIsTTLockAvailable(): void {
		const param = 'TTLockObslugaWlaczona';
		this.receptionService.getParamValueByName(param).subscribe((res) => {
			this.isTTLockAvailable = res;
		});
	}

	generateKeyRezerwacja(rezerwacja): void {
		const rezerwacjaId = rezerwacja.rezerwacjaId;
		const message = `Czy na pewno chcesz wygenerować nowy klucz dla rezerwacji ${rezerwacjaId}?`;
		const dialogData = new ConfirmDialogModel('Nowy klucz', message);
		const dialogRef = this.dialog.open(BasicConfirmationDialogComponent, {
			maxWidth: '400px',
			data: dialogData,
		});
		dialogRef.afterClosed().subscribe((dialogResult) => {
			if (dialogResult == true) {
				this.receptionService.generateKeyRezerwacja(rezerwacjaId).subscribe((res) => {
					if (res.status == 200) {
						this._snackBar.open(res.entity, '', {
							duration: 2000,
						});
					}
				});
			}
		});
	}

	setSectionsInSectionItems() {
		this.sectionItems = new Array<SectionItem>();

		this.sections.sort((a, b) => this.getSortOrder(a, b));

		this.sections.forEach((section) => {
			if (!this.sectionItems.some((item) => item.section.name === section.name)) {
				const perSectionItem = new SectionItem();
				perSectionItem.section = section;
				perSectionItem.minRowHeight = this.minRowHeight;
				this.sectionItems.push(perSectionItem);
			}
		});
	}

	getSortOrder(a, b) {
		const hotelCode = this.authService.getHotelCode();

		if (this.isLocalizationOn || this.isSpecialHotelCode(hotelCode)) {
			return this.sortByLocalization(a, b);
		} else if (hotelCode === '0051') {
			return this.sortByHotelCode0051(a, b);
		} else if (hotelCode === '0271') {
			return this.sortByHotelCode0271(a, b);
		} else {
			return a.name.localeCompare(b.name);
		}
	}

	isSpecialHotelCode(hotelCode) {
		return ['0101', '9998'].includes(hotelCode);
	}

	sortByLocalization(a, b) {
		const order = { 'Ujny Rez 11': 2, 'Ujny Rez 4': 1 };
		const aOrder = order[a.name] || 0;
		const bOrder = order[b.name] || 0;

		if (aOrder || bOrder) {
			return bOrder - aOrder;
		}

		const aParts = this.extractParts(a.name);
		const bParts = this.extractParts(b.name);

		return this.compareParts(aParts, bParts);
	}
	extractParts(name) {
		const matches = name.match(/^(\D*)(\d+)?(\D*)(\d+)?$/);
		if (!matches) {
			return { letters: ['', ''], numbers: [0, 0] };
		}
		return {
			letters: [matches[1], matches[3] || ''],
			numbers: [parseInt(matches[2], 10) || 0, parseInt(matches[4], 10) || 0],
		};
	}

	compareParts(aParts, bParts) {
		if (aParts.letters[0] !== bParts.letters[0]) {
			return aParts.letters[0] > bParts.letters[0] ? 1 : -1;
		} else if (aParts.numbers[0] !== bParts.numbers[0]) {
			return aParts.numbers[0] - bParts.numbers[0];
		} else if (aParts.letters[1] !== bParts.letters[1]) {
			return aParts.letters[1] > bParts.letters[1] ? 1 : -1;
		} else {
			return aParts.numbers[1] - bParts.numbers[1];
		}
	}

	sortByHotelCode0051(a, b) {
		const aParts = a.name.match(/^([^\d]+)(\d+)$/);
		const bParts = b.name.match(/^([^\d]+)(\d+)$/);

		const aLetters = aParts ? aParts[1].trim() : '';
		const bLetters = bParts ? bParts[1].trim() : '';
		const aNumber = aParts ? parseInt(aParts[2], 10) : 0;
		const bNumber = bParts ? parseInt(bParts[2], 10) : 0;

		if (aLetters !== bLetters) {
			return aLetters > bLetters ? 1 : -1;
		} else {
			return aNumber - bNumber;
		}
	}

	sortByHotelCode0271(a, b) {
		if (this.currentSection.name === 'Pokój') {
			return parseInt(a.name) - parseInt(b.name);
		} else {
			return a.name.localeCompare(b.name);
		}
	}

	lockOrUnlock(): void {
		this.locked = !this.locked;
		this.updateButtonStatusAndTooltip();

		if (this.locked) {
			this.disableAllForms();
		} else {
			this.enableSpecificControls();
		}
	}

	private updateButtonStatusAndTooltip(): void {
		if (this.locked) {
			this.buttonStatus = 'lock';
			this.lockerTooltip = 'Odblokuj zmiany';
		} else {
			this.buttonStatus = 'lock_open';
			this.lockerTooltip = 'Zablokuj zmiany';
		}
	}

	private disableAllForms(): void {
		this.bookingMelForm.disable();
		this.bookingResForm.disable();
	}

	private enableSpecificControls(): void {
		this.bookingMelForm.disable();
		this.bookingMelForm.controls.pokojNazwa.disable();
		this.bookingMelForm.controls.osobaEmail.enable();
		this.bookingMelForm.controls.osobaTelefon.enable();
		this.bookingMelForm.controls.meldunekUwagimemo.enable();
		this.bookingMelForm.controls.meldunekUwagiPortal.enable();

		this.bookingResForm.disable();
		this.bookingResForm.controls.pokojNazwa.disable();
		this.bookingResForm.controls.osobaEmail.enable();
		this.bookingResForm.controls.osobaTelefon.enable();
		this.bookingResForm.controls.rezerwacjaUwagimemo.enable();
		this.bookingResForm.controls.rezerwacjaUwagiPortal.enable();
	}

	lockOrUnlockNextOpening(whetherToBlock: boolean): void {
		if (whetherToBlock) {
			this.locked = true;
			if (this.locked == true) {
				this.buttonStatus = 'lock';
				this.lockerTooltip = 'Odblokuj zmiany';
				this.bookingMelForm.disable();
				this.bookingResForm.disable();
			} else {
				this.buttonStatus = 'lock_open';
				this.lockerTooltip = 'Zablokuj zmiany';
				this.bookingMelForm.enable();
				this.bookingMelForm.controls.pokojNazwa.disable();
				this.bookingMelForm.controls.polozenieNazwa.disable();
				this.bookingMelForm.controls.osobaAdres.disable();
				this.bookingMelForm.controls.osobaTelefon.disable();
				this.bookingMelForm.controls.zrodloRezerwacji.disable();
				this.bookingMelForm.controls.meldunekUwagiPortal.enable();
				this.bookingResForm.enable();
				this.bookingResForm.controls.pokojNazwa.disable();
				this.bookingResForm.controls.polozenieNazwa.disable();
				this.bookingResForm.controls.osobaAdres.disable();
				this.bookingResForm.controls.osobaTelefon.disable();
				this.bookingResForm.controls.zrodloRezerwacji.disable();
				this.bookingResForm.controls.rezerwacjaUwagiPortal.enable();
			}
		}
	}

	onSubmit(whatToSubmit: string) {
		this.confirmDialog('Czy jesteś pewny wprowadzonych zmian?', whatToSubmit);
	}

	confirmDialog(text, callTrigger): void {
		const dialogData = new ConfirmDialogModel('Potwierdź akcję', text);
		const dialogRef = this.dialog.open(BasicConfirmationDialogComponent, {
			maxWidth: '400px',
			data: dialogData,
		});

		dialogRef.afterClosed().subscribe((dialogResult) => {
			if (dialogResult === true && callTrigger === 'formSubmit') {
				this.isRezAfterFirst = false;
				this.isMelAfterFirst = false;
				let dataReservation;
				dataReservation = this.bookingResForm.value;
				dataReservation['rezerwacjaId'] = this.choosenObject.rezerwacjaId;
				if (this.bookingResForm.value.polozenieId == null) {
					delete dataReservation.polozenieId;
				}
				delete dataReservation.rezerwacjaDataZglosz;
				dataReservation.osobaId = this.choosenObject.osobaId;
				this.stayinsNewService
					.editReservation({
						rezerwacjaId: dataReservation.rezerwacjaId,
						rezerwacjaUwagimemo: dataReservation.rezerwacjaUwagimemo,
						rezerwacjaUwagiPortal: dataReservation.rezerwacjaUwagiPortal,
					})
					.subscribe((res) => {
						this._snackBar.open(res.entity, '', {
							duration: 2000,
						});
						this.personService
							.editPerson({
								osobaId: dataReservation.osobaId,
								osobaTelefon1: dataReservation.osobaTelefon,
								osobaEmail: dataReservation.osobaEmail,
							})
							.subscribe((res2) => {
								if (res.status == 200 && res2.status == 200) {
									this.hideDetails();
								}
							});
					});
			} else if (dialogResult === true && callTrigger === 'formMelSubmit') {
				let data;
				data = this.bookingMelForm.value;
				data['meldunekId'] = this.choosenObject.meldunekId;
				if (this.bookingResForm.value.polozenieId == null) {
					delete data.polozenieId;
				}
				data.osobaId = this.choosenObject.osobaId;
				this.stayinsNewService
					.editStayin({
						meldunekId: data.meldunekId,
						meldunekUwagimemo: data.meldunekUwagimemo,
						meldunekUwagiPortal: data.meldunekUwagiPortal,
					})
					.subscribe((res) => {
						this._snackBar.open(res.entity, '', {
							duration: 2000,
						});

						this.personService
							.editPerson({
								osobaId: data.osobaId,
								osobaTelefon1: data.osobaTelefon,
								osobaEmail: data.osobaEmail,
							})
							.subscribe((res2) => {
								if (res.status == 200 && res2.status == 200) {
									this.hideDetails();
								}
							});
					});
			}
		});
	}

	onCancelRes(item) {
		let data = {
			reason: '',
			item: item,
		};
		this.openCancelDialog(data, 'single');
	}

	openCancelDialog(data, type: string): void {
		const dialogRef = this.dialog.open(CancelReservationComponent, {
			width: '100%',
			maxWidth: '60vw',
			minWidth: '250px',
		});
		let obj = {};
		dialogRef.componentInstance.data = data;
		dialogRef.afterClosed().subscribe((result) => {
			if (result == 'confirm') {
				if (type === 'single') {
					obj = {
						rezerwacjaId: data.item.rezerwacjaId,
						powodAnulacji: data.reason,
					};
				} else {
					obj = {
						grupaId: data.item.grupaId,
						powodAnulacji: data.reason,
					};
				}
				this.reservationsService.reservationCancel(obj, type).subscribe((res) => {
					this._snackBar.open(res.entity, '', {
						duration: 2000,
					});
					if (res.status === 200) {
						this.events.onEditBlockStayReservation();
						//todo zamienic to na odswiezanie, a nie przeladowanie
						// window.location.reload();
					}
				});
			}
		});
	}

	setItemsInSectionItems() {
	  const itemMetas = [];
	  const itemMetaMap = new Map();
	  const sectionItemMap = new Map();
	  this.sectionItems.forEach((sectionItem) => {
	    sectionItem.itemMetas = [];
	    sectionItem.minRowHeight = this.minRowHeight;
	    sectionItemMap.set(sectionItem.section.id, sectionItem);
	  });
	  const itemsBySection = new Map();
	  this.items.forEach((item) => {
	    if (item.start <= this.end && item.end >= this.start) {
	      if (!itemsBySection.has(item.sectionID)) {
	        itemsBySection.set(item.sectionID, []);
	      }
	      itemsBySection.get(item.sectionID).push(item);
	    }
	  });
	  itemsBySection.forEach((items, sectionID) => {
		const sectionItem = sectionItemMap.get(sectionID);
		if (sectionItem) {
			items.forEach((item) => {
				const uniqueKeyParts = [item.id, item.sectionID];
				if (item.object['grupaId']) {
					uniqueKeyParts.push(item.object['grupaId']);
				}
				if (item.name) {
					uniqueKeyParts.push(item.name);
				}
				const uniqueKey = uniqueKeyParts.join('-');
				if (!itemMetaMap.has(uniqueKey)) {
					let itemMeta = new ItemMeta();
					itemMeta.item = item;
					itemMeta = this.itemMetaCal(itemMeta);
					sectionItem.itemMetas.push(itemMeta);
					itemMetas.push(itemMeta);
					itemMetaMap.set(uniqueKey, itemMeta);
				}
			});
		}
	});
	  const sortedItems = {};
	  this.sectionItems.forEach((sectionItem, index) => {
	    sortedItems[index] = sectionItem.itemMetas;
	  });
	  this.calCssTop(sortedItems);
	}
	
	cancelPasscode(rezerwacjaId, pokojNazwa): void {
		const resId = rezerwacjaId;
		const message = `Czy na pewno chcesz dezaktywować klucz dla pokoju` + ' ' + `${pokojNazwa}?`;
		const dialogData = new ConfirmDialogModel('Dezaktywuj klucz', message);
		const dialogRef = this.dialog.open(BasicConfirmationDialogComponent, {
			maxWidth: '400px',
			data: dialogData,
		});
		dialogRef.afterClosed().subscribe((dialogResult) => {
			if (dialogResult == true) {
				this.receptionService.cancelPassCodeRes(resId).subscribe((res) => {
					this._snackBar.open('Kod anulowano', '', {
						duration: 2000,
					});
				});
			}
		});
	}

	itemMetaCal(itemMeta: ItemMeta) {
		const foundStart = moment.max(itemMeta.item.start, this.start);
		const foundEnd = moment.min(itemMeta.item.end, this.end);
		let widthMinuteDiff = Math.abs(foundStart.diff(foundEnd, 'minutes'));
		let leftMinuteDiff = foundStart.diff(this.start, 'minutes');

		if (this.showBusinessDayOnly) {
			widthMinuteDiff -= this.getNumberOfWeekendDays(moment(foundStart), moment(foundEnd)) * this.currentPeriod.timeFramePeriod;
			leftMinuteDiff -= this.getNumberOfWeekendDays(moment(this.start), moment(foundStart)) * this.currentPeriod.timeFramePeriod;
		}
		itemMeta.cssLeft = (leftMinuteDiff / this.currentPeriodMinuteDiff) * 100;
		itemMeta.cssWidth = (widthMinuteDiff / this.currentPeriodMinuteDiff) * 100;

		if (itemMeta.item.start >= this.start) {
			itemMeta.isStart = true;
		}
		if (itemMeta.item.end <= this.end) {
			itemMeta.isEnd = true;
		}

		return itemMeta;
	}

	calCssTop(sortedItems) {
		for (const prop of Object.keys(sortedItems)) {
			const items = sortedItems[prop];

			for (let i = 0; i < items.length; i++) {
				const elem = items[i];
				elem.originalCssTop = elem.cssTop;
			}

			// Prealokujemy tablicę przechowującą dolne granice dla elementów
			const previousBottoms = new Array(items.length).fill(0);

			for (let i = 0; i < items.length; i++) {
				const elem = items[i];
				let collisionCount = 0;
				let collisionDetected = false;

				for (let prev = 0; prev < i; prev++) {
					const prevElem = items[prev];
					const prevElemBottom = previousBottoms[prev] || prevElem.cssTop + this.minRowHeight;
					const elemBottom = elem.cssTop + this.minRowHeight;

					const isOverlappingHorizontally =
						(prevElem.item.start <= elem.item.start && elem.item.start <= prevElem.item.end) ||
						(prevElem.item.start <= elem.item.end && elem.item.end <= prevElem.item.end) ||
						(prevElem.item.start >= elem.item.start && elem.item.end >= prevElem.item.end);

					const isOverlappingVertically = (prevElem.cssTop <= elem.cssTop && elem.cssTop <= prevElemBottom) || (prevElem.cssTop <= elemBottom && elemBottom <= prevElemBottom);

					if (isOverlappingHorizontally && isOverlappingVertically) {
						elem.cssTop = prevElemBottom + 1;
						collisionDetected = true;
						collisionCount++; // Zwiększamy licznik kolizji
						previousBottoms[i] = elem.cssTop + this.minRowHeight; // Uaktualniamy dolną granicę
					}
				}

				if (collisionDetected) {
					const elemBottom = elem.cssTop + this.minRowHeight;

					const section = this.sectionItems[Number(prop)];
					if (section && elemBottom > section.minRowHeight) {
						section.minRowHeight = elemBottom;
					}
					elem.cssMarginTop = elem.cssTop - elem.originalCssTop;

					if (section) {
						section['roomHeight'] = (section['roomHeight'] || 30) + collisionCount * 50;
					}
				}
			}
		}
		this.adjustRoomHeight(this.sectionItems);
	}

	adjustRoomHeight(sectionItems) {
		sectionItems.forEach((el) => {
			let sameDates = true;
			let maxCssMarginTop = 0;

			el['roomHeight'] = el['roomHeight'] || 30; // Domyślna wysokość

			if (el.itemMetas && el.itemMetas.length > 0) {
				el.itemMetas.forEach((dataLine) => {
					const zlecenieDataOd = dataLine.item.object['zlecenieDataOd'];
					const zlecenieDataDo = dataLine.item.object['zlecenieDataDo'];

					// Sprawdzenie dat tylko raz dla każdego elementu
					if (typeof zlecenieDataOd === 'undefined' || typeof zlecenieDataDo === 'undefined' || zlecenieDataOd !== zlecenieDataDo) {
						sameDates = false;
					}
					// Znajdujemy maksymalny margines
					if (dataLine.cssMarginTop && dataLine.cssMarginTop > maxCssMarginTop) {
						maxCssMarginTop = dataLine.cssMarginTop;
					}
				});

				// Dodanie 30px jeśli daty są takie same
				if (sameDates) {
					el['roomHeight'] += 30;
				}
				// Dodajemy maksymalny margines do wysokości pokoju
				el['roomHeight'] += maxCssMarginTop;
			}
		});
	}

	changePeriod(period: Period, userTrigger: boolean = true) {
		this.resetState();
		this.currentPeriod = period;
		this.initializeStart();

		const start = moment(this.start).startOf('day');
		this.end = this.calculateEndDate(start);
		this.currentPeriodMinuteDiff = this.calculateMinuteDiff(start, this.end);

		if (userTrigger && this.events.PeriodChange) {
			this.events.PeriodChange(this.start, this.end);
		}

		if (this.showBusinessDayOnly) {
			this.adjustForBusinessDays();
		}

		this.generateHeaders();
		this.setItemsInSectionItems();
		this.showCurrentTimeIndicator();
	}

	resetState() {
		this.showMelForm = false;
		this.showResForm = false;
		this.choosenObject = null;
	}

	initializeStart() {
		if (this.start === undefined) {
			this.start = moment().startOf('day').subtract(7, 'days');
		}
	}

	calculateEndDate(start: moment.Moment) {
		return moment(start).add(this.currentPeriod.timeFrameOverall, 'minutes').endOf('day');
	}

	calculateMinuteDiff(start: moment.Moment, end: moment.Moment) {
		return Math.abs(start.diff(end, 'minutes'));
	}

	adjustForBusinessDays() {
		const weekendDays = this.getNumberOfWeekendDays(moment(this.start), moment(this.end));
		this.currentPeriodMinuteDiff -= weekendDays * this.currentPeriod.timeFramePeriod;
	}

	generateHeaders() {
		this.header = this.currentPeriod.timeFrameHeaders.map((ele: string, index: number) => this.getDatesBetweenTwoDates(ele, index));
	}

	showCurrentTimeIndicator = () => {
		if (this.ShowCurrentTimeHandle) {
			clearTimeout(this.ShowCurrentTimeHandle);
		}

		const currentTime = moment();
		if (currentTime >= this.start && currentTime <= this.end) {
			this.currentTimeVisibility = 'visible';

			this.currentTimeIndicatorPosition = (Math.abs(this.start.diff(currentTime, 'minutes')) / this.currentPeriodMinuteDiff) * 100 + '%';
			this.currentTimeTitle = currentTime.format(this.currentTimeFormat);
		} else {
			this.currentTimeVisibility = 'hidden';
		}
		this.ShowCurrentTimeHandle = setTimeout(this.showCurrentTimeIndicator, 30000);
	};

	gotoToday() {
		this.sectionItems = [];
		this.start = moment().startOf('day').subtract(7, 'days');
		this.changePeriod(this.currentPeriod);
	}

	nextPeriod() {
		this.sectionItems = [];
		this.addCurrentPeriodDependOfPeriodName(this.start);
		this.changePeriod(this.currentPeriod);
	}

	previousPeriod() {
		this.sectionItems = [];
		this.subtractCurrentPeriodDependOfPeriodName(this.start);
		this.changePeriod(this.currentPeriod);
	}

	gotoDate(event: any) {
		this.sectionItems = [];
		this.showGotoModal = false;
		this.start = moment(event).startOf('day').subtract(7, 'days');
		this.changePeriod(this.currentPeriod);
	}

	getDatesBetweenTwoDates(format: string, index: number): Header {
		const now = moment(this.start);
		const dates = new Header();
		let prev: string;
		let colspan = 0;

		while (now.isBefore(this.end) || now.isSame(this.end)) {
			const isBusinessDay = !this.showBusinessDayOnly || (now.day() !== 0 && now.day() !== 6);

			if (isBusinessDay) {
				const headerDetails = new HeaderDetails();
				headerDetails.name = now.format(format);
				headerDetails.month = now.month();

				if (prev && prev !== headerDetails.name) {
					colspan = 1;
				} else {
					colspan++;
					dates.headerDetails.pop();
				}

				prev = headerDetails.name;
				headerDetails.colspan = colspan;
				headerDetails.tooltip = this.currentPeriod.timeFrameHeadersTooltip && this.currentPeriod.timeFrameHeadersTooltip[index] ? now.format(this.currentPeriod.timeFrameHeadersTooltip[index]) : '';

				if (format == 'DD') {
					headerDetails.isSaturday = now.day() === 6;
					headerDetails.isSunday = now.day() === 0;
					headerDetails.dayOfWeek = ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'Sb'][now.day()];
				}

				dates.headerDetails.push(headerDetails);
			}

			this.addTimePeriodDependOfPeriodName(now);
		}

		if (now.date() === moment().date() && now.month() === moment().month() && now.year() === moment().year()) {
			this.currentDay = moment().date();
		}

		return dates;
	}

	/**
	 * @param date
	 * @returns void
	 * @description function add time period depend of period name to given date
	 */
	addTimePeriodDependOfPeriodName(date: moment.Moment) {
		if (this.currentPeriod.timeFramePeriodName === 'minutes') {
			date.add(this.currentPeriod.timeFramePeriod / 60 / 24, 'day');
		} else if (this.currentPeriod.timeFramePeriodName === 'hours') {
			date.add(this.currentPeriod.timeFramePeriod / 24, 'day');
		} else {
			date.add(this.currentPeriod.timeFramePeriod, 'day');
		}
	}

	/**
	 * @param date
	 * @returns void
	 * @description function add overwall time period depend of period name to given date
	 */
	addCurrentPeriodDependOfPeriodName(date: moment.Moment) {
		if (this.currentPeriod.timeFramePeriodName === 'minutes') {
			date.add(this.currentPeriod.timeFrameOverall / 60 / 24, 'day');
		} else if (this.currentPeriod.timeFramePeriodName === 'hours') {
			date.add(this.currentPeriod.timeFrameOverall / 24, 'day');
		} else {
			date.add(this.currentPeriod.timeFrameOverall, 'day');
		}
	}

	/**
	 * @param date
	 * @returns void
	 * @description function subtract overwall time period depend of period name from given date
	 */
	subtractCurrentPeriodDependOfPeriodName(date: moment.Moment) {
		if (this.currentPeriod.timeFramePeriodName === 'minutes') {
			date.subtract(this.currentPeriod.timeFrameOverall / 60 / 24, 'day');
		} else if (this.currentPeriod.timeFramePeriodName === 'hours') {
			date.subtract(this.currentPeriod.timeFrameOverall / 24, 'day');
		} else {
			date.subtract(this.currentPeriod.timeFrameOverall, 'day');
		}
	}

	getNumberOfWeekendDays(startDate, endDate) {
		let count = 0;
		while (startDate.isBefore(endDate) || startDate.isSame(endDate)) {
			if (startDate.day() === 0 || startDate.day() === 6) {
				count++;
			}
			this.addTimePeriodDependOfPeriodName(startDate);
		}
		return count;
	}

	drop(event: CdkDragDrop<Section>) {
		event.item.data.sectionID = event.container.data.id;
		this.refreshView();
		this.events.ItemDropped(event.item.data);
	}

	refresh() {
		this.service.refreshView
			.asObservable()
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.refreshView();
			});
	}

	onSectionChange(event) {
		if (event.target.value == 0) {
			this.roomSectionFlag.next(true);
		} else {
			this.roomSectionFlag.next(false);
		}
		this.sectionItems = [];
		this.choosenObject = null;
		this.events.SectionChange(this.schedulerTypes[event.target.value]);
		this.currentSection = this.schedulerTypes[event.target.value];
	}

	onLocalizationChange(event) {
		const filteredLocalization = this.localizationArray.filter((item) => item.polozenieId === parseInt(event.target.value));
		this.stayinsService.localizationId.next(event.target.value);
		this.choosenObject = null;
		this.sectionItems = [];
		this.events.LocalizationChange(filteredLocalization[0]);
		this.choosenLocal = event.target.options[event.target.options.selectedIndex].text;
		this.service.sendMessage(this.choosenLocal);
	}

	showRoomBlockerDialog(element) {
		const dialogRef = this.dialog.open(RoomBlockerDialogComponent, {
			data: element,
			maxWidth: '400px',
		});
		dialogRef.afterClosed().subscribe((result) => {
			if (result == 'Edited') {
				this.events.onEditBlockStayReservation();
			}
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
		// reset localizationId for StayinService to default value of 0 when destroying component
		this.stayinsService.localizationId.next(0);
		this.stayinsService.localizationId.complete();
		// 
		this.dialog.closeAll();
	}
	scrollRight() {
		const outsider = document.getElementById('main-table');
		const tdScrollTo = document.getElementById(this.currentDay.toString() + moment().month().toString());
		const headerOffset = 110;
		const elementPosition = tdScrollTo.getBoundingClientRect().left;
		const offsetPosition = elementPosition + window.pageXOffset - headerOffset;

		outsider.scrollTo({
			left: offsetPosition,
			behavior: 'smooth',
		});
	}
}
